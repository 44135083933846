import React from 'react'

export default function CheckBox({label='lable',onChange,value,disabled=false}) {
  return (
   <div className="flex items-center ">
    <input id={label} checked={value} disabled={disabled} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 outline-none active:outline-none focus:outline-none" onChange={onChange}/>
    <label for={label} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
</div>
  );
}
