// src/Modal.js

import React from "react";

const Modal = ({ isOpen, onClose, children, props, backDrop = true }) => {
  if (!isOpen) return null;
  const handleClick = () => {
    if (backDrop) {
      onClose();
    }
    // Your click event handler logic here
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50"
      {...props}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={handleClick}
      ></div>
      <div className="bg-white p-4 rounded-lg z-10 relative">
        {/* <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          Close
        </button> */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
