import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";

import {
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { assesmentDetails, saveAssesment } from "../../../http/session";

function SessionAssesment(props) {
  const [isSuffel, setIsSuffel] = useState(false);
  const [questionCount, setQUestionCount] = useState(10);
  const tagInputRef = useRef(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const sessionId = location?.state?.sessionId || "";
  const type = location?.state?.type || "";

  const formType = props?.formType;

  const [isLoader, setLoader] = useState(false);

  const getAsessmentData = async () => {
    let payload = {
      type: type,
      reference_id: sessionId,
    };
    let res = await assesmentDetails(payload);

    if (res?.success) {
      if (res.data.length != 0) {
        // alert('fs')

        setQuestion([...res.data[0]?.assessment]);
        setIsSuffel(res?.data[0]?.suffle_questions === 1 ? true : false);
        setQUestionCount(res?.data[0]?.display_no_of_questions);
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };
  useEffect(() => {
    getAsessmentData();
  }, []);

  const preChecklist = () => {
    console.log(questionCount);
    const newQuestionCount = parseInt(questionCount);
    console.log(newQuestionCount);
    if (isNaN(newQuestionCount)) {
      showToast(
        "Display numbers of question can't be blank",
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      return false;
    }
    if (newQuestionCount < 10) {
      showToast(
        "Display numbers of question can't be less than 10",
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      return false;
    }
    if (question.length == 0) {
      if (newQuestionCount > 0) {
        showToast(
          "Display numbers of question can't be greater than 0 because there is no question.",
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        return false;
      } else if (isSuffel) {
        showToast(
          "Can't shuffle because there is no question.",
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        return false;
      }
    } else {
      if (newQuestionCount > question?.length) {
        showToast(
          "Display numbers of question can't be more than  question count.",
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e, values) => {
    const isCheckAssesment = finalAssignmentData();
    const checklist = preChecklist();
    console.log(checklist);
    if (isCheckAssesment && checklist) {
      let payload = {
        type: "session",
        reference_id: sessionId,
        assessment: question,
        suffle_questions: isSuffel ? 1 : 0,
        display_no_of_questions: questionCount,
      };

      let res;

      res = await saveAssesment(payload);

      // setIsSubmit(false)

      if (res?.success) {
        navigate("/session-list");
        showToast(
          res?.message,
          "success",
          1000, // Custom timeout duration (5 seconds)
          () => {}
        );
      } else {
        if (res?.status === 401) {
          showToast(
            res?.message,
            "danger",
            3000, // Custom timeout duration (5 seconds)
            () => {}
          );
          sessionStorage.clear();
          navigate("/");
        }
      }
    }
  };
  const handleChangeQuestionType = (e, index) => {
    let tempdata = question;

    tempdata[index].question_type = e.target.value;

    tempdata[index].tempOption.map((item) => (item.isChecked = false));
    tempdata[index].tempOption.map((item) => (item.isChecked = false));

    setQuestion([...tempdata]);
  };

  const [question, setQuestion] = useState([
    {
      question_text: "",
      question_type: "single_select",
      answer: [],
      options: [],
      count: 0,
      tempOption: [
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
      ],
    },
  ]);
  const addQuestion = () => {
    setQuestion([
      ...question,

      {
        question_text: "",
        question_type: "single_select",
        answer: [],
        options: [],
        count: 0,
        tempOption: [
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
        ],
      },
    ]);
  };
  const addTags = (setFieldValue, data) => {
    if (tagInputRef.current.value.length > 0) {
      data.push(tagInputRef.current.value);
      setFieldValue("tags", data);
      tagInputRef.current.value = "";
    }
  };
  const handleRadio = (e, index, innerIndex) => {
    let tempdata = question;

    // tempdata[index].tempOption.map((item,index)=>item.isChecked=false)
    // tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;

    // setQuestion([...tempdata]);
    tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;
    if (tempdata[index].question_type == "single_select") {
      tempdata[index].tempOption.map((item, index) => (item.isChecked = false));
      tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;
    }
    setQuestion([...tempdata]);
  };
  const deleteQuestion = (index) => {
    let tempQuestion = question;
    tempQuestion.splice(index, 1);
    setQuestion([...tempQuestion]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].tempOption[innerIndex].option = e.target.value;
    setQuestion([...tempdata]);
  };
  const handleQuestionTitle = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].question_text = e.target.value;
    setQuestion([...tempdata]);
  };

  const finalAssignmentData = () => {
    question?.map((item) => {
      item.count = 0;
      item.answer = [];
      item.options = [];
    });
    let tempdata = question;
    question?.map((item, index) => {
      item.tempOption?.map((innerItem, innerIndex) => {
        if (innerItem.isChecked) {
          item.count += 1;
          if (!item.answer.includes(innerItem.option)) {
            item.answer.push(innerItem.option);
          }
        }
        if (!item.options.includes(innerItem.option)) {
          item.options.push(innerItem.option);
        }

        // item.options.push(innerItem.option)
      });

      // if (item.count == 1) {
      //   item.question_type = "single_select";
      // }
      // if (item.count > 1) {
      //   item.question_type = "mcq";
      // }
      item.options = item.options.filter((v) => v != "");
      item.answer = item.answer.filter((v) => v != "");
    });
    tempdata[0].answer = [...new Set(tempdata[0].answer)];
    tempdata[0].options = [...new Set(tempdata[0].options)];
    // if(tempdata.length===1 && tempdata[0].options.length==0 && tempdata[0].answer.length==0){
    //   tempdata=[];
    // setQuestion([...tempdata]);

    //   return true

    // }else{

    for (let i = 0; i <= tempdata.length - 1; i++) {
      //   if(tempdata.length==1 &&tempdata[i].options.length==0 && tempdata[i].answer.length==0 && tempdata[0].question_text ==''){
      //
      //     tempdata=tempdata.splice(i, 1);
      //     setQuestion([...tempdata]);
      //     break;
      //       return true
      //   }else{
      if (tempdata[i].options.length < 2) {
        showToast(
          `add at least 2  options in question number ${i + 1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        setQuestion([...tempdata]);
        // setIsSubmit(false)
        return false;
        break;
      } else if (tempdata[i].answer.length === 0) {
        showToast(
          `add atleast one answer  in question number ${i + 1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        setQuestion([...tempdata]);
        // setIsSubmit(false)
        return false;
        break;
      } else if (tempdata[i].question_text == "") {
        showToast(
          `add question title in  question number ${i + 1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        setQuestion([...tempdata]);
        // setIsSubmit(false)
        return false;
        break;
      }
      //   }
    }

    //
    //  setIsSubmit(false)
    return true;
  };

  const handleChange = (e) => {
    console.log("value", e?.target?.value);
    if (e?.target?.value != undefined) {
      setQUestionCount(e?.target?.value);
    }
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "edit" ? ` Assessment` : `Assessment`}
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12 lg:col-span-10">
                            <InputBox
                              label="Display number of questions"
                              type="number"
                              value={questionCount}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-span-12 lg:col-span-10">
                            <CheckBox
                              label="shuffle"
                              value={isSuffel}
                              onChange={(e) => {
                                setIsSuffel(e?.target?.checked);
                              }}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12 lg:col-span-10">
                            <p className="mt-1">
                              <b>NOTE</b> : Please tick option for make them
                              answer.
                            </p>
                            {question?.map((item, index) => {
                              return (
                                <>
                                  <div className="relative mt-2">
                                    <div className="assessment_qus_box px-4 py-3">
                                      <div className="question">
                                        <label
                                          className="mb-3"
                                          for="exampleInputEmail1"
                                        >
                                          Question
                                        </label>
                                        <input
                                          type="text"
                                          autocomplete="off"
                                          className="border border-gray-300 px-2 rounded-md  w-full py-2"
                                          placeholder="Enter Question here"
                                          name="question_text"
                                          value={item.question_text}
                                          onChange={(e) =>
                                            handleQuestionTitle(e, index)
                                          }
                                        />
                                      </div>
                                      <DropdownBox
                                        label="Question Type"
                                        required={true}
                                        className="w-[10rem]"
                                        onChange={(e) =>
                                          handleChangeQuestionType(e, index)
                                        }
                                        value={item.question_type}
                                        name="Question type"
                                        //       onError={
                                        //         errors.type &&
                                        //         touched.type &&
                                        //           errors.type
                                        //       }
                                        //       onBlur={handleBlur}
                                      >
                                        <option
                                          value="select"
                                          selected={
                                            item.question_type == "select"
                                          }
                                        >
                                          Select
                                        </option>
                                        <option
                                          value="single_select"
                                          selected={
                                            item.question_type ==
                                            "single_select"
                                          }
                                        >
                                          Single
                                        </option>
                                        <option
                                          value="mcq"
                                          selected={item.question_type == "mcq"}
                                        >
                                          Multiple
                                        </option>
                                      </DropdownBox>
                                      <div className="ans-box w-9/12">
                                        {item.tempOption?.map(
                                          (innerItem, innerIndex) => (
                                            <>
                                              <div className="flex pt-3 items-center">
                                                <input
                                                  disabled={
                                                    innerItem.option.length == 0
                                                  }
                                                  type={
                                                    item.question_type ==
                                                    "single_select"
                                                      ? "radio"
                                                      : "checkbox"
                                                  }
                                                  name={`option${index}`}
                                                  autocomplete="off"
                                                  checked={innerItem.isChecked}
                                                  onChange={(e) =>
                                                    handleRadio(
                                                      e,
                                                      index,
                                                      innerIndex
                                                    )
                                                  }
                                                />
                                                <input
                                                  type="text"
                                                  autocomplete="off"
                                                  value={innerItem.option}
                                                  className="border border-gray-300 px-2 ms-2 w-full py-2 rounded-md"
                                                  placeholder="Enter option here"
                                                  name={`option`}
                                                  onChange={(e) =>
                                                    handleQuestion(
                                                      e,
                                                      index,
                                                      innerIndex
                                                    )
                                                  }
                                                />
                                              </div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <div className="add-qustion">
                                      {index == question.length - 1 ? (
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={addQuestion}
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : null}
                                      {index != 0 && (
                                        <button
                                          type="button"
                                          className="blue-bg"
                                          onClick={() => deleteQuestion(index)}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>

                          <div className="col-span-12">
                            <div className="justify-end flex">
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn_table green-bg py-2"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSubmit}
                                className="btn_table blue-bg ms-1 py-2"
                              >
                                {formType == "update" ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {isLoader && <Loader />}
    </>
  );
}

export default SessionAssesment;
