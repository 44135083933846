import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";


export const addTopicById = async (data = {},id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/topics/${id}`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   
  }
  return result;
};
export const addTopic = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/topics/add`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   
  }
  return result;
};

export const topicList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/topics`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   

  }
  return result;
};

export const topicBBImpactList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/batches/topics-list`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   

  }
  return result;
};
export const topicByID = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/topics/${id}`,);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   
  }
  return result;
};

export const BBImpactById = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/topics/check-bbimpact-topic`,data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   
  }
  return result;
};
export const importVideoById = async (id,chapterId) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/topics/import/${id}${`/`+chapterId}`,);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
   
  }
  return result;
};


export const publishTopicById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/topics/publish/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const changeTopicById = async (id,payload={}) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/batches/update-batch-topic/${id}`,payload);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const topicDeleteById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/topics/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const topicDeleteinBBById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/topics/batch-management/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

