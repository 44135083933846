import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertFromHTML,convertToRaw,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect } from "react";

const InputBox = ({
  type = "text",
  placeholder = "",
  value,
  onChange=()=>{},
  label,
  className,
  name,
  onError,
  onBlur,
  props,
  ref,
  min=0,
  required=false,
  defaultValue="",
  disabled=false
}) => {
 
  
  const[editorState,seteditorState]=useState(name =='reading_content' && value != `<p></p>\n` ? EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(value)
    )
  ):EditorState.createEmpty())
  // const[editorState,seteditorState]=useState(EditorState.createEmpty())

  const onEditorStateChange=(editorState)=>{
   
    seteditorState(editorState)
  }
  useEffect(()=>{
   
    // if(draftToHtml(convertToRaw(editorState.getCurrentContent())) !== '<p></p>'){
    // }
    onChange(name,draftToHtml(convertToRaw(editorState.getCurrentContent())))
// if(draftToHtml(convertToRaw(editorState.getCurrentContent()))!==null){

// }
  },[editorState])
  return (
    <div className="relative flex flex-col">
      {label && <label className="rounded-md mb-2">{label}</label>}
      {type == "text" && (
        <>
          <input
            type={type}
            disabled={disabled}
            autocomplete="off"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`w-full py-2 rounded-md border border-gray-300 px-2  text-[13px] ${className}`}
            name={name}
            //  onBlur={handleBlur}
            onBlur={onBlur}
            ref={ref}
            {...props}
            required={required}
          />
          <p className="onerror">{onError}</p>
        </>
      )}
      {type == "password" && (
        <>
          <input
            autocomplete="off"
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`w-full py-2 rounded-md border border-gray-300 px-2 text-[13px] ${className}`}
            name={name}
            //  onBlur={handleBlur}
            onBlur={onBlur}
          />
          <p className="onerror">{onError}</p>
        </>
      )}
      {type == "number" && (
        <>
          <input
            autocomplete="off"
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`w-full py-2 rounded-md border border-gray-300 px-2 text-[13px] ${className} [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
            name={name}
            min={min}
            required={required}
           
            {...props}
            //  onBlur={handleBlur}
            onBlur={onBlur}
          />
          <p className="onerror">{onError}</p>
        </>
      )}
      {type == "textarea" && (
        <>
          <textarea
            autocomplete="off"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{ height: "100px" }}
            name={name}
            className={`w-full py-2 border border-gray-300 rounded-md px-2 text-[13px] ${className}`}
          />
          <p className="onerror">{onError}</p>
        </>
      )}
      {type == "editor" && (
        <>
          <Editor
            className="editertext"
            editorState={editorState}
            toolbarclassName="toolbarclassName"
            wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
            onEditorStateChange={onEditorStateChange}
            
          />
          <p className="onerror">{onError}</p>
        </>
      )}
    </div>
  );
};

export default InputBox;
