import React, { Suspense } from "react";
import "./App.css";
import "./pages/Dashboard/Home.css";

import "../src/pages/login/style.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LoaderImage from "./images/logo_loader.png";
import { setToken } from "./http/Axios";
import TopicForm from "./pages/Courses/Topic/TopicForm";
import TopicList from "./pages/Courses/Topic/Index";
import Semester from "./pages/Courses/BbSemsters/index";
import Assesment from "./pages/Courses/assesment";
import Board from "./pages/LeaderBoard/board";
import SessionAssesment from "./pages/Courses/NhitSession/Assesment";
import SessionAssessmentPreview from "./pages/Courses/NhitSession/SessionAssessmentPreview";
import FormList from "./pages/Feedback/Form";
import CampaignList from "./pages/Feedback/Cmapgain";
import CampaignAdd from "./pages/Feedback/Cmapgain/Addform";
import CampaignMapping from "./pages/Feedback/Cmapgain/Mapping";
import BBReport from "./pages/Courses/BbBatches/Report";
import CertificateList from "./pages/Certificate/Index";
import Certificate from "./pages/Certificate/addForm";
import Reallocate from "./pages/Courses/BbBatches/Reallocate";
import BannerManage from "./pages/BannerManage";
import SessionManage from "./pages/SessionManage";
import DiscussForm from "./pages/DiscussionForm";
import FAQ from "./pages/Courses/Topic/FAQ";
import FrameWorkAdd from "./pages/Feedback/Form/Framework";
import Bundle from "./pages/Bundle";
import BBimpactLeaderboard from "./pages/LeaderBoard/BBimpactLeaderboard";
import ListComponent from "./pages/AskQuestion";
import HealthReport from "./pages/Report/Health";
import TopicBBImpactList from "./pages/TopicbbImpact";
// import AskQuestionBBImpact from "./pages/AskQuestion/bbImpact";
import AskQuestionBBImpactLive from "./pages/AskQuestion/bbImpact";
import AskQuestionBBImpact from "./pages/AskQuestion/bbImpact/nonLive";


// const Login = React.lazy(() => import("./pages/login"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Home = React.lazy(() => import("./pages/Dashboard/Home"));
const TopicAuthorsList = React.lazy(() =>
  import("./pages/Courses/TopicAuthors/index")
);
const TopicAuthorsAdd = React.lazy(() =>
  import("./pages/Courses/TopicAuthors/addform")
);

const SettingList = React.lazy(() => import("./pages/Setting/index"));

const SettingAdd = React.lazy(() => import("./pages/Setting/addform"));

const ProgramList = React.lazy(() => import("./pages/Courses/Program/index"));
const ProgramAdd = React.lazy(() => import("./pages/Courses/Program/addform"));
const SubjectList = React.lazy(() => import("./pages/Courses/Subject/index"));
const SubjectAdd = React.lazy(() => import("./pages/Courses/Subject/addform"));
const ChapterList = React.lazy(() => import("./pages/Courses/Chapter/index"));
const ChapterAdd = React.lazy(() => import("./pages/Courses/Chapter/addForm"));
const BusinessCoach = React.lazy(() => import("./pages/businessCoach/index"));
const LeaderboardNew = React.lazy(() => import("./pages/LeaderboardNew/index"));
const QuestionAdd = React.lazy(() =>
  import("./pages/Feedback/Questions/AddForm")
);

const BusinessCoachEdit = React.lazy(() =>
  import("./pages/businessCoach/Edit")
);

const BbBatchesList = React.lazy(() =>
  import("./pages/Courses/BbBatches/index")
);
const BbBatchesAdd = React.lazy(() =>
  import("./pages/Courses/BbBatches/addform")
);
const BbBatchesToUsers = React.lazy(() =>
  import("./pages/Courses/BbBatches/bbbatchestousers")
);
const BbUnitsList = React.lazy(() => import("./pages/Courses/BbUnits/index"));
const BbUnitsAdd = React.lazy(() => import("./pages/Courses/BbUnits/addform"));
const BbBbUnitsMaping = React.lazy(() =>
  import("./pages/Courses/BbUnits/bbBbUnitsMaping")
);

const SubjectMapping = React.lazy(() =>
  import("./pages/Courses/Subject/subjectMapping")
);
const BbSemstersAdd = React.lazy(() =>
  import("./pages/Courses/BbSemsters/addform")
);
const NhitSessionList = React.lazy(() =>
  import("./pages/Courses/NhitSession/index")
);

const NhitSessionAdd = React.lazy(() =>
  import("./pages/Courses/NhitSession/addform")
);

const App = (props) => {
  return (
    <div className="app">
      {/* screen loader here*/}
      <Suspense
        fallback={
          <div className="loader_css">
            <div className="loader">
              <div id="spinner1"></div>
              <img src={LoaderImage} alt="" />
            </div>
          </div>
        }
      >
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <UnProtected>
                  <Login />
                </UnProtected>
              }
            />
            <Route exact path="/leaderboard/:id" element={<LeaderboardNew />} />
            <Route exact path="/leaderboard" element={<LeaderboardNew />} />
            <Route exact path="/leaderboardnew" element={<LeaderboardNew />} />
            <Route exact path="/leaderboardnew/:id" element={<LeaderboardNew />} />
            <Route exact path="/leaderboardbbimpact/:id" element={<BBimpactLeaderboard />} />
            <Route exact path="/leaderboardbbimpact" element={<BBimpactLeaderboard />} />

            <Route
              exact
              path="/home"
              element={
                <Protected>
                  <Home />
                </Protected>
              }
            />
            
            <Route
              exact
              path="/bb-reallocate"
              element={
                <Protected>
                  <Reallocate />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-report"
              element={
                <Protected>
                  <BBReport />
                </Protected>
              }
            />
            <Route
              exact
              path="/add-certificate"
              element={
                <Protected>
                  <Certificate formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/edit-certificate"
              element={
                <Protected>
                  <Certificate formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/certificate"
              element={
                <Protected>
                  <CertificateList />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-assessment"
              element={
                <Protected>
                  <SessionAssesment />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-assessment-preview"
              element={
                <Protected>
                  <SessionAssessmentPreview />
                </Protected>
              }
            />
            <Route
              exact
              path="/topicAuthors-list"
              element={
                <Protected>
                  <TopicAuthorsList />
                </Protected>
              }
            />
            <Route
              exact
              path="/topicAuthors-add"
              element={
                <Protected>
                  <TopicAuthorsAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/topicAuthors-edit"
              element={
                <Protected>
                  <TopicAuthorsAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/setting-list"
              element={
                <Protected>
                  <SettingList />
                </Protected>
              }
            />
            <Route
              exact
              path="/setting-update"
              element={
                <Protected>
                  <SettingAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/program-list"
              element={
                <Protected>
                  <ProgramList />
                </Protected>
              }
            />
            <Route
              exact
              path="/program-add"
              element={
                <Protected>
                  <ProgramAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/program-edit"
              element={
                <Protected>
                  <ProgramAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/subject-list"
              element={
                <Protected>
                  <SubjectList />
                </Protected>
              }
            />
            <Route
              exact
              path="/subject-add"
              element={
                <Protected>
                  <SubjectAdd />
                </Protected>
              }
            />
            <Route
              exact
              path="/subject-edit"
              element={
                <Protected>
                  <SubjectAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/chapter-list"
              element={
                <Protected>
                  <ChapterList />
                </Protected>
              }
            />
            <Route
              exact
              path="/chapter-add"
              element={
                <Protected>
                  <ChapterAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/chapter-edit"
              element={
                <Protected>
                  <ChapterAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/topic-add"
              element={
                <Protected>
                  <TopicForm formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/topic-edit"
              element={
                <Protected>
                  <TopicForm formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-batches-list"
              element={
                <Protected>
                  <BbBatchesList />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-batches-add"
              element={
                <Protected>
                  <BbBatchesAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-batches-edit"
              element={
                <Protected>
                  <BbBatchesAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-batches-to-users"
              element={
                <Protected>
                  <BbBatchesToUsers />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-units-list"
              element={
                <Protected>
                  <BbUnitsList />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-units-add"
              element={
                <Protected>
                  <BbUnitsAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-units-edit"
              element={
                <Protected>
                  <BbUnitsAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-units-maping"
              element={
                <Protected>
                  <BbBbUnitsMaping />
                </Protected>
              }
            />
            <Route
              exact
              path="/subject-mapping"
              element={
                <Protected>
                  <SubjectMapping />
                </Protected>
              }
            />
            <Route
              exact
              path="/topic-list"
              element={
                <Protected>
                  <TopicList />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-semsters-list"
              element={
                <Protected>
                  <Semester />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-semsters-add"
              element={
                <Protected>
                  <BbSemstersAdd />
                </Protected>
              }
            />
            <Route
              exact
              path="/bb-semsters-edit"
              element={
                <Protected>
                  <BbSemstersAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/assessment"
              element={
                <Protected>
                  <Assesment />
                </Protected>
              }
            />

            <Route
              exact
              path="/session-add"
              element={
                <Protected>
                  <NhitSessionAdd />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-edit"
              element={
                <Protected>
                  <NhitSessionAdd formType="update" />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-list"
              element={
                <Protected>
                  <NhitSessionList />
                </Protected>
              }
            />
            <Route
              exact
              path="/business-coach"
              element={
                <Protected>
                  <BusinessCoach />
                </Protected>
              }
            />
            <Route
              exact
              path="/business-coach-edit"
              element={
                <Protected>
                  <BusinessCoachEdit />
                </Protected>
              }
            />
            <Route
              exact
              path="/question-list"
              element={
                <Protected>
                  <FormList />
                </Protected>
              }
            />
            <Route
              exact
              path="/campaign-list"
              element={
                <Protected>
                  <CampaignList />
                </Protected>
              }
            />
            <Route
              exact
              path="/question-add"
              element={
                <Protected>
                  <QuestionAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/framework-add"
              element={
                <Protected>
                  <FrameWorkAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/campaign-add"
              element={
                <Protected>
                  <CampaignAdd formType="add" />
                </Protected>
              }
            />
            <Route
              exact
              path="/campaign-edit"
              element={
                <Protected>
                  <CampaignAdd formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/campaign-mapping"
              element={
                <Protected>
                  <CampaignMapping />
                </Protected>
              }
            />
            <Route
              exact
              path="/question-edit"
              element={
                <Protected>
                  <QuestionAdd formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-banner"
              element={
                <Protected>
                  <BannerManage formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/session-manage"
              element={
                <Protected>
                  <SessionManage formType="edit" />
                </Protected>
              }
            />
            <Route
              exact
              path="/discuss-forum"
              element={
                <Protected>
                  <DiscussForm formType="edit" />
                </Protected>
              }
            />
             <Route
              exact
              path="/topic-faq"
              element={
                <Protected>
                  <FAQ />
                </Protected>
              }
            />
            <Route
              exact
              path="/bundle-activation"
              element={
                <Protected>
                  <Bundle />
                </Protected>
              }
            />
             <Route
              exact
              path="/health-report"
              element={
                <Protected>
                  <HealthReport />
                </Protected>
              }
            />
            <Route
              exact
              path="/ask-question"
              element={
              
                  <ListComponent />
             
              }
            />
             <Route
              exact
              path="/ask-question-bbimpact-live"
              element={
              
                  <AskQuestionBBImpactLive />
             
              }
            />
             <Route
              exact
              path="/ask-question-bbimpact"
              element={
                <Protected>
                  <AskQuestionBBImpact />
                  </Protected>
              }
            />
             <Route
              exact
              path="/bb-impact-topic"
              element={
                <Protected>
                  <TopicBBImpactList />
                  </Protected>
             
              }
            />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;

function Protected({ children }) {
  const value = sessionStorage.getItem("token") != null;
  // typeof sessionStorage.getItem('token')!=null

  if (sessionStorage.getItem("token") == null) {
    return <Navigate to="/" replace />;
  } else {
    setToken(sessionStorage.getItem("token"));

    return children;
  }
}

function UnProtected({ children }) {
  // typeof sessionStorage.getItem('token')!=null

  if (sessionStorage.getItem("token") !== null) {
    return <Navigate to="/home" replace />;
  } else {
    setToken(sessionStorage.getItem("token"));

    return children;
  }
}
