import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "../Modal/Index";
import Button from "../Button/Index";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import  UploadIconss  from "../../../images/upload-Icon.png";

export default function ImageUploader({
  value,
  onError,
  name,
  label,
  isCrop = false,
  onChange,
  imageUrl,
  imageShapeView,
  disabled=false
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState();
  const [imgSrc, setImgSrc] = useState("");
  const [finalImgSrc, setFinalImgSrc] = useState("");

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  let allowedExtension = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
  ];
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleCloseModal2 = () => {
    const file1 = document.getElementById("file1");
    const file2 = document.getElementById("file2");
    if (file1) {
      console.log(file1);
      file1.value = "";
    } else {
      console.log(file2);
      file2.value = "";
    }

    setModalOpen(false);
  };
  function onSelectFile(e) {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      allowedExtension.indexOf(e.target.files[0].type) > -1
    ) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  useEffect(() => {
    if (imgSrc !== "") {
      setModalOpen(true);
    }
  }, [imgSrc]);
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function handleSave() {
    previewCanvasRef.current.toBlob((blob) => {
      let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
      setFinalImgSrc(URL.createObjectURL(file));

      onChange(name, file);
      // onChange(file)
      handleCloseModal();
    }, "image/jpeg");
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <label className="mb-2" for="exampleInputEmail1">
        {label}
      </label>
      <div className="image_up_boxx">
      {isCrop ? (
       <div className="up-icon-box relative rounded-[8px] border-dashed border-[1px] cursor-pointer border-[#c6c6d3] text-center">
        <input
          type="file"
          disabled={disabled}
          accept="image/png, image/gif, image/jpeg"
          className="absolute top-[0] bottom-[0] right-[0] left-[0] cursor-pointer z-10 opacity-0 p-1 rounded-md w-full"
          name={name}
          onChange={onSelectFile}
          src={value}
          id="file1"
        />
          <img src={UploadIconss} width={30} alt="" className="m-auto pt-2 opacity-[0.3]" />
          <p className="mb-3">Upload Your File</p>
        </div>
      ) : (
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          className=" border border-gray-200 p-1 rounded-md w-full"
          name={name}
          onChange={onChange}
          src={value}
          id="file2"
        />
      )}
      {}
      <img
        src={finalImgSrc || imageUrl || imageShapeView}
        style={{ maxHeight: 60 ,marginTop:4}}

      />
      </div>
      {/* <p className="onerror">{onError}</p> */}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        style={{ maxWidth: 500, maxHeight: 500 }}
        backDrop={false}
      >
        <button
          onClick={handleCloseModal2}
          type="button"
          class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="deleteModal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only" onClose={handleCloseModal2}>
            Close modal
          </span>
        </button>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          className="mt-[2rem]"
          // aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
            className="w-[300px] xl:w-[500px] "
          />
        </ReactCrop>
        {/* <img src={imgSrc} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}/> */}
        {/* <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            /> */}
        {!!completedCrop && (
          <>
            <div className="mb-2">
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  height: 100,
                  // display:'none'
                }}
              />
            </div>
            <div>
              {/* <button onClick={onDownloadCropClick}>Download Crop</button> */}
              <a
                ref={hiddenAnchorRef}
                download
                style={{
                  position: "absolute",
                  top: "-200vh",
                  visibility: "hidden",
                }}
              >
                Hidden download
              </a>
            </div>
          </>
        )}
        <div className="flex justify-between gap-1">
          <Button
            icon={<i className="fa fa-rotate-right"></i>}
            text="Rotate 90"
            disable={!imgSrc}
            className="bg-red-600 w-[114px] xl:w-[150px] text-[13px] xl:text-[18px]"
            type="button"
            onClick={() =>
              setRotate(Math.min(180, Math.max(-180, Number(rotate - 90))))
            }
          />
          <Button
            icon={<i className="fa fa-save"></i>}
            text="Save"
            type="button"
            disable={!imgSrc}
            className="green-bg w-[77px] xl:w-[150px]  mx-[10px] text-[13px] xl:text-[18px]"
            onClick={handleSave}
          />
          <Button
            icon={<i className="fa fa-rotate-left"></i>}
            text="Rotate 90"
            type="button"
            disable={!imgSrc}
            className="bg-red-600 w-[114px] xl:w-[150px] text-[13px] xl:text-[18px]"
            onClick={() =>
              setRotate(Math.min(180, Math.max(-180, Number(rotate + 90))))
            }
          />
        </div>
      </Modal>
    </>
  );
}
