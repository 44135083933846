import Axios, { setToken, zoomWebApiClientKey, zoomWebApiUrl } from "../Axios";
import { getToken } from "../Utility";

const token = getToken();
//Batch APIs

export const addSession = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/sessions/add`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const updateSession = async (data = {}, id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/sessions/${id}`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const sessionDetails = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/sessions/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const notificationDropDownData = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(
      `/admin-api/v1/sessions/notification-calendar-dropdown`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const assesmentDetails = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/assessments`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const FAQDetails = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/faq`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const saveAssesment = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/assessments/save`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const saveFAQ = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/faq/save`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const deleteSession = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/sessions/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const sessionList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/sessions`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const launchActivityById = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/activities/launch`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadAttendanceFile = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.post(
      `/admin-api/v1/sessions/attendance-report`,
      data
    );
    // Create a blob from the response data
    const blob = new Blob([response.data]);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `bbdAttendance${data.sessionId}.csv`); // Specify the desired filename

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up the URL and the link
    window.URL.revokeObjectURL(url);
    //result.success = true;
    // return true;
    result.success = true;
    result.message = response;
    return response.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadPollActivity = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.post(
      `/admin-api/v1/activities/download/activity-report`,
      data
    );
    if (response?.data?.success == false) {
    } else {
      // Create a blob from the response data
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `bbdActivityPolls${data.reference_id}.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link
      window.URL.revokeObjectURL(url);
    }
    //result.success = true;
    // return true;
    result.success = true;
    result.message = response;
    return response.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const syncPollsFile = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await fetch(zoomWebApiUrl + "api/v1/bbp/set-sync-request", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        "client-key": zoomWebApiClientKey,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      });

    result.success = true;
    result.message = res;
    return res;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadPollsFile = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await fetch(zoomWebApiUrl + "api/v1/bbp/download-report", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "client-key": zoomWebApiClientKey,
      },
    }).then((res) => res.blob());
    //   let response = await Axios.post(`/api/v1/bbp/download-report`, data);
    // Create a blob from the response data
    //  const blob = new Blob([response.data]);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(response);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `bbdPoll${data.sessionId}.csv`); // Specify the desired filename

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up the URL and the link
    window.URL.revokeObjectURL(url);
    //result.success = true;
    // return true;
    result.success = true;
    result.message = response;
    return response;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadAssessmentFile = async (sessionId) => {
  let result = { success: false };
  try {
    let response = await Axios.get(
      `/admin-api/v1/sessions/assessments-details/${sessionId}`
    );
    const blob = new Blob([response.data]);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `bbdAssessment${sessionId}.csv`); // Specify the desired filename

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up the URL and the link
    window.URL.revokeObjectURL(url);
    result.success = true;
    result.message = response;
    return response.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadFeedbackReportFile = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.post(
      `/admin-api/v1/sessions/feedback-campaign-report`,
      data
    );

    if (response?.data?.success !== false) {
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `bbdFeedbackReport.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link
      window.URL.revokeObjectURL(url);
      result.success = true;
      result.message = response;
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};


export const sessionDeatils = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/sessions/course-live-session/${id}/session`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const sessionUpdate = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/sessions/course-live-session/save`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
