import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Breadcrumb from '../../Layout/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownBox from '../../components/core/DropdownBox/Index';
import { bannerDeatils, bannerDropDownData } from '../../http/Banner';
import { useToast } from '../../helper/custom hooks/useToast';
import Button from '../../components/core/Button/Index';
import InputBox from '../../components/core/InputBox/Index';
import { sessionDeatils, sessionUpdate } from '../../http/session';
import * as Yup from "yup";
import { Formik } from 'formik';
import CheckBox from '../../components/core/checkbox/Index';

const sessionSchema = Yup.object().shape({
  jobId: Yup.string().required("Required"),
  videoId: Yup.string().required("Required"),
  streamstatus: Yup.string().required("Required"),
});

export default function SessionManage(props) {
          const location = useLocation();
          const formType = location?.state?.formType || props?.formType;
          const sessionId=location?.state?.sessionId;
          const [sessionData,setSessionData]=useState(null)
          const [bannerValue,setBannerValue]=useState('')
          const [isAPICall,setIsAPICall]=useState(false)

          const navigate = useNavigate();
          const { showToast } = useToast();


          useEffect(()=>{
            if(!sessionId){
              navigate('/session-list')
              return
            }
// getbanenrDeatils()

                    // getBannerDropDownData()
                    getSessionDeatils();

          },[])

          const getBannerDropDownData = async () => {
                    let payload={  
                    "type": "dropdown"
                }
                    const res = await bannerDropDownData(payload);

                    
                    
                    if (res.success) {
                      
                     setSessionData(res?.data?.rows)
                    }else if(res.status===401){
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                        }
                      )
                      sessionStorage.clear()
                      navigate('/')
                
                    }else{
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                          // 
                        }
                      );
                    }
                  };
                  const getSessionDeatils = async () => {
                    let payload={  
                    "type": "dropdown"
                }
                    const res = await sessionDeatils(sessionId);

                    
                    
                    if (res.success) {
                      
                      setSessionData(res?.data[0])
                    }else if(res.status===401){
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                        }
                      )
                      sessionStorage.clear()
                      navigate('/')
                
                    }else{
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                          // 
                        }
                      );
                    }
                  };



const handleSubmit=async(values)=>{
  console.log(bannerValue)

  let payload={  
    "sessionId": sessionId,
    "type": "session",
    "jobId": values?.jobId,
    "videoId": values?.videoId,
    "streamstatus": values?.streamstatus,
    "enable_comments": values?.enable_comments ? 1:0


        }
            const res = await sessionUpdate(payload);

            
            
            if (res.success) {
              
                      navigate('/session-list')
            }else if(res.status===401){
        
              showToast(
                res.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {
                }
              )
              sessionStorage.clear()
              navigate('/')
        
            }else{
        
              showToast(
                res.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {
                  // 
                }
              );
            }
}

  return (
   <>
       <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12 ">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pb-2 flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                        Session managment
                        </h3>
                      </div>
                    </div>
                    <Formik
                    enableReinitialize
                       
                        initialValues={{
                          jobId: sessionData?.jobId || "",
                          videoId: sessionData?.videoId || "",
                          streamstatus: sessionData?.streamstatus || "",
                          enable_comments:sessionData?.enable_comments == 1 ? true :false
                        }}
                        onSubmit={(values) => handleSubmit(values)}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* and other goodies */
                        }) => (
                          <>
                      
                            <form onSubmit={handleSubmit}>
                    <div className="card-body pt-0 form-author">
                                <div className="grid grid-cols-12 gap-4">

                                <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      type="text"
                              //         disabled={isExpired}
                                      id=""
                                      placeholder="Enter Job Id"
                                      label="Job ID"
                                      name="jobId"
                                      className="border border-gray-300 px-2"
                                      
                                      onChange={handleChange}
                                      value={values.jobId}
                                      onError={
                                        errors.jobId &&
                                        touched.jobId &&
                                        errors.jobId
                                      }
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      type="text"
                              //         disabled={isExpired}
                                      id=""
                                      name='videoId'
                                      placeholder="Enter video ID"
                                      label="Video ID"
                              //         name="extra_assessment_time"
                                      className="border border-gray-300 px-2"
                                      onChange={handleChange}
                                      value={values.videoId}
                                      onError={
                                        errors.videoId &&
                                        touched.videoId &&
                                        errors.videoId
                                      }
                                    />
                                  </div>

                              
                                  <div className="col-span-12 lg:col-span-4">
                                <DropdownBox
                                  name='streamstatus'
                                      label="Select status "
                                      onChange={handleChange}
                                     value={values?.streamstatus}
                                     onError={
                                        errors.streamstatus &&
                                        touched.streamstatus &&
                                        errors.streamstatus
                                      }
                                      onBlur={handleBlur}
                                     
                                     
                                    >
                                      <option value="">Select</option>
                                      <option value="processing">Processing</option>
                                      <option value="finished">Finished</option>
                                     
                                    </DropdownBox>
                                  </div>
                                  </div>
                                  <div className="col-span-12 lg:col-span-4 flex items-center">
                                 
                                 <CheckBox
                                   value={values?.enable_comments}
                                   
                                   onChange={(e) =>
                                     setFieldValue('enable_comments',!values?.enable_comments)
                                   }
                                   label="Enable comments"
                                 />
                              
                             </div>

                                  <div className="col-span-12">
                                    <div className="justify-end flex">
                                      <Button
                                        type="button"
                                        text="Cancel"
                                        onClick={() => {
                                          navigate("/session-list");
                                        }}
                                        className="btn_table green-bg py-2"
                                      />
                                      <Button
                                        disable={isAPICall}
                                        loader={isAPICall}
                                        type="submit"
                                        onClick={handleSubmit}
                                        text='Save'
                                        className="btn_table blue-bg ms-1 py-2"
                                      />
                                    </div>
                                  </div>

                                  </div>
                                  
                            </form>
                          </>
                        )}
                      </Formik>


                    </div>
                    </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      
   </>
  )
}
