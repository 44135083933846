import LoaderImage from "../../../images/logo_loader.png";

const Loader=()=>{

    return   <div className="loader_css absolute top-0 w-[100vw]">
    <div className="loader">
      <div id="spinner1"></div>
      <img src={LoaderImage} alt="" />
    </div>
  </div>
}

export default Loader