import Axios from "../Axios";

export const colleageDataApi= async(id)=>{

  let result = { success: false }
  try {
    let res = await Axios.get(`/admin-api/v1/whitelabel/college-list`, {});
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const createCertificate= async(data)=>{

  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/whitelabel/certificate-logo-sign`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const certificateList = async (page,data = {}) => {
  
  let result = { success: false }
  try {
    let res = await Axios.get(`/admin-api/v1/whitelabel/certificate-logo-sign/list?page=${page}`);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}