import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Breadcrumb from "../../Layout/Breadcrumb";

import { useToast } from "../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";

import DropdownBox from "../../components/core/DropdownBox/Index";

import Button from "../../components/core/Button/Index";
import Loader from "../../components/core/Loader/Index";
import {
          activateMobile,
          bundleList,
  campaignList,
  campaignMapping,
  formDetails,
  saveForm,
  updateForm,
} from "../../http/feedback";
import InputBox from "../../components/core/InputBox/Index";
import Modal from "../../components/core/Modal/Index";

function Bundle(props) {
  const [campaignId, setCampaignId] = useState("");
  const [campaignData, setCampaignData] = useState([]);

  const [CSVFile, setCSVFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    "failedAssignment": [],
    "successAssignment": [],
    "invalidPhoneNumbers": []
  });


  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const formId = location?.state?.formId || "";
  const type = location?.state?.type || "";

  const formType = props?.formType;

  const getBundleList = async () => {
    const payload = {
      type: "dropdown",
    };

    const res = await bundleList(payload);

    if (res.success) {
      setCampaignData(res.data.rows);
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  useEffect(() => {
          getBundleList();
  }, []);

  const [isLoader, setLoader] = useState(false);
 const [mobile,setMobile]=useState([''])
 const addNewMobile=()=>{
          let tempMobile=mobile;
          tempMobile.push('');
          setMobile([...tempMobile])
 }

 const removeMobile=(index)=>{
          console.log(index)
          let tempMobile=mobile;
          if (index > -1) { // only splice array when item is found
                    tempMobile.splice(index, 1); // 2nd parameter means remove one item only
                  }

                  setMobile([...tempMobile])

 }

 const checkData=()=>{
          if(mobile.length>10){
                    showToast(
                              "Can't add more than 10 mobile number.",
                              "danger",
                              3000, // Custom timeout duration (5 seconds)
                              () => {}
                            );
                            return false
          }else  if(mobile.includes("")){
            showToast(
                      "Can't add blank mobile number.",
                      "danger",
                      3000, // Custom timeout duration (5 seconds)
                      () => {}
                    );
                    return false

  }else  if(campaignId==''){
    showToast(
              "Select bundle please.",
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
            return false

}else{
  for(let i=0;i<mobile.length;i++){
    if(mobile[i].length!=10){
      showToast(
        `Mobile no. ${mobile[i]} is invalid`,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      return false
    }
  }
  // mobile.forEach((item,index)=>{
  //   if(item.length!=10){
  //     showToast(
  //       `Mobile no. ${item} is invalid`,
  //       "danger",
  //       3000, // Custom timeout duration (5 seconds)
  //       () => {}
  //     );
  //     return false
  //   }
  // })
}


          return true
 }

 const displayToast=(data)=>{
let failedAssignment=data.failedAssignment;
let invalidPhoneNumbers=data.invalidPhoneNumbers
let successAssignment=data.successAssignment
setModalData({failedAssignment:data.failedAssignment,
   invalidPhoneNumbers:data.invalidPhoneNumbers,
   successAssignment:data.successAssignment})
   setModalOpen(true)
// if(invalidPhoneNumbers.length!=0){

//   showToast(
//     `Invalid numbers:${invalidPhoneNumbers.join(',')}`,
//     "danger",
//     4000, // Custom timeout duration (5 seconds)
//     () => {}
//   );
// }
// if(failedAssignment.length!=0){

//   showToast(
//     `failed assignment:${failedAssignment.join(',')}`,
//     "danger",
//     3000, // Custom timeout duration (5 seconds)
//     () => {}
//   );
// }
// if(successAssignment.length!=0){

//   showToast(
//     `success assignment:${successAssignment.join(',')}`,
//     "danger",
//     3000, // Custom timeout duration (5 seconds)
//     () => {}
//   );
// }


 }
  const handleSubmit = async (e, values) => {
    console.log(campaignId);
    let payload={
      "phone_no": mobile,
      "value_pack_id": campaignId
  
    }

    if (checkData()) {
     
      const res = await activateMobile(payload);

   
      if (!!res && res?.success != false) {
      displayToast(res.data)
        showToast(
          res?.message,
          "success",
          3000 // Custom timeout duration (5 seconds)
        );
      } else if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res.message,
          "error",
          1000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    } 
    // const isCheckAssesment = finalAssignmentData();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    const maxSize = 9 * 1024 * 1024; // 9MB in bytes

    // Check if the file is a CSV file
    if (!file.name.endsWith(".csv")) {
      showToast(
        "Please select a CSV file.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("Please select a CSV file.");
      document.getElementById("fileupload").value = "";

      return;
    }

    // Check file size
    if (file.size > maxSize) {
      showToast(
        "File size exceeds the limit of 9MB.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("File size exceeds the limit of 9MB.");
      document.getElementById("fileupload").value = "";

      return;
    }

    setCSVFile(file);
  };
  const handleCloseModal = () => {
    setMobile([''])
    setCampaignId('')
    setModalOpen(false);
  };
  const handleChange=(e,index)=>{
    let tempMobile=mobile;
    if(e){

      tempMobile[index]=e.target.value
    }
    setMobile([...tempMobile])

  }
  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                           Bundle activation
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12">
                            <DropdownBox
                              label="Bundle*"
                              value={campaignId}
                              className='mb-2'
                              onChange={(e) => setCampaignId(e?.target?.value)}
                            >
                              <option value="">Select</option>

                              {campaignData &&
                                campaignData?.map((item, index) => (
                                  <option value={item?._id} key={index}>
                                    {item?.title}
                                  </option>
                                ))}
                            </DropdownBox>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-6">

                          <div className="relative">
                            {/* <Button
                          text={"Import"}
                          disable={isImporting}
                          onClick={handleFileButtonClick}
                          className={"mx-3"}
                        /> */}
                        {
                              mobile.map((item,index)=><>

                              <InputBox label='Mobile*' value={item} type="number" placeholder="Enter mobile number" className='w-50 mb-2' onChange={(e)=>handleChange(e,index)}/>
                        <div className="add-qustion !bottom-[11px]">
                                      {index == mobile.length - 1 && mobile.length!=10 ? (
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={addNewMobile}
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : null}
                                      {index != 0 && (
                                        <button
                                          type="button"
                                          className="blue-bg"
                                          onClick={() => removeMobile(index)}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                              </>)
                        }
                        {/* <InputBox label='mobile' placeholder="Enter mobile number"/> */}
                          </div>
                          </div>
                          </div>

                        <div className="col-span-12">
                          <div className="justify-end flex">
                            {/* <button
                              type="button"
                              onClick={() => {
                                window.location.reload();
                              }}
                              className="btn_table green-bg py-2"
                            >
                              Cancel
                            </button> */}
                            <button
                              onClick={handleSubmit}
                              className="btn_table blue-bg ms-1 py-2"
                            >
                              Activate
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container-fluid end--> */}
          </div>
        </div>
      </div>
      {/* <!-- Page Body end--> */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        style={{ maxWidth: 500, maxHeight: 500,minWidth:300 }}
      >
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button
            onClick={handleCloseModal}
            type="button"
            class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="deleteModal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only" onClose={handleCloseModal}>
              Close modal
            </span>
          </button>
          {/* <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg> */}
          <div className="flex flex-col mt-5">
          {modalData?.failedAssignment.length!=0 && <div >
              <p className="text-left">Failed assignment or already assign : </p> <p className="text-left"> {modalData?.failedAssignment.join(',')}</p>
            </div>}
            {modalData?.invalidPhoneNumbers.length!=0 && <div>
              <p className="text-left">Invalid phone numbers : </p><p className="text-left"> {modalData?.invalidPhoneNumbers.join(',')}</p>
            </div>}
            {modalData?.successAssignment.length!=0 && <div className="mt-2">
              <p className="text-left">Success assignment : </p><p className="text-left"> {modalData?.successAssignment.join(',')}</p>
            </div>}
          </div>
        </div>
      </Modal>

      {isLoader && <Loader />}
    </>
  );
}

export default Bundle;
