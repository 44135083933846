import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Breadcrumb from '../../Layout/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownBox from '../../components/core/DropdownBox/Index';
import { BannerUpdate, bannerDeatils, bannerDropDownData } from '../../http/Banner';
import { useToast } from '../../helper/custom hooks/useToast';
import Button from '../../components/core/Button/Index';


export default function BannerManage(props) {
          const location = useLocation();
          const formType = location?.state?.formType || props?.formType;
          const sessionId=location?.state?.sessionId;
          const [bannerData,setBannerData]=useState([])
          const [bannerValue,setBannerValue]=useState('')
          const [isAPICall,setIsAPICall]=useState(false)

          const navigate = useNavigate();
          const { showToast } = useToast();


          useEffect(()=>{
            if(!sessionId){
              navigate('/session-list')
              return
            }
getbanenrDeatils()

                    getBannerDropDownData()

          },[])

          const getBannerDropDownData = async () => {
                    let payload={  
                    "type": "dropdown"
                }
                    const res = await bannerDropDownData(payload);

                    
                    
                    if (res.success) {
                      
                     setBannerData(res?.data?.rows)
                    }else if(res.status===401){
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                        }
                      )
                      sessionStorage.clear()
                      navigate('/')
                
                    }else{
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                          // 
                        }
                      );
                    }
                  };
                  const getbanenrDeatils = async () => {
                    let payload={  
                    "type": "dropdown"
                }
                    const res = await bannerDeatils(sessionId);

                    
                    
                    if (res.success) {
                      
                    setBannerValue(res?.data[0]?.banner_and_cta
?.id)
                    }else if(res.status===401){
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                        }
                      )
                      sessionStorage.clear()
                      navigate('/')
                
                    }else{
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                          // 
                        }
                      );
                    }
                  };

const handleSubmit=async()=>{


          console.log(bannerValue)

          let payload={  
            "sessionId": sessionId,
            "type": "banner",
            "banner_and_cta_id":bannerValue
        
                }
                    const res = await BannerUpdate(payload);

                    
                    
                    if (res.success) {
                      
                              navigate('/session-list')
                    }else if(res.status===401){
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                        }
                      )
                      sessionStorage.clear()
                      navigate('/')
                
                    }else{
                
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {
                          // 
                        }
                      );
                    }
}


  return (
   <>
       <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12 ">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pb-2 flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "update"
                            ? `Banner managment`
                            : `Add Session`}
                        </h3>
                      </div>
                    </div>
                    <div className="card-body pt-0 form-author">
                                <div className="grid grid-cols-12 gap-4">

                              
                                  <div className="col-span-12 lg:col-span-4">
                                <DropdownBox
                                  
                                      label="Select banner *"
                                     
                                     
                                      value={bannerValue}
                                      onChange={(e) => {
                                        setBannerValue(e?.target?.value);
                                        
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {bannerData.map((item, index) => {
                                        return (
                                          <option
                                            value={item?._id}
                                            selected={
                                              item?.id == bannerValue
                                            }
                                          >
                                            {item?.title}
                                          </option>
                                        );
                                      })}
                                    </DropdownBox>
                                  </div>
                                  </div>

                                  <div className="col-span-12">
                                    <div className="justify-end flex">
                                      <Button
                                        type="button"
                                        text="Cancel"
                                        onClick={() => {
                                          navigate("/session-list");
                                        }}
                                        className="btn_table green-bg py-2"
                                      />
                                      <Button
                                        disable={isAPICall}
                                        loader={isAPICall}
                                        type="submit"
                                        onClick={handleSubmit}
                                        text='Save'
                                        className="btn_table blue-bg ms-1 py-2"
                                      />
                                    </div>
                                  </div>

                                  </div>

                    </div>
                    </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      
   </>
  )
}
