import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Breadcrumb from "../../Layout/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import TableComponent from "../../components/core/Table/Index";
import Modal from "../../components/core/Modal/Index";
import { useToast } from "../../helper/custom hooks/useToast";
import { batchesList, deleteBatch, downloadReportFile } from "../../http/batch";
import NoDataListFound from "../../Layout/NoDataListFound";
import useDidMountEffect from "../../helper/custom hooks/useDidMountHook";
import InputBox from "../../components/core/InputBox/Index";
import { useDebounce } from "../../helper/custom hooks/useDebounce";
import TableLoader from "../../components/core/Loader/TableLoader";
import PaginationsList from "../Courses/PaginationsList";
import { certificateList } from "../../http/Certificate";
import TempTableComponent from "./Table";

function CertificateList(props) {
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState([]);
  const [perPageLimit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { showToast } = useToast();
  const [apiLoader, setApiLoader] = useState(false);
  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 700);

  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    getCertificateList();
  }, []);

  const changePerPageLimit = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useDidMountEffect(() => {
    getCertificateList();
  }, [currentPage, perPageLimit]);

  const columns = useMemo(
    () => [
      // { label: "Id", accessor: "id", sortable: false, sortbyOrder: "ASC" },
     
      { label: "COLLEGE NAME", accessor: "collegeName", sortable: false, sortbyOrder: "ASC" },
      { label: "COLLEGE LOGO", accessor: "certificateCompletionLogo", sortable: false, sortbyOrder: "ASC",isImage:true },
      // { label: "CERTIFICATE PARTICIPATION", accessor: "certificateParticipationLogo", sortable: false, sortbyOrder: "ASC",isImage:true },
      { label: "COLLEGE SIGNATURE", accessor: "certificateSignature", sortable: false, sortbyOrder: "ASC",isImage:true },

     
      {
        label: "Action",
        accessor: "action",
        sortable: false,
        sortbyOrder: "ASC",
      },
    ],
    []
  );

  const [unitListData, setUnitListData] = useState([]);
  useEffect(() => {
    getCertificateList();
  }, [currentPage, perPageLimit]);
  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected + 1);
  };
  const handleEdit = (id, index, data) => {
    console.log(id,index,data)
    navigate("/edit-certificate", {
      state: { certificateData: JSON.stringify(data), formType: "edit" },
    });
  };
  const handleDelete = (id, index) => {
    setModalOpenDelete(true);
    setSelectedItem(id);
  };
  const deleteTopic = async () => {
    setModalOpenDelete(false);
    const res = await deleteBatch(selectedItem);

    if (res.success) {
      showToast(
        res.message,
        "success",
        1000, // Custom timeout duration (5 seconds)
        () => {
          setCurrentPage(1);
          getCertificateList();
        }
      );
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  const hadnleStatus = (id, index) => {};
  const handleMapping = (id, index) => {
    navigate("/bb-batches-list", {
      state: { unitId: id },
    });
  };
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // if (searchQuery || query.length < 0)
    if (query?.length == 0) {
      setCurrentPage(1);
      setLimit(10);
      getCertificateList();
    } else if (currentPage) getCertificateList();
  }, [searchQuery]);
  const handleChange = (event) => {
    if (query?.length == 0) {
      setCurrentPage(1);
      setLimit(10);
    }

    setQuery(event?.target?.value);
  };
  const exportReport = async (id, index) => {
    const payload = {
      sessionId: id,
    };
    const res =downloadReportFile(id)
    if (!!res && res?.success != false) {
      showToast(
        "File Downloaded Successfully",
        "success",
        3000 // Custom timeout duration (5 seconds)
      );
    } else {
      showToast(
        res.message,
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };

  const getCertificateList = async () => {
    setApiLoader(true);
    // const payload = {
    //   page: currentPage - 1,
    //   type: "list",
    //   pageSize: perPageLimit,
    //   searchText: searchQuery,
    // };
    const res = await certificateList( currentPage - 1);
    setApiLoader(false);

    if (res.success) {
      setTotal(res?.data?.total || 0);
      setPageCount(Math.ceil(res.data.totalCount / perPageLimit));
      let dataArr = res?.data?.rows;
      dataArr.map((item, index) => {
        item.action = {
          edit: handleEdit,
          // delete: handleDelete,
          // exportReport: exportReport,
          // statusChange: hadnleStatus,
          // mapping: handleMapping,
        };
      });
      setTableData(dataArr);
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  const getSearchData = () => {
    if (!!searchText) {
      return unitListData?.filter((item) => {
        return (
          item?.Name?.toLowerCase()?.includes(searchText) ||
          item?.Email?.toLowerCase()?.includes(searchText) ||
          item?.MobileNumber?.toLowerCase()?.includes(searchText) ||
          item?.DOB?.toLowerCase()?.includes(searchText) ||
          item?.Gender?.toLowerCase()?.includes(searchText) ||
          item?.Address?.toLowerCase()?.includes(searchText)
        );
      });
    }
    return unitListData;
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header onSearchTextChange={setSearchText} />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4">
                    <div className="card-headers pt-2 pb-2 px-0 flex justify-between items-center">
                      <div className="flex items-center flex-wrap">
                        <h3 className="card-titles mb-0 me-3">Certificate logo list</h3>
                        {/* <InputBox
                          type="text"
                          className="!w-[15rem]"
                          onChange={handleChange}
                          placeholder="Search by name"
                        /> */}
                        <div className="search-teable relative">
                          <span className="absolute flex items-center">
                            {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                          </span>
                          {/* <input type="text" placeholder="Search" name="" /> */}
                        </div>
                      </div>
                      <div>
                        <Link to="/add-certificate">
                          <button className="btn_table blue-bg ms-1">
                            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            Add
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="pt-0 pb-3">
                      <div className="relative overflow-x-auto table-responsive">
                        {tableData.length > 0 ? (
                          <TempTableComponent
                            data={tableData}
                            columns={columns}
                            currentPage={currentPage}
                            perPageLimit={perPageLimit}
                          />
                        ) : !apiLoader ? (
                          <NoDataListFound />
                        ) : <TableLoader />}
                      </div>
                      {/* <PaginationsList /> */}
                      <div className="flex justify-center relative">
                     {tableData.length > 0 && <PaginationsList
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                      />}
                      {/* {tableData.length > 1 && (
                        <select
                          value={perPageLimit}
                          onChange={(e) => changePerPageLimit(e)}
                          className="datalimit absolute right-0 mt-4 border-[1px] p-[4px] text-[13px] rounded-[4px] border-gray-300"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                        </select>
                      )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
        <Modal
          isOpen={isModalOpenDelete}
          onClose={() => setModalOpenDelete(false)}
          style={{ maxWidth: 500, maxHeight: 500 }}
        >
          <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button
              onClick={() => setModalOpenDelete(false)}
              type="button"
              class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="deleteModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only" onClick={() => setModalOpenDelete(false)}>
                Close modal
              </span>
            </button>
            <svg
              class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <p class="mb-4 text-gray-500 dark:text-gray-300 mt-3">
              Are you sure you want to delete this item?
            </p>
            <div class="flex justify-center items-center space-x-4">
              <button
                onClick={() => setModalOpenDelete(false)}
                type="submit"
                class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
              <button
                onClick={deleteTopic}
                type="submit"
                class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                Yes, I'm sure
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CertificateList;
