import Axios from "../Axios";

export const AskQuestion = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/live-session-campaign/ask-questions`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  
  }
  return result;
};
export const AskQuestionForBB = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/live-session-campaign/bb-impact-ask-questions`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  
  }
  return result;
};

export const AskQuestionForBBNonLive = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/bb-impact-ask-questions-list`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  
  }
  return result;
};