const convertJsonToFormData = (data) => {
  const formData = new FormData();

  const appendToFormData = (key, value, parentKey = "") => {
    if (value instanceof File) {
      // If it's a File, append it directly to the FormData
      formData.append(parentKey || key, value);
    } else if (value instanceof Object && !(value instanceof Date)) {
      // If it's an object (excluding Date), iterate over its keys recursively
      Object.keys(value).forEach((innerKey) => {
        const newKey = parentKey ? `${parentKey}[${innerKey}]` : innerKey;
        appendToFormData(innerKey, value[innerKey], newKey);
      });
    } else {
      // Otherwise, append the value as a regular field
      formData.append(parentKey || key, value);
    }
  };

  Object.keys(data).forEach((key) => {
    appendToFormData(key, data[key]);
  });

  return formData;
};

const convertJsonToFormDataNested = (data) => {
  const formData = new FormData();

  const flattenObject = (obj, parentKey = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const newKey = parentKey ? `${parentKey}[${key}]` : key;

        if (value instanceof File) {
          formData.append(newKey, value);
        } else if (value instanceof Object && !(value instanceof Date)) {
          flattenObject(value, newKey);
        } else {
          formData.append(newKey, value);
        }
      }
    }
  };


  flattenObject(data);

  return formData;
};

function hasEmptyString(arrayOfObjects) {
  return arrayOfObjects.some((obj) =>
    Object.values(obj).some((value) => value === "")
  );
}

function getDateAndTimeFormate(dateTime) {
  //YYYY-MM-DD HH:mm:ss
  const t = new Date(dateTime);
  const y = t.getFullYear();
  const m = ("0" + (t.getMonth() + 1)).slice(-2);
  const d = ("0" + t.getDate()).slice(-2);
  const H = ("0" + t.getHours()).slice(-2);
  const M = ("0" + t.getMinutes()).slice(-2);
  return `${y}-${m}-${d} ${H}:${M}:00`;
}

function getDateFormate(dateTime) {
  //YYYY-MM-DD HH:mm:ss
  const t = new Date(dateTime);
  const y = t.getFullYear();
  const m = ("0" + (t.getMonth() + 1)).slice(-2);
  const d = ("0" + t.getDate()).slice(-2);
  return `${y}-${m}-${d}`;
}

function getDateAndTimeInputFormate(dateTime) {
  //YYYY-MM-DDThh:mm
  const t = new Date(dateTime);
  const y = t.getFullYear();
  const m = ("0" + (t.getMonth() + 1)).slice(-2);
  const d = ("0" + t.getDate()).slice(-2);
  const H = ("0" + t.getHours()).slice(-2);
  const M = ("0" + t.getMinutes()).slice(-2);
  return `${y}-${m}-${d}T${H}:${M}`;
}

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}


export {
  convertJsonToFormData,
  hasEmptyString,
  convertJsonToFormDataNested,
  getDateAndTimeFormate,
  getDateFormate,
  getDateAndTimeInputFormate,
  validateUrl,
};
