export const isAuthenticated = () => {
  let token = getToken();
  if (token) {
    return true;
  }
  return false;
};

export const setToken = (token) => {
  sessionStorage.setItem("token", token);
  localStorage.setItem("token", token);
};
export const clearStorage = (token) => {
  sessionStorage.clear();
  localStorage.clear();
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setUser = (user) => {
  localStorage.setItem("user", user);
};

export const getUser = () => {
  return localStorage.getItem("user");
};

export const setPermission = (permission) => {
  if (!!permission) {
    sessionStorage.setItem("permission", JSON.parse(permission)?.[0]);
  } else {
    sessionStorage.setItem("permission", "sub-admin");
  }
};
export const getPermission = () => {
  return sessionStorage.getItem("permission");
};
