import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const [currentPath, setCurrentPath] = useState("");

  const pathname = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive11, setIsActive11] = useState(false);
  const [isActive12, setIsActive12] = useState(false);
  const [isActive13, setIsActive13] = useState(false);
  const [isActive14, setIsActive14] = useState(false);





  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  const handleClick8 = (event) => {
    setIsActive8((current) => !current);
  };
  const handleClick1 = (event) => {
    setIsActive1((current) => !current);
  };
  const handleClick2 = (event) => {
    setIsActive2((current) => !current);
  };
  const handleClick3 = (event) => {
    setIsActive3((current) => !current);
  };
  const handleClick4 = (event) => {
    setIsActive4((current) => !current);
  };
  const handleClick5 = (event) => {
    setIsActive5((current) => !current);
  };
  const handleClick6 = (event) => {
    setIsActive6((current) => !current);
  };
  const handleClick7 = (event) => {
    setIsActive7((current) => !current);
  };
  const handleClick11 = (event) => {

    setIsActive11((current) => !current);
  };
  const handleClick13 = (event) => {

    setIsActive13((current) => !current);
  };
  const handleClick12 = (event) => {

    setIsActive12((current) => !current);
  };
  const handleClick14 = (event) => {

    setIsActive14((current) => !current);
  };
  useEffect(() => {
    const currentPath =
      window.location.href?.split("/")[
        window.location.href?.split("/").length - 1
      ];
    setCurrentPath(currentPath);
    if (
      currentPath == "topicAuthors-list" ||
      currentPath == "topicAuthors-add"
    ) {
      setIsActive(true);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(false);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive7(false);
      setIsActive11(false);
      setIsActive8(false);
    } else if (currentPath == "chapter-list" || currentPath == "chapter-add") {
      setIsActive1(true);
      setIsActive(false);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(false);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive7(false);
      setIsActive8(false);
      setIsActive11(false);
    } else if (currentPath == "program-list" || currentPath == "program-add") {
      setIsActive2(true);
      setIsActive1(false);
      setIsActive3(false);
      setIsActive4(false);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive7(false);
      setIsActive8(false);
      setIsActive(false);
      setIsActive11(false);
    } else if (
      currentPath == "subject-list" ||
      currentPath == "subject-mapping" ||
      currentPath == "subject-add"
    ) {
      setIsActive3(true);
      setIsActive4(false);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    } else if (
      currentPath == "bb-units-list" ||
      currentPath == "bb-units-add"
    ) {
      setIsActive4(true);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    } else if (
      currentPath == "bb-batches-list" ||
      currentPath == "bb-batches-add" || currentPath == "bb-report" 
    ) {
      setIsActive5(true);
      setIsActive6(false);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    } else if (
      currentPath == "bb-semsters-list" ||
      currentPath == "bb-semsters-add"
    ) {
      setIsActive6(true);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    } else if (
      currentPath == "session-list" ||
      currentPath == "session-add"
    ) {
      setIsActive7(true);
      setIsActive8(false);
      setIsActive6(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    }else if (
      currentPath == "question-list" ||
      currentPath == "campaign-list" ||
      currentPath == "campaign-mapping" ||
      currentPath == "question-add" ||
      currentPath == "campaign-add"
    ) {
      setIsActive8(true);
      setIsActive7(false);
      setIsActive6(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
      setIsActive11(false);
    }else if (
      currentPath == "certificate" || 'add-certificate'
    ) {
      setIsActive11(true);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive6(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
    }else if (
      currentPath == "bundle-activation" 
    ) {
      setIsActive12(true);
      setIsActive11(false);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive6(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
    }else if (
      currentPath == "health-report" 
    ) {
      setIsActive12(false);
      setIsActive13(true);
      setIsActive11(false);
      setIsActive8(false);
      setIsActive7(false);
      setIsActive6(false);
      setIsActive5(false);
      setIsActive4(false);
      setIsActive3(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive(false);
    }
  }, [window]);
  return (
    <>
      {/* <!-- Page Sidebar Start--> */}
      <header className="main-nav">
        <nav>
          <div className="main-navbar">
            <div className="left-arrow disabled" id="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </div>
            <div id="mainnav">
              <ul
                className="nav-menu custom-scrollbar"
                style={{ display: "block" }}
              >
                <li className="back-btn">
                  <div className="mobile-back text-end">
                    <span>Back</span>
                    <i
                      className="fa fa-angle-right ps-2"
                      aria-open_sub_menu="true"
                    ></i>
                  </div>
                </li>
                <li className="sidebar-main-title">
                  <div>
                    <h6></h6>
                  </div>
                </li>
                <li className="dropdown">
                  <Link
                    className={
                      currentPath == "home"
                        ? "nav-link menu-title active"
                        : "nav-link menu-title"
                    }
                    to="/home"
                  >
                    <i
                      className="fa fa-tachometer"
                      aria-open_sub_menu="true"
                    ></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                {/* <li className="dropdown">
                  <Link
                    className={
                      isActive
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick}
                  >
                    <i className="fa fa-file" aria-open_sub_menu="true"></i>
                    <span>Courses</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "entrepreneurship-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/entrepreneurship-list"
                      >
                        Entrepreneurship
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "topicAuthors-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/topicAuthors-list"
                      >
                        Topic Authors
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link
                    className={
                      isActive1
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick1}
                  >
                    <i className="fa fa-book" aria-open_sub_menu="true"></i>
                    <span>Short Term Courses</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive1
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "shorttermcourses-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/shorttermcourses-list"
                      >
                        Courses
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "shorttermcourses-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/shorttermcourses-add"
                      >
                        Course Add
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="dropdown">
                  <Link
                    className={
                      isActive2
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick2}
                  >
                    <i
                      className="fa fa-caret-square-o-right"
                      aria-open_sub_menu="true"
                    ></i>
                    <span>Program</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive2
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "program-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/program-list"
                      >
                        Program List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "program-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/program-add"
                      >
                        Program Add
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <Link
                    className={
                      isActive3
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick3}
                  >
                    <i className="fa fa-list" aria-open_sub_menu="true"></i>
                    <span>Subject</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive3
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "subject-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/subject-list"
                      >
                        Subject List
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className={
                          currentPath == "subject-mapping"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/subject-mapping"
                      >
                        Subject Mapping
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className={
                          currentPath == "subject-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/subject-add"
                      >
                        Subject Add
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="dropdown">
                  <Link
                    className={
                      isActive1
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick1}
                  >
                    <i class="fa fa-window-maximize" aria-hidden="true"></i>
                    <span>Chapter</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive1
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "chapter-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/chapter-list"
                      >
                        Chapter List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "chapter-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/chapter-add"
                      >
                        Chapter Add
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="dropdown">
                  <Link
                    className={
                      isActive6
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick6}
                  >
                    <i class="fa fa-newspaper-o" aria-hidden="true"></i>
                    <span>Semester</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive6
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "bb-semsters-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-semsters-list"
                      >
                        Semester List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "bb-semsters-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-semsters-add"
                      >
                        Semester Add
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="dropdown">
                  <Link
                    className={
                      isActive4
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick4}
                  >
                    <i class="fa fa-life-ring" aria-open_sub_menu="true"></i>
                    <span>Unit</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive4
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "bb-units-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        itemProp="data1"
                        to="/bb-units-list"
                      >
                        Unit List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "bb-units-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-units-add"
                      >
                        Unit Add
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="dropdown">
                  <Link
                    className={
                      isActive5
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick5}
                  >
                    <i className="fa fa-users" aria-open_sub_menu="true"></i>
                    <span>Batch</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive5
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "bb-batches-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-batches-list"
                      >
                        Batch List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "bb-batches-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-batches-add"
                      >
                        Batch Add
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "bb-report"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bb-report"
                      >
                        Batch Report
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link
                    className={
                      isActive7
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick7}
                  >
                    <i class="fa fa-object-group" aria-hidden="true"></i>
                    <span>Session</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive7
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "session-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/session-list"
                      >
                        Session List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "session-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/session-add"
                      >
                        Session Add
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link
                    className={
                      isActive
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick}
                  >
                    <i class="fa fa-address-book-o" aria-hidden="true"></i>
                    <span>Author</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "topicAuthors-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/topicAuthors-list"
                      >
                        Authors List
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "topicAuthors-add"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/topicAuthors-add"
                      >
                        Authors Add
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={
                      currentPath == "business-coach"
                        ? "nav-link menu-title active"
                        : "nav-link menu-title"
                    }
                    to="/business-coach"
                  >
                    <i class="fa fa-user-secret" aria-hidden="true"></i>
                    Business Coach
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      currentPath == "discuss-forum"
                        ? "nav-link menu-title active"
                        : "nav-link menu-title"
                    }
                    to="/discuss-forum"
                  >
                    <i class="fa fa-sticky-note" aria-hidden="true"></i>
                    Discussion Forum
                  </Link>
                </li>
                 {/* feedback */}
                 <li className="dropdown">
                  <Link
                    className={
                      isActive8
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick8}
                  >
                    <i class="fa fa-commenting-o" aria-hidden="true"></i>
                    <span>Feedback Form</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive8
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "question-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/question-list"
                      >
                        Form
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "campaign-list"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/campaign-list"
                      >
                        Campaign
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "campaign-mapping"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/campaign-mapping"
                      >
                        Campaign mapping
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={
                      currentPath == "setting-list"
                        ? "nav-link menu-title active"
                        : "nav-link menu-title"
                    }
                    to="/setting-list"
                  >
                    <i className="fa fa-cog" aria-open_sub_menu="true"></i>
                    Setting
                  </Link>
                </li>
                {/* bunder */}
                <li>
                  <Link
                    className={
                      currentPath == "bundle-activation"
                        ? "nav-link menu-title active"
                        : "nav-link menu-title"
                    }
                    to="/bundle-activation"
                  >
                    <i className="fa fa-cog" aria-open_sub_menu="true"></i>
                    Bundle activation
                  </Link>
                </li>
            
{/* WHITE label */}
<li className="dropdown">
                  <Link
                    className={
                      isActive11
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick11}
                  >
                    <i class="fa fa-address-book-o" aria-hidden="true"></i>
                    <span>White Label</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive11
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "certificate"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/certificate"
                      >
                        Certificate
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "add-certificate"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/add-certificate"
                      >
                       Add certificate
                      </Link>
                    </li>
                  
                  </ul>
                </li>
                {/* ask */}
                <li className="dropdown">
                  <Link
                    className={
                      isActive14
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick14}
                  >
                    <i class="fa fa-question" aria-hidden="true"></i>
                    <span>Ask question</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive14
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "ask-question"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/ask-question"
                      >
                        Webinar ask question
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "ask-question-bbimpact"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/ask-question-bbimpact"
                      >
                       BB Impact ask question
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "ask-question-bbimpact"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/ask-question-bbimpact-live"
                      >
                       BB Impact Live session ask question
                      </Link>
                    </li>
                  
                  </ul>
                </li>
                {/* report */}
                <li className="dropdown">
                  <Link
                    className={
                      isActive13
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick13}
                  >
                    <i class="fa fa-address-book-o" aria-hidden="true"></i>
                    <span>Report</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive13
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "health-report"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/health-report"
                      >
                        Health report
                      </Link>
                    </li>
                
                  
                  </ul>
                </li>

                {/* bundle */}

                {/* <li className="dropdown">
                  <Link
                    className={
                      isActive12
                        ? "nav-link menu-title active"
                        : "nav-link menu-title "
                    }
                    onClick={handleClick12}
                  >
                    <i class="fa fa-address-book-o" aria-hidden="true"></i>
                    <span>Bundle</span>
                    <div className="according-menu">
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Link>
                  <ul
                    className={
                      isActive12
                        ? "nav-submenu menu-content active"
                        : " nav-submenu menu-content open_sub_menu "
                    }
                  >
                    <li>
                      <Link
                        className={
                          currentPath == "certificate"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bundle-activation"
                      >
                        Activation
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentPath == "certificate"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/bundle-activation-list"
                      >
                        Activation list
                      </Link>
                    </li>
                     <li>
                      <Link
                        className={
                          currentPath == "add-certificate"
                            ? "nav-link menu-title active"
                            : "nav-link menu-title"
                        }
                        to="/add-certificate"
                      >
                       Add certificate
                      </Link>
                    </li> 
                  
                  </ul>
                </li> */}
               
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* <!-- Page Sidebar end-->  */}
    </>
  );
}

export default Sidebar;
