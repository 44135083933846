import Axios, { setToken } from '../Axios';
import { getToken } from '../Utility';

const token = getToken();
export const addSemester = async (data = {}) => {


  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/semesters/add`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const updateSemester= async (data = {},id) => {


  let result = { success: false }
  try {
    let res = await Axios.put(`/admin-api/v1/semesters/${id}`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const semesterDetails = async (id) => {


  let result = { success: false }
  try {
    let res = await Axios.get(`/admin-api/v1/semesters/${id}`, {});
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const deleteSemesters = async (id) => {


  let result = { success: false }
  try {
    let res = await Axios.delete(`/admin-api/v1/semesters/${id}`, {});
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const semesterList = async (data = {}) => {


  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/semesters`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}
