import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";

import {
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { assesmentDetails, saveAssesment } from "../../../http/session";
import {
  campaignList,
  campaignMapping,
  formDetails,
  saveForm,
  updateForm,
} from "../../../http/feedback";
import { campaignReportList, getHealthReport } from "../../../http/report";

function HealthReport(props) {
  const [campaignId, setCampaignId] = useState("");
  const [campaignData, setCampaignData] = useState([]);

  const [CSVFile, setCSVFile] = useState(null);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const formId = location?.state?.formId || "";
  const type = location?.state?.type || "";

  const formType = props?.formType;

  const getCampaignList = async () => {
    const payload = {
      type: "dropdown",
    };

    const res = await campaignReportList(payload);

    if (res.success) {
      setCampaignData(res.data.rows);
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  useEffect(() => {
    getCampaignList();
  }, []);

  const [isLoader, setLoader] = useState(false);
  const [isImporting, setIsImproting] = useState(false);
  const handleFileButtonClick = () => {
    document.getElementById("fileupload").click();
  };

  const getFormData = async () => {
    let res = await formDetails(formId);
    console.log(res);
    if (res?.success) {
      if (res.data.length != 0) {
        // alert('fs')
        // setQuestion([...res.data[0]?.assessment]);
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };

  const handleSubmit = async (e, values) => {
    console.log(campaignId);

    if (campaignId) {
      
      const res = await getHealthReport(campaignId);

     console.log(res)
     
      if (!!res && res?.success != false) {
        console.log(res);
        showToast(
          res?.message,
          "success",
          3000 // Custom timeout duration (5 seconds)
        );
      } else if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res.message,
          "error",
          1000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    } else {
      showToast(
        "Please select campaign.",
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
    // const isCheckAssesment = finalAssignmentData();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    const maxSize = 9 * 1024 * 1024; // 9MB in bytes

    // Check if the file is a CSV file
    if (!file.name.endsWith(".csv")) {
      showToast(
        "Please select a CSV file.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("Please select a CSV file.");
      document.getElementById("fileupload").value = "";

      return;
    }

    // Check file size
    if (file.size > maxSize) {
      showToast(
        "File size exceeds the limit of 9MB.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("File size exceeds the limit of 9MB.");
      document.getElementById("fileupload").value = "";

      return;
    }

    setCSVFile(file);
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          Health report
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12">
                          <label className="rounded-md mb-2">
                          Campaign*
                                </label>
                            {/* <DropdownBox
                              label="Campaign*"
                              value={campaignId}
                              onChange={(e) => setCampaignId(e?.target?.value)}
                            >
                              <option value="">Select</option>

                              {campaignData &&
                                campaignData?.map((item, index) => (
                                  <option value={item?._id} key={index}>
                                    {item?.name}
                                  </option>
                                ))}

                            </DropdownBox> */}
                            {
                              campaignData &&
                            <Select
                                
                                  name="colors"
                                  options={campaignData}
                                  onChange={(e) => setCampaignId(e?._id)}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option._id}
                                />
                            }
                          </div>
                         
                        </div>

                        <div className="col-span-12 mt-2">
                          <div className="justify-end flex">
                            
                            <button
                              onClick={handleSubmit}
                              className="btn_table blue-bg ms-1 py-2"
                            >
                            Download Report
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container-fluid end--> */}
          </div>
        </div>
      </div>
      {/* <!-- Page Body end--> */}

      {isLoader && <Loader />}
    </>
  );
}

export default HealthReport;
