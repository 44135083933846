import moment from 'moment';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import DropdownBox from '../../../components/core/DropdownBox/Index';
import { AskQuestion, AskQuestionForBB, AskQuestionForBBNonLive } from '../../../http/Ask';
import { useToast } from '../../../helper/custom hooks/useToast';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../../components/core/Table/Index';
import PaginationsList from '../../Courses/PaginationsList';

export default function AskQuestionBBImpact() {
          const [idArray, setIdArray] = useState([]);
          const [selectedValue, setSelectedValue] = useState('');
          const [latestId, setLatestId] = useState('');
          const [isFetching, setIsFetching] = useState(false);
          const [timeoutId, setTimeoutId] = useState(null);
          const { showToast } = useToast();
          const latestIdRef = useRef('');
  const [perPageLimit, setLimit] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState([]);
  const [pageCount, setPageCount] = useState(1);


          const navigate = useNavigate();
          const timeoutIdRef = useRef(null);
          const today=moment().format('YYYY-MM-DD');
          const tomorrow=moment().add(1, 'days').format('YYYY-MM-DD');
          const yesterday=moment().subtract(1, 'days').format('YYYY-MM-DD');
          const columns = useMemo(
            () => [
              // { label: "#", accessor: "id" },
              {
                label: "User name",
                accessor: "user_name",
                sortable: false,
                sortbyOrder: "ASC",
                isHTML: false,
              },
              {
                label: "Question",
                accessor: "question",
                sortable: false,
                sortbyOrder: "ASC",
                isHTML: false,
              },
              {
                label: "Topic",
                accessor: "topic_name",
                sortable: false,
                sortbyOrder: "ASC",
                isHTML: false,
              }
              
            ],
            []
          );
          useEffect(() => {
            fetchData();
          }, [currentPage, perPageLimit]);
          const handlePageChange = (selectedObject) => {
            setCurrentPage(selectedObject.selected + 1);
          };
          const fetchData = async () => {
                  try {
                    // Step 3: Make API call to get array of IDs
        let payload={  "page": currentPage - 1,
        "pageSize":perPageLimit
    }
                    const res = await AskQuestionForBBNonLive(payload);
                    if (res.success) {
                      console.log(res)
                      setTotal(res?.data?.totalCount || 0);
                      setPageCount(Math.ceil(res?.data?.totalCount / perPageLimit));
                      setIdArray([...res?.data?.rows]);
                              // if(selectedValue==moment().format('YYYY-MM-DD')){
        
                                // setLatestId(res?.data[0]?.id)
                              // }
                            // latestIdRef.current = res?.data[0]?.id;
        
                    }else {
                            if (res?.status === 401) {
                              showToast(
                                res?.message,
                                "danger",
                                3000, // Custom timeout duration (5 seconds)
                                () => {}
                              );
                              sessionStorage.clear();
                              navigate("/");
                            } else {
                              showToast(
                                res?.message,
                                "danger",
                                3000, // Custom timeout duration (5 seconds)
                                () => {}
                              );
                            }
                          }
              
                    // Step 4: Use the first ID from the array as a payload
        
              
         
                  } catch (error) {
                    console.error('Error fetching data:', error);
                  }
                };
              
                const fetchDataWithPayload = async () => {
                  try {
                    // Step 3: Make API call to get array of IDs
                //     const response = await fetch('https://conappdevapi.badabusiness.co.in//admin-api/v1/live-session-campaign/ask-questions',{
                //       method: "POST",
                //       headers:{
                //                 'content-type':'application/json'
                //       },
                //      body:JSON.stringify({  "filterDate": selectedValue,
                //      "last_ask_question_id": latestIdRef.current
              
                //  })
                //     });
                let payload={  "filterDate": moment().format('YYYY-MM-DD'),
                      "last_ask_question_id": latestIdRef.current}
                const res=await AskQuestionForBB(payload)
                    // const data = await response.json();
                 
                    latestIdRef.current = res?.data[0]?.id;
                    setIdArray(preState=>[...res?.data,...preState]);
              
                    // Step 4: Use the first ID from the array as a payload
                 
              
           
                  } catch (error) {
                    console.error('Error fetching data:', error);
                  }
                };
                const scheduleFetchData = () => {
                  
                  // Schedule the fetchData function to run every 30 seconds
                  timeoutIdRef.current =  setTimeout(async () => {
                            console.log({isFetching})
                            if (isFetching) {
                              await fetchDataWithPayload();
                      scheduleFetchData(); // Call itself to run again after 30 seconds
                    }
                  }, 30000);
                
                }; 
        
          useEffect(()=>{
                 
        
          //         if(selectedValue!==''){
               
        
                            fetchData()
                          
          //         }
        
                  if(latestIdRef.current !== '' 
                  
          //         &&selectedValue==moment().format('YYYY-MM-DD')
                  ){
                         
                           
                             setIsFetching(true)
                  }else{
                            setLatestId('')
                            latestIdRef.current='';
                            setIdArray([])
                         setIsFetching(false)
                 
                  clearTimeout(timeoutIdRef.current);
                  }
        
          },[latestId,selectedValue])
        
          useEffect(() => {
                  // Make the initial API call
                console.log(isFetching)
              
                  // Start the timer for subsequent API calls
                  
                  scheduleFetchData();
              
                  // Cleanup on component unmount
                  return () =>{   clearTimeout(timeoutIdRef.current);clearTimeout(timeoutId);}
                }, [isFetching]);
                useEffect(()=>{
                  return () =>{  clearTimeout(timeoutIdRef.current); clearTimeout(timeoutId);}
                },[])
                const getCurrentIndex = (index) => {
                  return (currentPage - 1) * perPageLimit + index;
                };
        
          return (
                  <div className="container mx-auto my-8">
                  <div className="sticky top-0 bg-white p-4 z-10">
                    <h1 className="text-3xl font-bold mb-4">Ask question for BB impact</h1>
                    {/* <DropdownBox value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} className="mb-4">
                      <option value={''}>Select</option>
                      <option value={yesterday}>Yesterday</option>
                      <option value={today}>Today</option>
                      <option value={tomorrow}>Tomorrow</option>
                    </DropdownBox> */}
                  </div>
                  <div className="">
                  {/* {
                    idArray.length!=0 &&
                  <TableComponent
                                    data={idArray}
                                    columns={columns}
                                    currentPage={1}
                                  />
                  } */}
                  <table className="table min-w-full divide-y divide-gray-200">
      <thead className="border-b dark:border-neutral-500">
        <th
          key={-1}
          className="bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r"
        >
          S.No
        </th>
        {columns.map((item, index) => (
          <th
            key={index}
            className="bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r"
          >
            {item?.label}
          </th>
        ))}
      </thead>
      <tbody className="divide-y divide-gray-200">
        {idArray.map((item, index) => {
          return (
            <tr key={index} className="border-b dark:border-neutral-500">
            <td
                key={-1}
                className="px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500"
              >
                {/* {index + 1} {currentPage} */}
                {getCurrentIndex(index + 1)}
              </td>
             
             
              {columns.map((cell, cellIndex) => {
                    
                  {/* return  <td
                      key={cellIndex}
                      className="px-3 py-2 bg-white capitalize w-[200px] border-r dark:border-neutral-500 min-w-[50rem] whitespace-break-spaces"
                    >
                   
                    {item[cell?.accessor]}
                    
                    </td> */}
                    if (
                  cell.accessor == "question" 
                ) {
                  return (
                    <td
                      key={cellIndex}
                      className="px-3 py-2 bg-white capitalize w-[200px] border-r dark:border-neutral-500 max-w-[50rem] whitespace-break-spaces"
                    >
                   
                    
                     <>{ item[cell?.accessor]}</>
                    
                    
                    </td>
                  );
                } else{
                  return (
                    <td
                      key={cellIndex}
                      className="px-3 py-2 bg-white capitalize w-[200px] border-r dark:border-neutral-500"
                    >
                   
                    
                     <>{ item[cell?.accessor]}</>
                    
                    
                    </td>
                  );
                }
                 
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    {idArray.length > 0 && <PaginationsList
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                      />}
                    {/* <ul className="list-disc pl-7 ml-2">
                      {idArray.map(item => (
                        <li key={item.id} className="mb-2">{item?.username||'N/A'} - {item.question}</li>
                      ))}
                    </ul> */}
                  </div>
                </div>
          );
        };
        