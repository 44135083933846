import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";

import {
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { assesmentDetails, saveAssesment } from "../../../http/session";
import { formDetails, frameworkDetails, saveForm, saveFramework, updateForm } from "../../../http/feedback";

function FrameWorkAdd(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isMandatroy, setIsMandatroy] = useState('optional');
  const [isPublished, setIsPublished] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const formId = location?.state?.topicID || "";
  const type = location?.state?.type || "";

  const formType = props?.formType;

  const [isLoader, setLoader] = useState(false);

  const getFormData = async () => {
          let payload={    "topic_id":formId
}
    let res = await frameworkDetails(payload);
    console.log(res);
    if (res?.success) {
      if (res.data.length != 0) {
        // alert('fs')
        setName(res.data[0]?.name);
        setDescription(res.data[0]?.description);
        setIsPublished(res.data[0]?.published);

       
     

        setQuestion([...res.data[0]?.questions]);
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };
  useEffect(() => {
            getFormData();
   
  }, []);


  const makeID=()=>{
          let tempQuestion=question;
          tempQuestion.map((item,index)=>{item._id=index+1})
  }

  const handleSubmit = async (e, values) => {
    if (name && description) {
makeID()
      const isCheckAssesment = finalAssignmentData();
      if (isCheckAssesment) {
      setIsSubmit(true)
        let payload = {
          name: name,
          description: description,
          questions: question,
          published: isPublished,
          form_priority: isMandatroy ,
          topic_id:formId
        };

        let res;
        console.log(payload)
        res=await saveFramework(payload)
    

//         res =
//           formType == "add"
//             ? await saveForm(payload)
//             : await updateForm(payload, formId);

        // setIsSubmit(false)

        if (res?.success) {
          setIsSubmit(false)
          navigate("/topic-list");
          showToast(
            res?.message,
            "success",
            1000, // Custom timeout duration (5 seconds)
            () => {}
          );
        } else {
          setIsSubmit(false)
          if (res?.status === 401) {
            showToast(
              res?.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
            sessionStorage.clear();
            navigate("/");
          } else {
            setIsSubmit(false)
            showToast(
              res?.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
          }
        }
      }
    } else {
      showToast(
        "Please all fill mandatory.",
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
    // const isCheckAssesment = finalAssignmentData();
  };
  const handleChangeQuestionType = (e, index) => {
    let tempdata = question;

    tempdata[index].question_type = e.target.value;

    tempdata[index].options = ["", ""];

    if(e.target.value=='text' ||e.target.value=='textarea'){
          tempdata[index].char_length=2000
          tempdata[index].options=['']
    }else{
          tempdata[index].char_length=0
          tempdata[index].options=['','']


    }
    if(e.target.value=='rating'){
      tempdata[index].enable_ask_bb_coach=false
}else{
  tempdata[index].enable_ask_bb_coach=true

}

    setQuestion([...tempdata]);
  };

  const [question, setQuestion] = useState([
    {
      question: "",
      question_type: "text",
      options: [""],
      placeholder: "",
      question_priority: "optional",
//       published: false,
      order: 0,
      char_length:2000,
      enable_ask_bb_coach:true,
      status:'enabled',
      enable_instruction:false,
      instruction:''

    },
  ]);
  const addOption = (index) => {
    let tempQuestion = question;
    tempQuestion[index].options.push("");
    setQuestion([...tempQuestion]);
  };

  const deleteOption = (index, innerIndex) => {
    let tempQuestion = question;
    tempQuestion[index].options.splice(innerIndex, 1);
// tempQuestion[index].status='deleted'
    setQuestion([...tempQuestion]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].options[innerIndex] = e.target.value;
    setQuestion([...tempdata]);
  };
  const handleQuestionTitle = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].question = e.target.value;
    setQuestion([...tempdata]);
  };
  useEffect(()=>{
    console.log({question})
        },[question])
  const finalAssignmentData = () => {
  
    let tempdata = [...question];
    tempdata?.map((item, index) => {
      

        // item.options = item.options.filter((v) => v != "");
      
    });

    


    for (let i = 0; i <= tempdata.length - 1; i++) {
          if(tempdata[i].status!='deleted'){
            if(tempdata[i].instruction=='' && tempdata[i].enable_instruction){
              showToast(
                `add instruction in  question title ${tempdata[i].question}`,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {}
              );
              // setQuestion([...tempdata]);
              // setIsSubmit(false)
              return false;
              break;
            }
          //  debugger;

                    // if (!(tempdata[i]?.options.some(option => option.trim() === '')) && tempdata[i].question_type=='rating') {

                    //   showToast(
                    //     `add at least 2  options in question number`,
                    //     "danger",
                    //     3000, // Custom timeout duration (5 seconds)
                    //     () => {}
                    //   );
                    //   // setQuestion([...tempdata]);
                    //   // setIsSubmit(false)
                    //   return false;
                    //   break;
                    // } else
                     if (tempdata[i].question == "") {
                      showToast(
                        `add question title in  question title ${tempdata[i].question}`,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {}
                      );
                      // setQuestion([...tempdata]);
                      // setIsSubmit(false)
                      return false;
                      break;
                    }else if(tempdata[i].question_type=='text' || tempdata[i].question_type=='textarea'){
                      
                        // alert('hi')
                        if(tempdata[i].char_length==0){
              
                                  showToast(
                                    `Charcter count can't be zero`,
                                    "danger",
                                    3000, // Custom timeout duration (5 seconds)
                                    () => {}
                                  );
                                  return false;
                                  break;
                        }
                        // else if (tempdata[i]?.options.some(element => element === "") && tempdata[i]?.options.length>1) {
                        //   showToast(
                        //     `Blank options are not allowed in question title ${tempdata[i].question}`,
                        //     "danger",
                        //     3000, // Custom timeout duration (5 seconds)
                        //     () => {}
                        //   );
                        //   // setQuestion([...tempdata]);
                        //   // setIsSubmit(false)
                        //   return false;
                        //   break;
                        // }
                        // else if(!(tempdata[i]?.options.some(option => option.trim() === ''))){
                        //   showToast(
                        //     `add at least 1  options in question number`,
                        //     "danger",
                        //     3000, // Custom timeout duration (5 seconds)
                        //     () => {}
                        //   );
                        //   return false;
                        //   break;
                        // }
                      }else if(tempdata[i].question_type!=''){
              
                      if (tempdata[i]?.options.some(element => element === "")) {
                        showToast(
                          `Blank options are not allowed in question title ${tempdata[i].question}`,
                          "danger",
                          3000, // Custom timeout duration (5 seconds)
                          () => {}
                        );
                        // setQuestion([...tempdata]);
                        // setIsSubmit(false)
                        return false;
                        break;
                      }
                    }
              //       else if(hasDuplicateOrder(tempdata)){
              //           showToast(
              //             `Order should not be same in questions`,
              //             "danger",
              //             3000, // Custom timeout duration (5 seconds)
              //             () => {}
              //           );
              //           return false;
              //           break;
              //         }
          }
        
    }

    //
    //  setIsSubmit(false)
    // setQuestion([...tempdata]);
    return true;
  };
  const addQuestion = () => {
    setQuestion([
      ...question,

      {
        question: "",
      question_type: "text",
      options: [""],
      placeholder: "",
      question_priority: "optional",
      char_length:2000,
      order: 0,
      enable_ask_bb_coach:true,
      status:'enabled',
      enable_instruction:false,
      instruction:''
      },
    ]);
  };
  const deleteQuestion = (index) => {
    let tempQuestion = question;
//     tempQuestion.splice(index, 1);
tempQuestion[index].status='deleted'
    setQuestion([...tempQuestion]);
  };
  const handleQuestionOrder = (index, e) => {
    let tempdata = question;
    console.log(e?.target?.value!=undefined)
    if(e?.target?.value!=undefined){

  tempdata[index].instruction = e?.target?.value;
  setQuestion([...tempdata]);
}
  };
  const handleQuestionPlaceholder = (index, e) => {
    let tempdata = question;
if(e?.target?.value!=undefined){

          tempdata[index].char_length = e?.target?.value;
}
    setQuestion([...tempdata]);
  };
  const handleRequired = (index, e) => {
    let tempdata = question;

    tempdata[index].enable_instruction = e?.target?.checked
     
    setQuestion([...tempdata]);
  };

  
  const hasDuplicateOrder=(arr)=> {
    
    const orderSet = new Set();
  
    for (const obj of arr) {
      if (obj.hasOwnProperty('order')) {
        const orderValue = obj.order;
  // console.log(orderSet,orderValue)
        if (orderSet.has(orderValue)) {
  console.log(orderSet,orderValue)

          return true;  // Duplicate order found
        }
  
        orderSet.add(orderValue);
      }
    }
  
    return false;  // No duplicate orders found
  }

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          Framework
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12 lg:col-span-6">
                            <InputBox
                              label="Name*"
                              value={name}
                              onChange={(e) => setName(e?.target?.value)}
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <InputBox
                              label="Description*"
                              value={description}
                              onChange={(e) => setDescription(e?.target?.value)}
                              placeholder="Enter Description"
                            />
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                          
                            <div>
                              <CheckBox
                                label="Published"
                                value={isPublished}
                                onChange={(e) => {
                                  setIsPublished(e?.target?.checked);
                                }}
                              />
                            </div>
                          </div>
                        
                          <div className="col-span-12 lg:col-span-10">
                            {/* <p className="mt-0">
                              <b>NOTE</b> : Please tick option for make them
                              answer.
                            </p> */}
                            {question?.map((item, index) => {
                              return (
                                <>
                                {
                                        item?.status!='deleted'&&
                                  <div className="relative mt-2 mb-3">
                                    <div className="assessment_qus_box px-4 py-3">
                                      <div className="question">
                                        <label
                                          className="mb-3"
                                          for="exampleInputEmail1"
                                        >
                                          Question
                                        </label>
                                        <input
                                          type="text"
                                          autocomplete="off"
                                          className="border border-gray-300 px-2 rounded-md  w-full py-2"
                                          placeholder="Enter Question here"
                                          name="question_text"
                                          value={item.question}
                                          onChange={(e) =>
                                            handleQuestionTitle(e, index)
                                          }
                                        />
                                      </div>
                                      <div className="flex items-end gap-3">
                                        <DropdownBox
                                          label="Question Type"
                                          required={true}
                                          className="w-[10rem]"
                                          onChange={(e) =>
                                            handleChangeQuestionType(e, index)
                                          }
                                          value={item.question_type}
                                          name="Question type"
                                          //       onError={
                                          //         errors.type &&
                                          //         touched.type &&
                                          //           errors.type
                                          //       }
                                          //       onBlur={handleBlur}
                                        >
                                          <option
                                            value="select"
                                            selected={
                                              item.question_type == "select"
                                            }
                                          >
                                            Select
                                          </option>
                                          {/* <option
                                            value="single"
                                            selected={
                                              item.question_type == "single"
                                            }
                                          >
                                            Single
                                          </option> */}
                                          <option
                                            value="multiple"
                                            selected={
                                              item.question_type == "multiple"
                                            }
                                          >
                                            Multiple
                                          </option>
                                          <option
                                            value="text"
                                            selected={
                                              item.question_type == "text"
                                            }
                                          >
                                            Text
                                          </option>
                                          <option
                                            value="textarea"
                                            selected={
                                              item.question_type == "textarea"
                                            }
                                          >
                                            Textarea
                                          </option>
                                          {/* <option
                                            value="truenfalse"
                                            selected={
                                              item.question_type == "truenfalse"
                                            }
                                          >
                                            True False
                                          </option> */}
                                          <option
                                            value="rating"
                                            selected={
                                              item.question_type == "rating"
                                            }
                                          >
                                            Rating
                                          </option>
                                          {/* <option
                                            value="dropdown"
                                            selected={
                                              item.question_type == "dropdown"
                                            }
                                          >
                                            Dropdown
                                          </option> */}
                                        </DropdownBox>
                                      
                                        <CheckBox
                                          label="enable instractions"
                                          value={
                                            item?.enable_instruction
                                          }
                                          onChange={(e) =>
                                            handleRequired(index, e)
                                          }
                                        />
                                        {
                                          item?.enable_instruction &&<textarea
                                          label="Instructions"
                                          value={item?.instruction}
                                          placeholder="Enter instruction"
                                          onChange={(e) =>
                                            handleQuestionOrder(index, e)
                                          }
                                          type="text"
                                          className="w-full py-2 rounded-md border border-gray-300 px-2 text-[13px] !w-[39%] ml-[0.5rem] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                        />
                                        }
                                       
                                      </div>
                                      {(item?.question_type == "text" || item?.question_type == "textarea") &&
                                      <div>
                                      {/* <InputBox
                                          label="Placeholder"
                                          value={item?.placeholder}
                                          placeholder="Enter textbox's placeholder here"
                                          onChange={(e) =>
                                            handleQuestionPlaceholder(index, e)
                                          }
                                          type="text"
                                        /> */}
                                         <InputBox
                                          label="Charcter length"
                                           className='!w-[74%] ml-[0.5rem]'
                                          value={item?.char_length}
                                          type='number'
                                          placeholder="Enter charcter length"
                                          onChange={(e) =>
                                            handleQuestionPlaceholder(index, e)
                                          }
                                         
                                        />
                                      </div>
                                      }
                                      {/* {item?.question_type !== "text" && item?.question_type !== "textarea" && */}
                                 {item?.question_type && (
                                          <div className="ans-box w-9/12">
                                            {item.options?.map(
                                              (innerItem, innerIndex) => (
                                                <>
                                                  <div className="flex pt-3 items-center">
                                                    <input
                                                      type="text"
                                                      autocomplete="off"
                                                      value={innerItem}
                                                      className="border border-gray-300 px-2 ms-2 w-full py-2 rounded-md"
                                                      placeholder={(item?.question_type == "text" || item?.question_type == "textarea") ? 'Enter sub question here':'Enter option here'}
                                                      name={`option`}
                                                      onChange={(e) =>
                                                        handleQuestion(
                                                          e,
                                                          index,
                                                          innerIndex
                                                        )
                                                      }
                                                    />
                                                    <div className="add-options absolute right-[60px] bottom-[10px] ">
                                                      {(item.options.length !==
                                                        1 && item?.question_type=='text' || item.options.length !==
                                                        1 && item?.question_type=='textarea')&& (
                                                        <button
                                                          type="button"
                                                          className="blue-bg"
                                                          onClick={() =>
                                                            deleteOption(
                                                              index,
                                                              innerIndex
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </button>
                                                      )}

                                                      {
                                                        (item.options.length !==
                                                        2 && item?.question_type=='rating') &&
                                                        <button
                                                          type="button"
                                                          className="blue-bg"
                                                          onClick={() =>
                                                            deleteOption(
                                                              index,
                                                              innerIndex
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-minus"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </button>
                                                      }
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            )}
                                            {
                                              item?.question_type!='truenfalse' &&
                                            <div className="add-options absolute right-[90px] bottom-[10px]">
                                              <button
                                                type="button"
                                                className="green-bg me-2 border-0 text-sm p-[6px] border-radius-[5px]"
                                                onClick={() => addOption(index)}
                                              >
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </div>
                                            }
                                          </div>)}
                                        {/* )} */}
                                    </div>
                                    <div className="add-qustion">
                                    
                                      {index == question.length - 1 || question[question.length-1].status=='deleted' ? (
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={addQuestion}
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : null}
                                      {index != 0 && (
                                        <button
                                          type="button"
                                          className="blue-bg"
                                          onClick={() => deleteQuestion(index)}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                }
                                </>
                              );
                            })}
                          </div>

                          <div className="col-span-12">
                            <div className="justify-end flex">
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn_table green-bg py-2"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSubmit}
                                disabled={isSubmit}
                                className="btn_table blue-bg ms-1 py-2"
                              >
                                {formType == "update" ? `Update` : `Save`}
                                
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {isLoader && <Loader />}
    </>
  );
}

export default FrameWorkAdd;
