import Axios from "./Axios";


export const login = async (data = {}) => {

  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/login`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const commanAPIForGet = async (data = {},url) => {

  let result = { success: false }
  try {
    let res = await Axios.post(`${url}`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const getLeaderBoardData = async (user_id,program_id='') => {

  let result = { success: false }
  try {
    let res;
    if(program_id==''){
      res = await Axios.get(`/admin-api/v1/boards/learning-score-board/${user_id}`,{
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
    }else{

      res = await Axios.get(`/admin-api/v1/boards/learning-score-board/${user_id}/${program_id}`,{
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
    }
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

