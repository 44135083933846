import React from 'react';
import LoaderBtn from '../Loader/TableLoader';

const Button = ({ text, onClick, icon, className,type,disable,loader=false }) => {
  return (
    <button
      className={`btn-loader flex items-center px-4 py-2 bg-blue-500 text-white rounded-md ${className} text-center justify-center`}
      onClick={onClick}
      type={type}
      disabled={disable}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
     {loader && <LoaderBtn />}
    </button>
  );
};

export default Button;
