import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";

const token = getToken();
export const addSubject = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/subjects/add`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const mappingSubject = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/subjects/mapping`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const updateSubject = async (data = {}, id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/subjects/${id}`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
export const publishSubject = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/subjects/publish/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const subjectDetails = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/subjects/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const deleteSubject = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/subjects/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

export const subjectList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/subjects`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
