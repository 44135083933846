import React, { useEffect, useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import Header from "../../../Layout/Header";
import InputBox from "../../../components/core/InputBox/Index";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import * as Yup from "yup";
import { Formik } from "formik";
import { addProgram } from "../../../http/program";
import { async } from "q";
import { useNavigate,useLocation } from "react-router";
import { useToast } from "../../../helper/custom hooks/useToast";
import CheckBox from "../../../components/core/checkbox/Index";
import ImageUploader from "../../../components/core/ImageUploader";
import { convertJsonToFormData } from "../../../helper/utils";
import Flip from "./Flip";
import Flash from "./Flash";
import Poll from "./Poll";
import Match from "./Match";
import { addAssesment, addAssesmentById, assesmentByID, assesmentDeleteById, assesmentList, pollById } from "../../../http/Assesment";
import TableComponent from "../../../components/core/Table/Index";
import PaginationsList from "../PaginationsList";
import { useMemo } from "react";
import Modal from "../../../components/core/Modal/Index";
import NoDataListFound from "../../../Layout/NoDataListFound";
import { assesmentDetails, launchActivityById } from "../../../http/session";
import './style.css'
import Button from "../../../components/core/Button/Index";
const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}


function Assesment(props) {

  const [perPageLimit, setLimit] = useState(10);
  const [pollResult, setPollResult] = useState(null);
  const [assesmentData, setAssesmentData] = useState(null);
  const [assessmentTypeError, setAssessmentTypeError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [assesmentListData, setAssesmentListData] = useState([]);



  const navigate = useNavigate();
  const location=useLocation();
  const source=location?.state?.source;
  const sessionId=location?.state?.sessionId;
  const topicId=location?.state?.topicId;
  const duration=location?.state?.duration;
  const [formType,setFormType] = useState(location?.state?.formType || props?.formType)
  const assesmentSchema = Yup.object().shape({
    type  : Yup.string().required("Required"),
    title  : Yup.string().required("Required"),
    location: Yup.number().typeError('Activity location should be in number').required("Required"),
    timerInSec:Yup.number().typeError('timer should be in number').required("Required"),
   
  });
  const [match,setMatch]=useState([{title:'',answer:'',marks:0} ,{title:'',answer:'',marks:0} ])
  const [pageCount, setPageCount] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const [isModalOpenPoll, setModalOpenPoll] = useState(false);

  const [showError, setShowError] = useState('');

  const [tableData, setTableData] = useState([]);
 


  const columns = useMemo(
    () => [
      {
        label: "ACTIVITY TITLE",
        accessor: "title",
        sortable: false,
        sortbyOrder: "ASC",
      },
      {
        label: "TYPE",
        accessor: "type",
        sortable: false,
        sortbyOrder: "ASC",
      },
   
      {
        label: "QUESTION'S TITLE",
        accessor: "activitySet[0].options",
        sortable: false,
        sortbyOrder: "ASC",
        isQuestion: true,

      },
      {
        label: "TOTAL QUESTION'S OPTION NO. / TOTAL QUESTION NO.",
        accessor: "activitySet[0].options",
        sortable: false,
        sortbyOrder: "ASC",
        isQuestion: true,

      },
      {
        label: "TOTAL QUESTION'S ANSWER NO.",
        accessor: "activitySet[0].options",
        sortable: false,
        sortbyOrder: "ASC",
        isQuestion: true,

      },
      {
        label: "MARKS",
        accessor: "activitySet[0].options",
        sortable: false,
        sortbyOrder: "ASC",
        isQuestion: true,

      },
      
      {
        label: "ACTION",
        accessor: "action",
        sortable: false,
        sortbyOrder: "ASC",
      },
    ],
    []
  );


  const [currentPage, setcurrentPage] = useState(1);
  // useEffect(() => {
  //   getTopicList();
  // }, []);
  useEffect(()=>{
    getAssessmentData()
  },[])
  useEffect(()=>{
    console.log('assesmentListData = ',assesmentListData?.[0]?.assessment?.length>0)
  },[assesmentListData])
  useEffect(() => {
  

    
    getAssesmentList();
  }, [currentPage, perPageLimit]);
  const handlePageChange = (selectedObject) => {
    
    setcurrentPage(selectedObject.selected + 1);
  };

useEffect(() => {
  let intervalId;

  // Function to start the interval when the state changes
  const startInterval = () => {
    intervalId = setInterval(() => {
      // Do something at each interval
      // console.log('Interval tick');
      // console.log(pollResult)
      getPollDataByIdInterval(pollResult?._id)
    }, 1000); // Change the interval duration as needed
  };

  // Function to clear the interval when the state resets
  const clearIntervalFn = () => {
    clearInterval(intervalId);
  };

  // Start the interval when the state changes
  if (isModalOpenPoll) {
    startInterval();
  } else {
    clearIntervalFn();
  }

  // Cleanup the interval when the component unmounts or when the state resets
  return () => {
    clearIntervalFn();
  };
}, [isModalOpenPoll]);
const getPollDataByIdInterval=async(id)=>{
  let payload={
    "activity_id": id,
    "type": "poll"
}

let res = await pollById(payload)
if(res.success){
  setPollResult(res?.data)
  console.log(res)
  // setModalOpenPoll(true)
}

}

  const { showToast } = useToast();
  const [flipData,setFlipData]=useState([{question:'',answer:'',marks:0} ])
  const [question, setQuestion] = useState([
    {
      question_text: "",
      marks: 0,
      Questype:'single',
      options: [],
      count: 0,
      tempOption: [
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
      ],
    },
  ]);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
 
    setModalOpen(false);
  };
  const handleEdit = (id, index) => {
    setSelectedItem(id)
    getAssesmentById(id)
    setFormType('update')
  };
  const viewPoll = (id, index) => {
    getPollDataById(id)
   console.log(id,index)
  };
  const handleLaunch = (id, index) => {
    console.log(id)
    launchById(id)
    // setSelectedItem(id)
    // getAssesmentById(id)
    // setFormType('update')
  }

  const getPollDataById=async(id)=>{

    let payload={
      "activity_id": id,
      "type": "poll"
  }

  let res = await pollById(payload)
  if(res.success){
    setPollResult(res?.data)
    console.log(res)
    setModalOpenPoll(true)
  }

  }

  const launchById=async(id)=>{
let payload={
  "activity_id": id,
  "reference_id":sessionId,
  "activity_source":'session'
}
    let res = await launchActivityById(payload)

    if(res.success){

      showToast(
        res.message,
        "success",
        2000, // Custom timeout duration (5 seconds)
        () => {
        }
      )

    }


  }

  const handleDelete = (id, index) => {
    setModalOpenDelete(true)
    setSelectedItem(id)
  };
  const hadnleStatus = (id, index) => {
    handleOpenModal()
    setSelectedItem(id)

  };

  const getAssesmentList = async () => {
  

    const payload = {
      page: currentPage - 1,
      // type:"list",
      activity_source: source || 'topic',
      reference_id: source=='session' ? sessionId: topicId
    };
    // if (!!subjectId) {
    //   payload.subject_id = subjectId;
    // }
    // if (!!chapterId) {
    //   payload.chapter_id = chapterId;
    // }
    // if (!!unitId) {
    //   payload.unit_id = unitId;
    // }
    const res = await assesmentList(payload);
   
    if (res.success) {
    
      setPageCount(Math.ceil(res.data.totalCount / perPageLimit));
      let dataArr = res?.data?.rows;
      dataArr.map((item, index) => {
        if(source=='session'){

          item.action = {
            edit: handleEdit,
            delete: handleDelete,
            launch:handleLaunch,
            viewPoll:viewPoll
            // status: hadnleStatus,
  
          };
        }else{
          item.action = {
            edit: handleEdit,
            delete: handleDelete,
            // viewPoll:viewPoll
            // launch:handleLaunch
            // status: hadnleStatus,
  
          };
        }


      });

      if(source!=='session'){
      
        columns.splice(2,0,{
          label: "LOCATION",
          accessor: "location",
          sortable: false,
          sortbyOrder: "ASC",
        })
      
      }
      
      setTableData(dataArr);
     
    }else{
      if(res.status===401){

        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
        sessionStorage.clear()
        navigate('/')

      }else{
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
      }
    }
  };
  const finalAssignmentData = () => {
    let tempdata = question;
    
    question.map((item, index) => {
      item.options=[];
      item.answer=[];
      item.marks=parseInt(item.marks)
      item.tempOption.map((innerItem, innerIndex) => {
        if (innerItem.isChecked) {
          item.count += 1;
          item.answer.push(innerItem.option);
        }
        item.options.push(innerItem.option);

        // item.options.push(innerItem.option)
      });

      if (item.count == 1) {
        item.Questype = "single";
      }
      if (item.count > 1) {
        item.Questype = "multiple";
      }
    });
    tempdata[0].answer = [...new Set(tempdata[0].answer)];
    tempdata[0].options = [...new Set(tempdata[0].options)];
    setQuestion([...tempdata]);
    return null;
  };
  const finalAssignmentDataForPoll =()=>{

    let tempdata = question;
    
    question.map((item, index) => {
      item.options=[];
      item.marks=parseInt(item.marks)
      item.question=item.question_text
      item.tempOption.map((innerItem, innerIndex) => {
        if (innerItem.option!='') {
          item.count += 1;
          item.options.push({ 
          "option_value": innerItem.option,
        //  "isCorrect": innerItem.isChecked
  });
        }

        
      });

    
    });
   
    tempdata.map((item,index)=>{
      item.options.map((innerItem,innerIndex)=>innerItem.option_id=innerIndex+1)
    })
    tempdata[0].options = [...new Set(tempdata[0].options)];
    // tempdata.
    setQuestion([...tempdata]);
    return null;



  };
  const finalAssignmentDataForFlash = () => {

    let tempdata = question;
    
    question.map((item, index) => {
      item.options=[];
      item.marks=parseInt(item.marks)
      item.question=item.question_text
      item.tempOption.map((innerItem, innerIndex) => {
        if (innerItem.option!='') {
          item.count += 1;
          item.options.push({ 
          "option_value": innerItem.option,
          "isCorrect": innerItem.isChecked
  });
        }

        
      });

    
    });
   
    tempdata.map((item,index)=>{
      item.options.map((innerItem,innerIndex)=>innerItem.option_id=innerIndex+1)
    })
    tempdata[0].options = [...new Set(tempdata[0].options)];
    // tempdata.
    setQuestion([...tempdata]);
    return null;



  };
  const cleanDataForFlash=(tempData)=>{
tempData.map((item,index)=>{
  if(item.Questype='single'){
    let count = 0;
  item.tempOption.map((innerItem,innerIndex)=>{
    if(innerItem.isChecked){
      count=count+1
    }
  })
  if(count>1){
    item.tempOption.map((innerItem,innerIndex)=>{
      if(innerItem.isChecked){
       innerItem.isChecked=false
      }
    })
  }
  }
})
console.log(tempData)
setQuestion([...tempData])

  }
  const cleanDataForPolls=(tempData)=>{
    tempData.map((item,index)=>{
      if(item.Questype='single'){
        let count = 0;
      item.tempOption.map((innerItem,innerIndex)=>{
        if(innerItem.isChecked){
          count=count+1
        }
      })
      if(count>1){
        item.tempOption.map((innerItem,innerIndex)=>{
          if(innerItem.isChecked){
         //  innerItem.isChecked=false
          }
        })
      }
      }
    })
    console.log(tempData)
    setQuestion([...tempData])
    
      }
  const getAssesmentById = async (id) => {
    const res = await assesmentByID(id);
    if (res.success) {
     
     setAssesmentData(res.data[0])
     if(res.data[0].type==='match'){
      setMatch(res.data[0].activitySet)
     }else if(res.data[0].type==='flash'){
      cleanDataForFlash(res.data[0].activitySet)
      // setQuestion(res.data[0].activitySet)
     
    }else if(res.data[0].type==='poll'){
      cleanDataForPolls(res.data[0].activitySet)
      // setQuestion(res.data[0].activitySet)
     
    }else if(res.data[0].type==='flip'){
      setFlipData(res.data[0].activitySet)
     
    }
  }else{
    if(res.status===401){

      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {
        }
      )
      sessionStorage.clear()
      navigate('/')

    }else{
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {
        }
      )
    }
  }
}

const checkData=()=>{
  let tempdata=question
  for(let i=0;i<=tempdata.length-1;i++){
    
    // if(tempdata[i].options.length!==4){
    
    //   showToast(
    //     `add all options in question number ${i+1}`,
    //     "danger",
    //     3000, // Custom timeout duration (5 seconds)
    //     () => {
    //     }
    //   )
    //   setQuestion([...tempdata]);
    //   return false;
    //   break;


    // }else 
    if(tempdata[i].options.length===0){
      
      showToast(
        `add atleast one answer  in question number ${i+1}`,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {
        }
      )
      setQuestion([...tempdata]);
      return false;
      break;

    }else if(tempdata[i].options.length<=1){
       
  
        showToast(
          `Add at least two options in question number ${i+1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
        setQuestion([...tempdata]);
        return false;
        break;
  
  
      }

      let correctAnswerFound = false;
      for (const option of tempdata[i].options) {
        if (option.isCorrect) {
              correctAnswerFound = true;
              break;
          }
      }

      
      if (!correctAnswerFound) {
        showToast(
          `Question number ${i+1} should have at least one correct answer`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
          // errors.push(`Question number ${i+1}should have at least one correct answer`);
          return false
          break;
      }
  
      if(tempdata[i].marks<=0){
        showToast(
          `Marks should be greater than 0 in question number ${i+1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
          // errors.push(`Question number ${i+1}should have at least one correct answer`);
          return false
          break;

      }
      
    }
    
return true;
  
}
const checkDataPolls=()=>{
  let tempdata=question
  for(let i=0;i<=tempdata.length-1;i++){
    
    // if(tempdata[i].options.length!==4){
    
    //   showToast(
    //     `add all options in question number ${i+1}`,
    //     "danger",
    //     3000, // Custom timeout duration (5 seconds)
    //     () => {
    //     }
    //   )
    //   setQuestion([...tempdata]);
    //   return false;
    //   break;


    // }else 
    if(tempdata[i].options.length===0){
      
      showToast(
        `add atleast one option in question number ${i+1}`,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {
        }
      )
      setQuestion([...tempdata]);
      return false;
      break;

    }else if(tempdata[i].options.length<=1){
       
  
        showToast(
          `Add at least two options in question number ${i+1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
        setQuestion([...tempdata]);
        return false;
        break;
  
  
      }

     

     
      
    }
    
return true;
  
}

const getAssessmentData=async ()=>{
  let payload={
      "type": "session",
      "reference_id": sessionId
  }
  let res= await assesmentDetails(payload)
  
  if(res?.success){
    setAssesmentListData(res?.data)
  }else{
      if(res?.status===401){

        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {
          }
        )
        sessionStorage.clear()
        navigate('/')

      }else{
          showToast(
              res?.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {
              }
            )

      }
    }
}
  const handleSubmit1 = async (values) => {
    console.log('values = ',values)
    if(values.type=='assessment' && (!values.assessment_id || values.assessment_id=='select')){
      //alert('Required')
      setAssessmentTypeError('Required')
      return true
    }
    setIsSubmit(true)
    let payload={
      "type": values.type,
      "location": parseInt(values.location),
      "reference_id":source=='session' ? sessionId: topicId,
      "timerInSec":parseInt(values.timerInSec),
      title:values.title,
      "activity_source": source || 'topic',
  
    }
  
    if (source == "session") {
      payload.activitySet = [
        {
          assessment_id: parseInt(values?.assessment_id),
        },
      ];
    }

    if(values.type=='flip'){
      
      payload={...payload,activitySet:{...flipData[0],questype:'single'}}

    }else if(values.type=='flash'){
      finalAssignmentDataForFlash();
     let check= checkData()
     if(check){
     
       payload={...payload,activitySet:question}
     }else{
      setIsSubmit(false)
      return 
     }
  
  
    
      }else if(values.type=='poll'){
       
        finalAssignmentDataForPoll();
       let check= checkDataPolls()
       if(check){
       
         payload={...payload,activitySet:question}
       }else{
        setIsSubmit(false)
        return 
       }
    
    
      
        }else if(values.type=='match'){
        finalAssignmentData();
        payload={...payload,activitySet:match}
    
    
      
        }
    // const payload = {
    //   title: values.title,
    //   description: values.description,
    //   short_code: values.shortCode,
    //   duration: values.duration,
    //   duration_unit: values.durationUnit,
    //   image: values.image,
    //   status: values.status,
    // };
    // const fromData = convertJsonToFormData(payload);
    let res;
    if (!!selectedItem) {
      res = await addAssesmentById(payload, selectedItem);
    } else {
      res = await addAssesment(payload);
    }
    
    
    if (res?.success) {
      showToast(
        res.message,
        "success",
        1000, // Custom timeout duration (5 seconds)
        () => {
        }
      );
      // setIsSubmit(false)
    window.location.reload()
    }else{
      showToast(
        res?.message,
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {
         
        }
      );
      setIsSubmit(false)
      // window.location.reload()
    }
  };
  const deleteTopic=async()=>{
    const res =  await assesmentDeleteById(selectedItem)
    if(res.success){
     setModalOpenDelete(false)
     setIsSubmit(false)
      window.location.reload()
    }

  }
  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null ">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body !min-h-full">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "update"
                            ? `Edit Activity`
                            : `Add Activity`}
                        </h3>
                      </div>
                     
                      {source==='session' &&  <div>
                      <button onClick={()=>{
                            console.log(sessionId,topicId)
                              navigate("/session-assessment", {
                                state: { sessionId: sessionId ? sessionId :topicId ,type:sessionId?'session':'topic'},
                              });
                          }} className="btn_table blue-bg ms-1">
                           
                            Assessment
                          </button>
                      <button
                        disabled={! assesmentListData?.[0]?.assessment?.length>0}
                      onClick={()=>{
                              navigate("/session-assessment-preview", {
                                state: { sessionId: sessionId },
                              });
                          }} className="btn_table blue-bg ms-1">
                           
                            Assessment Preview
                          </button>
                          
                        
                      </div>
                       }

                            {source==='topic' &&  <div>
                            <button onClick={()=>{
                            console.log(sessionId,topicId)
                              navigate("/topic-faq", {
                                state: { topicID: topicId ,type:'topic'},
                              });
                          }} className="btn_table blue-bg ms-1">
                           
                            FAQ
                          </button>
                          <button onClick={()=>{
                            console.log(sessionId,topicId)
                              navigate("/framework-add", {
                                state: { topicID: topicId ,type:'topic'},
                              });
                          }} className="btn_table blue-bg ms-1">
                           
                            Framework
                          </button>                      
                          
                        
                      </div>
                       } 
                    </div>
                    <Formik
                    enableReinitialize={true}
                      validationSchema={assesmentSchema}
                      initialValues={{
                        type: assesmentData?.type ||"",
                        location: source==='session'? '0':assesmentData?.location ||"",
                        title: assesmentData?.title ||"",
                        timerInSec:assesmentData?.timerInSec ||"",
                        assessment_id: assesmentData?.activitySet?.[0]?.assessment_id || "",
                      }}
                      onSubmit={(values) => handleSubmit1(values)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                          {/* {JSON.stringify(values)}
                          {JSON.stringify(errors)} */}

                            <div className="card-body pt-0 form-author">
                              <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-12 lg:col-span-4">
                                  <DropdownBox
                                    label="Gaming Quiz Type *"
                                    value={values.type}
                                    name="type"
                                    onChange={(e)=>setFieldValue('type',e.target.value)}
                                    onError={
                                      errors.type && touched.type && errors.type
                                    }
                                    onBlur={handleBlur}
                                  >
                                    <option value="select">Select</option>
                                    <option value="flip">FLIP</option>
                                    <option value="flash">FLASH</option>
                                    {source==='session' && <option value="poll">POLL</option>}
                                    <option value="match">MATCH</option>
                                    {source==='session' && <option value="assessment">ASSESSMENT</option>}
                                  </DropdownBox>
                                </div>

                             {source!='session' &&   <div className="col-span-12 lg:col-span-4">
                                  <InputBox
                                    type="text"
                                    disabled={source==='session'}
                                    id=""
                                    placeholder="Enter Activity Position (In Seconds)"
                                    label="Activity Position (In Seconds) *"
                                    name="location"
                                    className="border border-gray-300 px-2"
                                    onChange={handleChange}
                                    value={values.location}
                                    onError={
                                      errors.location &&
                                      touched.location &&
                                      errors.location
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>}
                                <div className="col-span-12 lg:col-span-4">
                                  <InputBox
                                    type="text"
                                    id=""
                                    label="Timer in Sec *"
                                    placeholder="Enter Timer in Sec"
                                    name="timerInSec"
                                    className="border border-gray-300 px-2"
                                    onChange={handleChange}
                                    value={values.timerInSec}
                                    onError={
                                      errors.timerInSec &&
                                      touched.timerInSec &&
                                      errors.timerInSec
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>
                                
                                <div className="col-span-12">
                                  <InputBox
                                    type="text"
                                    id=""
                                    placeholder="Enter Game Quiz title"
                                    label="Gaming Quiz Title *"
                                    name="title"
                                    onChange={handleChange}
                                    value={values.title}
                                    onError={
                                      errors.title &&
                                      touched.title &&
                                      errors.title
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>
                                {
                                  values.type =='flip'? <Flip flipData={flipData} setFlipData={setFlipData}/>
                                  :values.type =='flash' ? <Flash question={question} setQuestion={setQuestion} showError={showError}/>
                                  :values.type =='poll' ? <Poll question={question} setQuestion={setQuestion} showError={showError}/>
                                  : values.type =='match' ? <Match match={match} setMatch={setMatch}/> :null
                                }

                              {values.type =='assessment' &&  <div className="col-span-12 lg:col-span-4">
                                  <DropdownBox
                                    label="Assessment List"
                                    value={values.assessment_id}
                                    name="assessment_id"
                                    onChange={(e)=>{
                                      setAssessmentTypeError("")
                                      setFieldValue('assessment_id',e.target.value)}}
                                    onError={
                                      assessmentTypeError
                                    }
                                    onBlur={handleBlur}
                                  >
                                    <option value="select">Select</option>
                                    {assesmentListData.map((item, index) => {
                                        return (
                                          <option
                                            value={item.id}
                                            selected={
                                              item.id == values.programId
                                            }
                                          >
                                            {item.title}
                                          </option>
                                        );
                                      })}
                               
                                  </DropdownBox>
                                </div>}

                                <div className="col-span-12">
                                  <div className="justify-end flex">
                                    <button className="btn_table green-bg py-2" type="button" onClick={()=>window.location.reload()}>
                                      Cancel
                                    </button>
                                    <Button
                                    disabled={isSubmit}
                                    loader={isSubmit}
                                      type="submit"
                                      className="btn_table blue-bg ms-1 py-2"
                                      text={formType == "update" ? `Update` : `Save`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                       

                        </>
                      )}
                    </Formik>
                    {/* <Flip />
                    <Flash />
                    <Match /> */}
                  </div>
                
             
            <div className="pt-0 pb-3">
            <div className="relative overflow-x-auto table-responsive">
                        {tableData.length > 0 && (
                          <TableComponent data={tableData} columns={columns} currentPage={currentPage} />
                        )}
                      </div>

                      <PaginationsList
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                      />
          </div>
          </div>
              </div>
           {/* <!-- Container-fluid end--> */}
           </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} style={{maxWidth:500,maxHeight:500}}>

      <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button onClick={handleCloseModal} type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only" onClose={handleCloseModal}>Close modal</span>
            </button>
            {/* <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
            <p class="mb-4 text-gray-500 dark:text-gray-300 mt-3">Are you sure you want to publise this item?</p>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={handleCloseModal} type="submit" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    No, cancel
                </button>
                <button onClick={publishTopic} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                    Yes, I'm sure
                </button>
            </div>
        </div>
      </Modal> */}
 <Modal isOpen={isModalOpenDelete} onClose={()=>setModalOpenDelete(false)} style={{maxWidth:500,maxHeight:500}}>

<div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
     <button onClick={()=>setModalOpenDelete(false)} type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          <span class="sr-only" onClick={()=>setModalOpenDelete(false)}>Close modal</span>
      </button>
      <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
      <p class="mb-4 text-gray-500 dark:text-gray-300 mt-3">Are you sure you want to delete this item?</p>
      <div class="flex justify-center items-center space-x-4">
          <button onClick={()=>setModalOpenDelete(false)} type="submit" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
              No, cancel
          </button>
          <button onClick={deleteTopic} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
              Yes, I'm sure
          </button>
      </div>
  </div>
</Modal>
<Modal isOpen={isModalOpenPoll} onClose={()=>setModalOpenPoll(false)} style={{maxWidth:500,maxHeight:500}} backDrop={true}>

<div class="relative popup_qu p-0 text-center bg-white rounded-lg dark:bg-gray-800">
<button onClick={()=>setModalOpenPoll(false)} type="button" class="text-gray-400 absolute top-[-8px] right-[-10px] bg-transparent  hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          <span class="sr-only" onClick={()=>setModalOpenPoll(false)}>Closess modal</span>
      </button>
    

      {
        pollResult !=null &&
      <div class="question my-[0px!important]">
      
        <h2 className="text-[18px] text-[#000] pt-5 font-semibold">{pollResult?.activitySet[0]?.question_text}</h2>
        <div className="options">
          {
            pollResult?.activitySet[0]?.options.map((item,index)=>{
           return   <div className={`option  flex justify-between option${index}`} data-percent="20">
            <div className="bar_text">
              <p className="name_bar">
                {item?.option_value}
              </p>
              <p className="vlue_bar">
              {item?.percentage}%
              </p>
            </div>
            <div class="fill-background " id="hi" data-per={item?.percentage} style={{width:`${item?.percentage}%`}}></div>
        </div>
            })
          }
        {/* <label class="option" data-percent="20">
        &#x2022; Paris
            <div class="fill-background " id="hi" style={{width:'50%'}}></div>
        </label>
        <label class="option" data-percent="50">
        &#x2022; London
            <div class="fill-background"></div>
        </label>
        <label class="option" data-percent="10">
        &#x2022;  Berlin
            <div class="fill-background"></div>
        </label>
        <label class="option" data-percent="20">
        &#x2022;  Madrid
            <div class="fill-background"></div>
        </label> */}

        </div>
        <div className="text-left text-[0.9rem] mt-4 mb-4">
             Total count : {pollResult?.totalAnswerCount}
             <button title="Refresh" onClick={()=>getPollDataByIdInterval(pollResult._id)} type="button" class="text-gray-400 ml-3 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
                <i class="fa fa-refresh" aria-hidden="true"></i>
                  <span class="sr-only">Closess modal</span>
              </button>
        </div>
    </div>
      }
     
  </div>
</Modal>
      
    </>
  );
}

export default Assesment;
