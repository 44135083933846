import { Formik } from "formik";
import React from "react";
import InputBox from "../../../components/core/InputBox/Index";
import { useState } from "react";

export default function Match({match,setMatch}) {
          
          const addMatch=()=>{
                    const tempData=match
                    tempData.push({title:'',answer:'',marks:''})
                    setMatch([...tempData])
          }
          const deleteMatch = (index) => {
                    let tempQuestion = match;
                    tempQuestion.splice(index, 1);
                    setMatch([...tempQuestion]);
                  };
                  const handleChange=(e,index)=>{
let tempdata=match
if(e?.target?.name =='marks'){
  tempdata[index][e.target.name]=parseInt(e?.target?.value)
  
  setMatch([...tempdata])
}else if(e?.target?.name){

  tempdata[index][e.target.name]=e?.target?.value
  setMatch([...tempdata])


}

                  }
                
  return (
    <>
{
    
                    match.map((item,index)=>{
                      return    <>

                <div className="col-span-12 lg:col-span-3">
                  <InputBox
                    type="text"
                    required={true}
                    id=""
                    placeholder="Enter Question"
                    label="Question *"
                    name="question"
                    value={item.question}
                   onChange={(e)=>handleChange(e,index)}
                    className='w-[10rem]'
                  />
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <InputBox
                    type="text"
                    id=""
                    required={true}
                    placeholder="Enter Answer"
                    label="Answer *"
                    name="answer"
                    value={item.answer}
                    onChange={(e)=>handleChange(e,index)}
                    className='w-[10rem]'
                  />
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <InputBox
                    type="number"
                    required={true}
                    id=""
                    min="1"
                    label="Marks"
                    name="marks"
                    value={item.marks}
                    onChange={(e)=>handleChange(e,index)}
                    className='w-[10rem]'
                  />
                </div>
                <div className="col-span-12 lg:col-span-3 relative">

                <div className="add-qustion !right-0 left-0">
                    {index == match.length - 1 ? (
                      <button
                        type="button"
                        className="green-bg me-2"
                        onClick={addMatch}
                      >
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          
                        ></i>
                      </button>
                    ) : null}
                    {index >1 ?(
                    <button
                      type="button"
                      className="blue-bg"
                      onClick={() =>
                        deleteMatch(index)
                      }
                    >
                      <i
                        className="fa fa-minus"
                        aria-hidden="true"
                        
                      ></i>
                    </button>
                    ) : null}
                  </div>
                </div>
                          </>    
                    })
                    }
                    </>
  );
}
