import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import Loader from "../../../components/core/Loader/Index";
import { assesmentDetails, saveAssesment } from "../../../http/session";
import CircleBar from "../../../components/core/CircleBar/Index";

function SessionAssesment(props) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const sessionId = location?.state?.sessionId || "";
  const [question, setQuestion] = useState([
    {
      question_text: "",
      question_type: "",
      answer: [],
      options: [],
      count: 0,
      tempOption: [
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
      ],
    },
  ]);
  const [currIndex, setCurrIndex] = useState(null);
  const [displayAns, setDisplayAns] = useState(false);
  const [score, setScore] = useState(0);
  const [isLoader, setLoader] = useState(false);

  const getAsessmentData = async () => {
    let payload = {
      type: "session",
      reference_id: sessionId,
    };
    let res = await assesmentDetails(payload);

    if (res?.success) {
      if (res.data.length != 0) {
        // alert('fs')

        setQuestion([...res.data[0]?.assessment]);
        setTimeout(() => {
          setCurrIndex(0);
        }, 500);
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };
  useEffect(() => {
    getAsessmentData();
  }, []);

  const nextQue = () => {
    setCurrIndex((prev) => prev + 1);
  };

  const preQue = () => {
    setCurrIndex((prev) => prev - 1);
  };

  const getResult = () => {
    let isDataOkay = question?.findIndex((item, index) => {
      return !item?.givenAnswer?.length > 0;
    });
    if (isDataOkay > -1) {
      showToast(
        `Please fill the answer of question no ${isDataOkay + 1}`,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    } else {
      const localQue = question;
      let score = 0;
      localQue?.map((itm) => {
        if (JSON.stringify(itm?.answer) == JSON.stringify(itm?.givenAnswer)) {
          score = score + 1;
        }
      });
      setDisplayAns(true);
      setScore(score);
    }

    // alert(isDataOkay);
  };

  useEffect(() => {
    // setQuestion([question[currIndex]]);
  }, [currIndex]);

  const handleRadio = (innerItem, question_type) => {
    let localQue = question;
    console.log("localQue = ", localQue);
    if (question_type == "single_select") {
      localQue[currIndex].givenAnswer = [innerItem];
    } else {
      if (!!localQue[currIndex]?.givenAnswer?.find((itm) => itm == innerItem)) {
        localQue[currIndex].givenAnswer.splice(
          localQue[currIndex].givenAnswer.indexOf(innerItem),
          1
        );
      } else {
        if (!!localQue[currIndex]?.givenAnswer?.length > 0) {
          localQue[currIndex].givenAnswer.push(innerItem);
        } else {
          localQue[currIndex].givenAnswer = [innerItem];
        }
      }
    }
    setQuestion([...localQue]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].tempOption[innerIndex].option = e.target.value;
    setQuestion([...tempdata]);
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          Assessment Preview
                        </h3>
                      </div>
                    </div>

                    {displayAns ? (
                      <CircleBar score={score} totalAns={question?.length} />
                    ) : (
                      <div className="flex gap-2 mb-2">
                        {question?.map((item, index) => (
                          <button
                            type="button"
                            onClick={() => {
                              setCurrIndex(index);
                            }}
                            className={
                              index == currIndex ||
                              item?.givenAnswer?.length > 0
                                ? "btn_tables green-bg py-2"
                                : "btn_tables ms-1 py-2"
                            }
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    )}

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4 ">
                          <div className="col-span-12 lg:col-span-12">
                            {displayAns
                              ? question?.map((item, index) => {
                                  return (
                                    <>
                                      <div className="relative mt-2">
                                        <div className="assessment_qus_box px-4 py-3">
                                          <div>
                                            <label
                                              className="mb-3"
                                              for="exampleInputEmail1"
                                            >
                                              {JSON.stringify(item?.answer) ==
                                              JSON.stringify(
                                                item?.givenAnswer
                                              ) ? (
                                                <span className="mr-1 text-[#57d828]">
                                                  <i
                                                    class="fa fa-check-circle-o text-[16px]"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              ) : (
                                                <span className="mr-1 text-[#d82828]">
                                                  <i
                                                    class="fa fa-times-circle-o text-[16px]"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              )}
                                              Question
                                            </label>
                                            <label
                                              className="mb-1"
                                              for="exampleInputEmail1"
                                            >
                                              {item?.question_text}
                                            </label>
                                          </div>
                                          Correct Answer :{" "}
                                          {item?.answer?.toString()}
                                          <br />
                                          {"     "}
                                          Given Answer :{" "}
                                          {item?.givenAnswer?.toString()}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : [question[currIndex]]?.map((item, index) => {
                                  return (
                                    <>
                                      <div className="relative mt-2">
                                        <div className="assessment_qus_box px-4 py-3">
                                          <div>
                                            <label
                                              className="mb-3"
                                              for="exampleInputEmail1"
                                            >
                                              Question
                                            </label>
                                            <label
                                              className="mb-1"
                                              for="exampleInputEmail1"
                                            >
                                              {item?.question_text}
                                            </label>
                                          </div>
                                          <div className="ans-box w-9/12">
                                            {item?.options?.map(
                                              (innerItem, innerIndex) => (
                                                <>
                                                  <div className="flex pt-3 items-center">
                                                    <input
                                                      type={
                                                        item.question_type ==
                                                        "single_select"
                                                          ? "radio"
                                                          : "checkbox"
                                                      }
                                                      name={`option${index}`}
                                                      autocomplete="off"
                                                      checked={
                                                        item?.givenAnswer?.filter(
                                                          (itm) =>
                                                            itm == innerItem
                                                        )?.length > 0
                                                      }
                                                      onChange={(e) =>
                                                        handleRadio(
                                                          innerItem,
                                                          item.question_type
                                                        )
                                                      }
                                                    />
                                                    <div
                                                      onClick={() => {
                                                        handleRadio(
                                                          innerItem,
                                                          item.question_type
                                                        );
                                                      }}
                                                      className="border border-gray-300 px-2 ms-2 w-full py-2 rounded-md"
                                                    >
                                                      {innerItem}
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </div>
                                        </div>
                                        {/* <div className="add-qustion">
                                      {index == question.length - 1 ? (
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={addQuestion}
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : null}
                                      {index != 0 && (
                                        <button
                                          type="button"
                                          className="blue-bg"
                                          onClick={() => deleteQuestion(index)}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      )}
                                    </div> */}
                                      </div>
                                    </>
                                  );
                                })}
                          </div>

                          {displayAns ? (
                            <div className="col-span-12">
                              <div className="justify-end flex">
                                <button
                                  type="button"
                                  onClick={() => {
                                    window.location.reload();
                                  }}
                                  // disabled={currIndex == 0}
                                  className="btn_table green-bg py-2"
                                >
                                  Retest
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="col-span-12">
                              <div className="justify-end flex">
                                <button
                                  type="button"
                                  onClick={preQue}
                                  disabled={currIndex == 0}
                                  className="btn_table green-bg py-2"
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  //disabled={currIndex == question?.length - 1}
                                  onClick={() => {
                                    if (currIndex == question?.length - 1) {
                                      getResult();
                                    } else {
                                      nextQue();
                                    }
                                  }}
                                  className="btn_table blue-bg ms-1 py-2"
                                >
                                  {currIndex == question?.length - 1
                                    ? "Submit"
                                    : `Next`}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {isLoader && <Loader />}
    </>
  );
}

export default SessionAssesment;
