import React, { useState } from "react";
import UploadIconss from "../../../images/upload-Icon.png";
import Button from "../Button/Index";
import Modal from "../Modal/Index";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PdfUploadComponent = ({selectedFile, setSelectedFile,label=''}) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pdfPreview, setPdfPreview] = useState('');

  const handleFileChange = (e) => {
    const tempFile=selectedFile
    const file = e.target.files[0];
    const allowedTypes = ["application/pdf"];
    const maxFileSize = 10 * 1024 *1024; // 5MB in bytes
    console.log(file?.size , maxFileSize)
    console.log(file?.size > maxFileSize)

    if (!allowedTypes.includes(file.type)) {
      setSelectedFile(tempFile);
      setErrorMessage('Please select a valid PDF file.');
    } else if (file.size > maxFileSize) {
      setSelectedFile(tempFile);
      setErrorMessage('File size exceeds 10MB limit.');
    } else {
      setSelectedFile(file);
      previewPDF(file)
      setErrorMessage('');
    }
  }
  const previewPDF = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file)
reader.onload=()=>{
  setPdfPreview(reader.result)
}

    // reader.onloadend = () => {
    //   setPdfPreview(reader.result);
    // };

    // reader.readAsDataURL(file);
  };


const createURL=(value)=>{
  if(typeof value =='string'){
    return value
  }else{
    return URL.createObjectURL(value)
  }
console.log(typeof value)
}



  return (
    <>
 <label className="mb-2" for="exampleInputEmail1">
        {label}
      </label>
    <div className="image_up_boxx">
      <div className="up-icon-box relative rounded-[8px] border-dashed border-[1px] cursor-pointer border-[#c6c6d3] text-center">
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          className="absolute top-[0] bottom-[0] right-[0] left-[0] cursor-pointer z-10 opacity-0 p-1 rounded-md w-full"
        />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <img
          src={UploadIconss}
          width={30}
          alt=""
          className="m-auto pt-2 opacity-[0.3]"
        />
        <p className="mb-3">Upload Your File</p>
        
      </div>
    </div>
        {selectedFile && <>

          <Button type='button' onClick={()=>setIsOpen(true)} text={'Preview'} />
          <Modal isOpen={isOpen} onClose={()=>setIsOpen(false)}>

          <iframe
            title="PDF Preview"
            src={createURL(selectedFile)}
            // src='https://assets.badabusiness.com/topics/30918_1701684270036.pdf'
            width="600"
            height="400"
          ></iframe>
          </Modal>

        </>}
   
    </>
  );
};

export default PdfUploadComponent;



// import React, { useState } from 'react';
// import Modal from '../Modal/Index';
// import Button from '../Button/Index';

// const PdfUploadComponent = () => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isOpen, setIsOpen] = useState(false);

//   const [errorMessage, setErrorMessage] = useState('');

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     const allowedTypes = ['application/pdf'];

//     if (file && allowedTypes.includes(file.type)) {
//       setSelectedFile(file);
//       setErrorMessage('');
//     } else {
//       setSelectedFile(null);
//       setErrorMessage('Please select a valid PDF file.');
//     }
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         accept=".pdf"
//         onChange={handleFileChange}
//       />
//       {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//       {selectedFile && (
//         <div>
//           <p>Selected file: {selectedFile.name}</p>
//           <Button type='button' onClick={()=>setIsOpen(true)} text={'Preview'} />
//           <Modal isOpen={isOpen} onClose={()=>setIsOpen(false)}>

//           <iframe
//             title="PDF Preview"
//             // src={URL.createObjectURL(selectedFile)}
//             src='https://assets.badabusiness.com/topics/30918_1701684270036.pdf'
//             width="600"
//             height="400"
//           ></iframe>
//           </Modal>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PdfUploadComponent;
