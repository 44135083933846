import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";


export const updateForm = async (data = {},id) => {
          let result = { success: false };
          try {
            let res = await Axios.put(`/admin-api/v1/forms/${id}`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        export const updateCampaign = async (data = {},id) => {
          let result = { success: false };
          try {
            let res = await Axios.put(`/admin-api/v1/forms/campaigns/${id}`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        export const saveForm = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/add`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };


        export const saveFramework = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/frameworks/save`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        export const saveCampaign = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/campaigns/add`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };

        export const formList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
        export const professionList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/professions`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
        export const campaignList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/campaigns`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
       
        export const bundleList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/courses/course-bundles`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
        
        export const formDetails = async (id) => {
          let result = { success: false };
          try {
            let res = await Axios.get(`/admin-api/v1/forms/${id}`,);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        
        export const frameworkDetails = async (data={}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/frameworks/details`,data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        export const campaignDetails = async (id) => {
          let result = { success: false };
          try {
            let res = await Axios.get(`/admin-api/v1/forms/campaigns/${id}`,);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };


export const publishFormById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/topics/publish/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
export const formDeleteById = async (id) => {
          let result = { success: false };
          try {
            let res = await Axios.delete(`/admin-api/v1/forms/${id}`);
            result.success = res.status;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
          }
          return result;
        };
        
        export const campaignDeleteById = async (id) => {
          let result = { success: false };
          try {
            let res = await Axios.delete(`/admin-api/v1/forms/campaigns/${id}`);
            result.success = res.status;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
          }
          return result;
        };

        export const campaignMapping = async (data = {}) => {
          let result = { success: false };
          try {
            let response = await Axios.post(
              `/admin-api/v1/forms/campaigns/user-mapping`,
              data
            );
        
            return response.data
          } catch (error) {
            result.message = error?.response?.data?.message;
            result.status = error?.response?.status;
          }
          return result;
        };
        
        export const activateMobile = async (data = {}) => {
          let result = { success: false };
          try {
            let response = await Axios.post(
              `/admin-api/v1/courses/course-bundles/seven-star-bundle-unlock`,
              data
            );
        
            return response.data
          } catch (error) {
            result.message = error?.response?.data?.message;
            result.status = error?.response?.status;
          }
          return result;
        };
        export const downloadFeedbackFromReportFile = async (data = {}) => {
          let result = { success: false };
          try {
            let response = await Axios.post(
              `/admin-api/v1/forms/feedback-campaign-report`,
              data
            );
        
            if (response?.data?.success !== false) {
              const blob = new Blob([response.data]);
        
              // Create a URL for the blob
              const url = window.URL.createObjectURL(blob);
        
              // Create a link element to trigger the download
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `bbdFeedbackReport.csv`); // Specify the desired filename
        
              // Simulate a click on the link to trigger the download
              link.click();
        
              // Clean up the URL and the link
              window.URL.revokeObjectURL(url);
              result.success = true;
              result.message = response;
              return response.data;
            } else {
              return response.data;
            }
          } catch (error) {
            result.message = error?.response?.data?.message;
            result.status = error?.response?.status;
          }
          return result;
        };
        
