import React, { useEffect, useState } from 'react'
import Logo from "../../images/placeholder.jpg";
import { useToast } from '../../helper/custom hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { getLeaderBoardData } from '../../http/ServiceApi';

function BBimpactLeaderboard() {
    const [baseValue, setBaseValue] = useState("REVENUE");
    const [showTab, setShowTab] = useState("IBC");
    const { showToast } = useToast();
    const [data,setData]=useState([])
    const [selfData,setSelfData]=useState({})
    const [isView,setIsView]=useState(false)
    const navigate = useNavigate();
    useEffect(()=>{
        getLeaderBoard()

    },[])

    const urlValue=()=>{
        console.log(window.location?.href)
        const urlString=window.location?.href
        const urlParams = new URLSearchParams(urlString.split('?')[1]);
const programId = urlParams.get('program_id');

if(urlString.match(/leaderboardbbimpact\/([^?/]+)/)){

    const number = urlString.match(/leaderboardbbimpact\/([^?/]+)/)[1];
    
    return [number,programId];
}
return null



    }
    const getLeaderBoard=async()=>{
       
       const value=urlValue()
    //  conso
       if(value){
        let res
if(value[1]==null){
     res = await getLeaderBoardData(value[0])

}else{
     res = await getLeaderBoardData(value[0],value[1])

}
        //    let res = await getLeaderBoardData(value[0],value[1])
   
           if (res?.success) {
               console.log(res?.data?.score_board)
              setData(res?.data?.score_board)
              setSelfData(res?.data?.self_score_board)
              
             } else if (res.status === 401) {
   
               showToast(
                 res.message,
                 "danger",
                 3000, // Custom timeout duration (5 seconds)
                 () => {}
               );
               sessionStorage.clear();
               navigate("/");
             } else {
   
               showToast(
                 res.message,
                 "danger",
                 3000, // Custom timeout duration (5 seconds)
                 () => {}
               );
             }
       }


    }
   
    return (
        <> {
        (  Object.keys(selfData).length == 0  && data.length ==0  ) ?  <div className='text-center flex items-center justify-center h-[35rem] p-[1rem]'>
          Leaderboard is coming soon.
            </div> 
            :
           <div className='text-center bg-[transparent]'> 
              <div className='bg-[#4549BA] pt-[50px] h-[100px]'>
               <div className='bg-[#252762] rounded-[20px] px-[20px] pb-[20px]'>
                    <div className=''>
                        <span className='justify-center overflow-hidden w-20 h-20 rounded-full mx-auto inline-flex items-center mt-[-40px] border-[3px] border-[solid] border-[#ffffff8c]'>
                        <img className="w-full " src={selfData?.user_image || 'https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'} onError={(e)=>e.target.src='https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'} alt="Rounded avatar" />
                       </span>
                    </div>
                    <div className='text-white text-[18px] font-[500] mt-4'>YOUR PERFORMANCE</div>
                    <div className='bg-white  rounded-[30px] text-[14px] font-[500] py-2 mt-3'>
                        <span className='pr-[10px]'>Your Rank : {selfData?.rank}</span> | <span className='pl-[10px]'>Your Score : {selfData?.score}</span>
                    </div>
               </div>
               <div className='text-[#000] text-[18px] font-[500] mt-4 pb-[140px]'>Learning Score Board</div>
               </div>
               <div className='bg-white w-[90%] mx-auto mt-[-130px] py-[20px] rounded-t-[20px] overflow-y-auto max-h-[49vh] mt-[10rem] scrollbar-hide'>
                   <table className='w-full'>
                    <thead>
                        <tr className='bg-[#F1F4FF] rounded-[30px] '>
                            <th className='py-2 pl-3 text-[13px] text-left rounded-s-[30px]'>Rank</th>
                            <th className='py-2 px-3 text-[13px] text-left' colSpan={2}>User Profile</th>
                            <th className='py-2 px-3 text-[13px] text-[center!important] rounded-e-[30px]'>Score</th>
                        </tr>
                    </thead>
                    <tbody>

                    {/* {
                        !isView && data?.length!=0 && data.slice(0,5).map((item,index)=>     <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 pl-3 text-[13px] text-left'>{item?.rank}</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={item?.user_image || 'https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'} alt="Rounded avatar" onError={(e)=>e.target.src='https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'}/>
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>{item?.user_name}</td>
                            <td className='py-1 px-3 text-[13px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>{item?.score} 
                              
                                </span>
                            </td>
                        </tr>)
                    } */}
                    {
                        data.map((item ,index)=>{  
                           

                                                   return      <tr className={`border-b-[1px] border-[#BFCDFF] ${!isView && index>4 && 'hidden'}`}>
                            <td className='py-1 pl-3 text-[13px] text-left'>{item?.rank}s</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={item?.user_image || 'https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'} alt="Rounded avatar" onError={(e)=>e.target.src='https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'}/>
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>{item?.user_name}</td>
                            <td className='py-1 px-3 text-[13px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>{item?.score} 
                                
                                </span>
                            </td>
                        </tr>
                        
                        })
                    }

                    {/* {
                        isView && data?.length!=0 && data.slice(0,10).map((item,index)=>     <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>{item?.rank}</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={item?.user_image || 'https://imagesstatic.badabusiness.com/bbdprint_users/avatar/2023/07/placeholder.jpg'} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>{item?.user_name}</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>{item?.score} 
                                
                                </span>
                            </td>
                        </tr>)
                    } */}

                        {/* <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>1.</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={Logo} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>Parshav Jain</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>100 
                                <svg className="ml-1 w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                </svg>
                                </span>
                            </td>
                        </tr>
                        <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>2.</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={Logo} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>Parshav Jain</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>100 
                                <svg className="ml-1 w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                </svg>
                                </span>
                            </td>
                        </tr>
                        <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>3.</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={Logo} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>Parshav Jain</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>100 
                                <svg className="ml-1 w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                </svg>
                                </span>
                            </td>
                        </tr>
                        <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>4.</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={Logo} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>Parshav Jain</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>100 
                                <svg className="ml-1 w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                </svg>
                                </span>
                            </td>
                        </tr>
                        <tr className='border-b-[1px] border-[#BFCDFF]'>
                            <td className='py-1 px-3 text-[14px] text-left'>5.</td>
                            <td className='py-1 pl-3 text-left'>
                                <span className='justify-center overflow-hidden w-10 h-10 rounded-full inline-flex items-center'>
                                    <img className="w-full " src={Logo} alt="Rounded avatar" />
                                </span>
                            </td>
                            <td className='py-1 pr-3 text-[12px] text-left'>Parshav Jain</td>
                            <td className='py-1 px-3 text-[14px] text-right'>
                                <span className='inline-flex justify-end bg-[#4549BA] text-white rounded-[30px] px-[10px] py-[4px] text-[14px]'>100 
                                <svg className="ml-1 w-4 h-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                </svg>
                                </span>
                            </td>
                        </tr> */}
                    </tbody>
                   </table>
                   <button className='mb-[1rem] mt-4 text-[15px] text-[#3BA33B]' onClick={()=>setIsView(!isView)}>View {isView?'Less':'More'} Profile</button>
               </div>
           </div>
            }
            
        </>
    )
}

export default BBimpactLeaderboard