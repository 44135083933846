import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";

const token = getToken();
//Batch APIs

export const addBatches = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/batches/add`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const updateBatches = async (data = {}, id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/batches/${id}`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const batchDetails = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/batches/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const deleteBatch = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/batches/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const batchesList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/batches`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const getBBReport = async (id, phone, data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.get(
      `/admin-api/v1/sessions/user/overall-report?batch_id=${id}&phone_no=${phone}&type=view_report`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const downloadReportFile = async (id) => {
  let result = { success: false };
  try {
    let response = await Axios.get(
      `/admin-api/v1/sessions/batch-wise-report/${id}`
    );

    if (response?.data?.success !== false) {
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `bbdAssessment${id}.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link
      window.URL.revokeObjectURL(url);
      result.success = true;
      result.message = response;
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const downloadBBReport = async (id, phone, data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.get(
      `/admin-api/v1/sessions/user/overall-report?batch_id=${id}&phone_no=${phone}&type=download_report`,
      data
    );
    // Create a blob from the response data
    const blob = new Blob([response.data]);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `bbdReport${id}.csv`); // Specify the desired filename

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up the URL and the link
    window.URL.revokeObjectURL(url);
    //result.success = true;
    // return true;
    result.success = true;
    result.message = response;
    return response.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const getSampleFileForBatch = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.get(
      `/admin-api/v1/batches/download/sample-file`,
      data
    );

    if (response?.data?.success == false) {
    } else {
      // Create a blob from the response data
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `sampleBatch.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link

      window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);

      result.success = true;
      result.message = response;

      return result;
    }
    //result.success = true;
    // return true;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const uploadCSVFileForBatch = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.post(
      `/admin-api/v1/batches/csv/assign-batch`,
      data
    );

    if (response?.data?.success == false) {
      return response.data;
    } else {
      // Create a blob from the response data
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ResultBatch.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link

      window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);

      result.success = true;
      result.message = response;

      return result;
    }
    //result.success = true;
    // return true;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
