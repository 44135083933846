import moment from "moment";
import React, { useEffect } from "react";

const CircleBar = (props) => {
  const percentage = Math.round((props?.score * 100) / props?.totalAns);
 
  return (
    <>
      <div className="mb-5 flex justify-center">
        <div className="mt-8">
          <div
            class="flex items-center w-[22rem] pl-5 pr-5 bg-[#f2f2f2] shadow-xl rounded-[10px] h-[60px]">
            <div role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100" style={{'--value': percentage}}></div>
            <div className="">
              <p class="ml-4 font-medium text-gray-500 text-[10px]">
                {moment().format("DD MMM YYYY")}
              </p>
              <p class="ml-4 font-medium text-gray-600 sm:text-[18px]">
                Result
              </p>
            </div>
            <span class="ml-auto text-[16px] font-medium text-blue-600 hidden sm:block mt-3">{`${props?.score}/${props?.totalAns}`}</span>
          </div>
          <div className="text-center mt-8">
            <p className="text-[14px] mb-0">
              <b className="text-[18px]">{percentage>70?`Congratulations!`:`Better luck next time`}</b>
              <br /> {percentage>70? `You Have Passed The Assessment` :  `You have not scored enough Please try again`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CircleBar;
