
import Axios, { setToken } from '../Axios';
import { getToken } from '../Utility';

const token = getToken();

//Program APIs

export const addProgram = async (data = {}) => {

  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/programmes/add`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const updateProgram = async (data = {},id) => {

  let result = { success: false }
  try {
    let res = await Axios.put(`/admin-api/v1/programmes/${id}`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const programDetails  = async (id) => {


  let result = { success: false }
  try {
    let res = await Axios.get(`/admin-api/v1/programmes/${id}`, {});
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const deleteProgram  = async (id) => {


  let result = { success: false }
  try {
    let res = await Axios.delete(`/admin-api/v1/programmes/${id}`, {});
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}

export const programList = async (data = {}) => {


  let result = { success: false }
  try {
    let res = await Axios.post(`/admin-api/v1/programmes`, data);
    result.success=true;
    result.message=res
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
}