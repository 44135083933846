import React, { useEffect, useState } from "react";
import Button from '../components/core/Button/Index';
import { Link, useNavigate } from 'react-router-dom';
import { clearStorage } from '../http/Utility';

function Header(props) {
  const [toggle, setToggle] = useState(false);
   const navigate = useNavigate();


  const toggleSidebar = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    if (toggle === true) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  }, [toggle]);
  const handleLogout=()=>{
    clearStorage();
    navigate('/')
  }

  return (
    <>
      {/* <!-- Page Header Start--> */}
      <div className="page-main-header">
        <div className="main-header-right grid grid-cols-3 gap-0 lg:gap-4 m-0">
          <div className="main-header-left">
            <div className="logo-wrapper">
              <Link to="/home">
                <img
                  className="img-fluid"
                  src="https://images.badabusiness.com/bb-website-prod/assets/digital_webinar/images/logo.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="toggle-sidebar" onClick={toggleSidebar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-align-center status_toggle middle"
                id="sidebar-toggle"
              >
                <line x1="18" y1="10" x2="6" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="18" y1="18" x2="6" y2="18"></line>
              </svg>
            </div>
          </div>
          <div className="left-menu-header col">
            <ul className="m-0">
              {/* <li>
                <form className="form-inline search-form">
                  <div className="search-bg">
                    <i className="fa fa-search"></i>
                    <input
                      className="form-control-plaintext"
                      onChange={(event) => {
                        if (!!props?.onSearchTextChange) {
                          props?.onSearchTextChange(event?.target?.value);
                        }
                      }}
                      placeholder="Search here....."
                    />
                  </div>
                </form>
              </li> */}
            </ul>
          </div>
          <div className="nav-right col ml-auto p-0">
            <ul className="nav-menus m-0">
              <li className="onhover-dropdown p-0">
                <Button icon={<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>}
                  text='Log out'
                  className='bg-red-600'
                  onClick={handleLogout}
                  />
                {/* <button className="btn btn-primary-light" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  Log out
                </button> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Page Header Ends--> */}
    </>
  );
}

export default Header;
