import DataNot from '../images/search-engine.png';

function NoDataListFound() {
    return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: 50,
      }}
    >
      <img src={DataNot} className='mx-auto img-fluid' width={80} alt="" />
      <h2 style={{ textAlign: "center" }}>No Data Found</h2>
    </div>
  )}

  export default NoDataListFound;