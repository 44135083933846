import ReactPaginate from "react-paginate";

function PaginationsList({ pageCount, currentPage, onPageChange }) {
  return (
    <div className="PaginationsLists mt-3">
      {pageCount > 0 && (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          onPageChange={(e) => onPageChange(e)}
          disabledClassNae={"disabled"}
          activeClassName={"active"}
          containerClassName="pagination"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousLinkClassName="page-link"
          forcePage={currentPage - 1}
        />
      )}
    </div>
  );
}

export default PaginationsList;
