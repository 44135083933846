import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";

const token = getToken();
//Batch APIs

export const batchDetails = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/batches/${id}`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const getAllBusinessData = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(
      `/admin-api/v1/business-coach/access-request`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};

export const updateBussinesCoach = async (data = {}, id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(
      `/admin-api/v1/business-coach/access-request/${id}`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const updateBussinesCoach2 = async (data = {}, id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(
      `/admin-api/v1/business-coach/user-quota/${id}`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const getSingleData = async (id, data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.get(
      `/admin-api/v1/business-coach/access-request/${id}`,
      data
    );
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const uploadCSVFile = async (data = {}) => {
  let result = { success: false };
  try {
    let response = await Axios.post(
      `/admin-api/v1/business-coach/access-request/import`,
      data
    );

    if (response?.data?.success == false) {
    } else {
      // Create a blob from the response data
      const blob = new Blob([response.data]);

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `bbdbusinesscoach.csv`); // Specify the desired filename

      // Simulate a click on the link to trigger the download
      link.click();

      // Clean up the URL and the link

      window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);

      result.success = true;
      result.message = response;

      return result;
    }
    //result.success = true;
    // return true;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
