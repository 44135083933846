import React, { Children } from "react";

const DropdownBox = ({ label, className, options = [],children,onError,name='',onChange='',value='',placeholder='',required=false,disabled=false }) => {
  return (
    // <div className="relative d-flex flex-col">
    //   {label && (
    //     <label className="rounded-md mb-2">
    //       {label}
    //     </label>
    //   )}
    //   <input
    //     type={type}
    //     placeholder={placeholder}
    //     value={value}
    //     onChange={onChange}
    //     className={`px-4 py-2 border border-gray-300 rounded-md ${className}`}
    //   />
    // </div>
    <div className="relative flex flex-col">
      {label && <label className="rounded-md mb-2">{label}</label>}
      <select
      required={required}
      placeholder={placeholder}
      disabled={disabled}
        name={name}
        value={!!value && value}
        onChange={onChange}
        className={`px-2 py-2 border border-gray-300 rounded-md w-full text-[14px] ${className}`}
      >
        {/* {options?.map((item) => (
          <option value={item?.key}>{item?.value}</option>
        ))} */}
        {children}
      </select>
      <p className="onerror">

       {onError}
      </p>
    </div>
  );
};

export default DropdownBox;
