import { Formik } from "formik";
import React from "react";
import InputBox from "../../../components/core/InputBox/Index";

export default function Flip(props) {
  const handleChange=(e)=>{
    let tempData=props.flipData
    if(e?.target?.name =='marks'){
    tempData[0][e?.target?.name]=parseInt(e?.target?.value)

    }else if(e?.target?.name){

      tempData[0][e?.target?.name]=e?.target?.value
    }
props.setFlipData([...tempData])
  }
  return (
   
         <>{
          
         }
                <div className="col-span-12 lg:col-span-4">
                  <InputBox
                  required={true}
                    type="text"
                    placeholder="Enter question"
                    id=""
                    label="Question *"
                    name="question"
                    value={props.flipData[0].question}
                    onChange={handleChange}
                    className='w-[10rem]'
                  />
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <InputBox
                  required={true}
                    type="text"
                    placeholder="Enter Answer"
                    id=""
                    label="Answer *"
                    name="answer"
                    value={props.flipData[0].answer}
                    onChange={handleChange}
                    className='w-[10rem]'
                  />
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <InputBox
                  required={true}
                    type="number"
                    id=""
                    label="Marks"
                    name="marks"
                    min={1}
                    value={props.flipData[0].marks}
                    onChange={handleChange}
                    className='w-[10rem]'
                  />
                </div>
                </>
              
  );
}
