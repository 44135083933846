import React from "react";
import { useState } from "react";
import DropdownBox from "../../../components/core/DropdownBox/Index";

export default function FLash({question,setQuestion,showError}) {
  // const [question, setQuestion] = useState([
  //   {
  //     question_text: "",
  //     marks: "",
  //     question_type: "",
  //     answer: [],
  //     options: [],
  //     count: 0,
  //     tempOption: [
  //       {
  //         option: "",
  //         isChecked: false,
  //       },
  //       {
  //         option: "",
  //         isChecked: false,
  //       },
  //       {
  //         option: "",
  //         isChecked: false,
  //       },
  //       {
  //         option: "",
  //         isChecked: false,
  //       },
  //     ],
  //   },
  // ]);
  const handleRadio = (e, index, innerIndex) => {
    let tempdata = question;

    tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;
    if(tempdata[index].Questype=='single'){
      tempdata[index].tempOption.map((item,index)=>item.isChecked=false)
    tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;

    }
    
    setQuestion([...tempdata]);
  };
  const deleteQuestion = (index) => {
    let tempQuestion = question;
    tempQuestion.splice(index, 1);
    setQuestion([...tempQuestion]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;
    
    tempdata[mainIndex].tempOption[innerIndex].option = e.target.value;
    setQuestion([...tempdata]);
    
  };
  const handleQuestionTitle = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex][e.target.name] = e.target.value;
    setQuestion([...tempdata]);
  };
  const addQuestion = () => {
    setQuestion([
      ...question,

      {
        question_text: "",
        Questype: "single",
        marks: 0,
        options: [],
        count: 0,
        tempOption: [
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
        ],
      },
    ]);
  };

  const handleChangeQuestionType = (e, index) => {
    let tempdata = question;

    tempdata[index].Questype = e.target.value;

    tempdata[index].options.map((item)=>item.isChecked=false)
    tempdata[index].tempOption.map((item)=>item.isChecked=false)

    setQuestion([...tempdata]);

  };
  return (
   <div>
    {console.log(question)}
          {question?.map((item, index) => {
            return (
              <>
                <div className="relative mt-2 w-[40rem]">
                <p className="onError my-2">{showError }</p>
                  <div className="assessment_qus_box px-4 py-3">
                    <div className="question">
                      <label className="mb-3" for="exampleInputEmail1">
                        Question *
                      </label>
                      <input
                        type="text"
                        required={true}
                        autocomplete="off"
                        className="border border-gray-300 px-2 rounded-md  w-full py-2"
                        placeholder="Enter Question title here"
                        name="question_text"
                        value={item.question_text}
                        onChange={(e) => handleQuestionTitle(e, index)}
                      />
                      <div className="flex items-end gap-3 justify-content-evenly">
                      <div>

                      <label className="mb-3" for="exampleInputEmail1">
                        Marks
                      </label>
                        <input
                        required={true}
                          autocomplete="off"
                          min='1'
                          type="number"
                          className="border border-gray-300 px-2 rounded-md py-2"
                          placeholder="Marks"
                          name="marks"
                          value={item.marks}
                          onChange={(e) => handleQuestionTitle(e, index)}
                        />
                      </div>
                        <DropdownBox
                          label="Question Type"
                          required={true}
                          className="w-[10rem]"
                          onChange={(e) => handleChangeQuestionType(e, index)}
                                value={item.Questype}
                          name="Question type"
                          //       onError={
                          //         errors.type &&
                          //         touched.type &&
                          //           errors.type
                          //       }
                          //       onBlur={handleBlur}
                        >
                          <option value="select" selected={item.Questype=='select'}>Select</option>
                          <option value="single" selected={item.Questype=='Single'}>Single</option>
                          <option value="mutiple" selected={item.Questype=='Mutiple'}>Multiple</option>
                        </DropdownBox>
                      </div>
                    </div>
                    <div className="ans-box w-9/12">
                      {item?.tempOption?.map((innerItem, innerIndex) => (
                        <>
                          <div className="flex pt-3 items-center">
                            <input
                            
                              disabled={innerItem.option == ""}
                              type={
                                item.Questype == "single"
                                  ? "radio"
                                  : "checkbox"
                              }
                              name={`option${index}`}
                              checked={innerItem.isChecked}
                              onChange={(e) =>
                                handleRadio(e, index, innerIndex)
                              }
                            />
                            <input
                          
                              autocomplete="off"
                              type="text"
                              value={innerItem.option}
                              className="border border-gray-300 px-2 ms-2 w-full py-2 rounded-md"
                              placeholder="Option here"
                              name={`option`}
                              onChange={(e) =>
                                handleQuestion(e, index, innerIndex)
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="add-qustion">
                    {index == question.length - 1 ? (
                      <button type="button" className="green-bg me-2" onClick={addQuestion}>
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          
                        ></i>
                      </button>
                    ) : null}
                    {index > 0 ? (
                      <button type="button" className="blue-bg" onClick={() => deleteQuestion(index)}>
                        <i
                          className="fa fa-minus"
                          aria-hidden="true"
                          
                        ></i>
                      </button>
                    ) : null}
                  </div>
                </div>
              </>
            );
          })}
       </div>
  );
}
