import moment from 'moment';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import DropdownBox from '../../components/core/DropdownBox/Index';
import { AskQuestion } from '../../http/Ask';
import { useToast } from '../../helper/custom hooks/useToast';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../components/core/Table/Index';

const ListComponent = () => {
  const [idArray, setIdArray] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [latestId, setLatestId] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { showToast } = useToast();
  const latestIdRef = useRef('');
  const navigate = useNavigate();
  const timeoutIdRef = useRef(null);
  const today=moment().format('YYYY-MM-DD');
  const tomorrow=moment().add(1, 'days').format('YYYY-MM-DD');
  const yesterday=moment().subtract(1, 'days').format('YYYY-MM-DD');
  const columns = useMemo(
    () => [
      // { label: "#", accessor: "id" },
      {
        label: "Name",
        accessor: "username",
        sortable: false,
        sortbyOrder: "ASC",
        isHTML: false,
      },
      {
        label: "Question",
        accessor: "question",
        sortable: false,
        sortbyOrder: "ASC",
        isHTML: false,
      }
      
    ],
    []
  );

  const fetchData = async () => {
          try {
            // Step 3: Make API call to get array of IDs
let payload={  "filterDate": selectedValue}
            const res = await AskQuestion(payload);
            if (res.success) {
              setIdArray([...res?.data]);
                      if(selectedValue==moment().format('YYYY-MM-DD')){

                        setLatestId(res?.data[0].id)
                      }
                    latestIdRef.current = res?.data[0].id;

            }else {
                    if (res?.status === 401) {
                      showToast(
                        res?.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {}
                      );
                      sessionStorage.clear();
                      navigate("/");
                    } else {
                      showToast(
                        res?.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {}
                      );
                    }
                  }
      
            // Step 4: Use the first ID from the array as a payload

      
 
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        const fetchDataWithPayload = async () => {
          try {
            // Step 3: Make API call to get array of IDs
        //     const response = await fetch('https://conappdevapi.badabusiness.co.in//admin-api/v1/live-session-campaign/ask-questions',{
        //       method: "POST",
        //       headers:{
        //                 'content-type':'application/json'
        //       },
        //      body:JSON.stringify({  "filterDate": selectedValue,
        //      "last_ask_question_id": latestIdRef.current
      
        //  })
        //     });
        let payload={  "filterDate": selectedValue,
              "last_ask_question_id": latestIdRef.current}
        const res=await AskQuestion(payload)
            // const data = await response.json();
         
            latestIdRef.current = res?.data[0].id;
            setIdArray(preState=>[...res?.data,...preState]);
      
            // Step 4: Use the first ID from the array as a payload
         
      
   
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        const scheduleFetchData = () => {
          
          // Schedule the fetchData function to run every 30 seconds
          timeoutIdRef.current =  setTimeout(async () => {
                    console.log({isFetching})
                    if (isFetching) {
                      await fetchDataWithPayload();
              scheduleFetchData(); // Call itself to run again after 30 seconds
            }
          }, 30000);
        
        }; 

  useEffect(()=>{
          console.log(latestIdRef.current)
          console.log(latestId)

          if(selectedValue!==''){
          console.log('inside',latestId)

                    fetchData()
                  
          }

          if(latestIdRef.current !== '' &&selectedValue==moment().format('YYYY-MM-DD')){
                    console.log({latestId})
                   
                     setIsFetching(true)
          }else{
                    setLatestId('')
                    latestIdRef.current='';
                    setIdArray([])
                 setIsFetching(false)
         
          clearTimeout(timeoutIdRef.current);
          }

  },[latestId,selectedValue])

  useEffect(() => {
          // Make the initial API call
        console.log(isFetching)
      
          // Start the timer for subsequent API calls
          
          scheduleFetchData();
      
          // Cleanup on component unmount
          return () =>{   clearTimeout(timeoutIdRef.current);clearTimeout(timeoutId);}
        }, [isFetching]);
        useEffect(()=>{
          return () =>{  clearTimeout(timeoutIdRef.current); clearTimeout(timeoutId);}
        },[])

  return (
          <div className="container mx-auto my-8">
          <div className="sticky top-0 bg-white p-4 z-10">
            <h1 className="text-3xl font-bold mb-4">Ask question</h1>
            <DropdownBox value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} className="mb-4">
              <option value={''}>Select</option>
              <option value={yesterday}>Yesterday</option>
              <option value={today}>Today</option>
              <option value={tomorrow}>Tomorrow</option>
            </DropdownBox>
          </div>
          <div className="overflow-y-auto max-h-[40rem]">
         {/* {
            idArray.length!=0 &&
          <TableComponent
                            data={idArray}
                            columns={columns}
                            currentPage={1}
                          />
          }*/}
            <ul className="list-disc pl-7 ml-2">
              {idArray.map(item => (
                <li key={item.id} className="mb-2">{item?.username||'N/A'} - {item.question}</li>
              ))}
            </ul> 
          </div>
        </div>
  );
};


export default ListComponent;
