import React, { useEffect, useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import Header from "../../../Layout/Header";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import {
  batchesList,
  downloadBBReport,
  getBBReport,
  getSampleFileForBatch,
  uploadCSVFileForBatch,
} from "../../../http/batch";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/core/Button/Index";
import moment from "moment";
import { uploadCSVFile } from "../../../http/businessCoach";

export default function Reallocate() {
  const [phone, setPhone] = useState("");

  const [batchData, setBatchData] = useState([]);
  const { showToast } = useToast();
  const [apiLoader, setApiLoader] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [isImporting, setIsImproting] = useState(true);
  const [file, setFile] = useState(null);
  const location = useLocation();
  const batchId = location?.state?.batchId || "";

  const navigate = useNavigate();
  useEffect(() => {
    console.log(batchId);
    if (batchId == "") {
      navigate("/bb-batches-list");
    }
  }, []);

  //   const handleSubmit = async () => {
  //     if (phone != "" && bacthId != "" && phone.length == 10) {
  //       setApiLoader(true);
  //       const payload = {
  //         type: "dropdown",
  //       };
  //       const res = await getBBReport(bacthId, phone);
  //       setApiLoader(false);

  //       if (res.success) {
  //         setTableData(res.data);
  //       } else if (res.status === 401) {
  //         setTableData({ ...tableData, session_detalis: [] });
  //         showToast(
  //           res.message,
  //           "danger",
  //           3000, // Custom timeout duration (5 seconds)
  //           () => {}
  //         );
  //         sessionStorage.clear();
  //         navigate("/");
  //       } else {
  //         setTableData({ ...tableData, session_detalis: [] });
  //         showToast(
  //           res.message,
  //           "danger",
  //           3000, // Custom timeout duration (5 seconds)
  //           () => {}
  //         );
  //       }
  //     } else {
  //       showToast(
  //         "please add Proper number or select batch",
  //         "danger",
  //         3000, // Custom timeout duration (5 seconds)
  //         () => {}
  //       );
  //     }
  //   };
  const handleFileButtonClick = () => {
    document.getElementById("fileupload").click();
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsImproting(false);
      setFile(file);
      //   handleCSVFile(file);
    }
  };
  async function handleSubmit() {
    const maxSize = 9 * 1024 * 1024; // 9MB in bytes

    // Check if the file is a CSV file
    if (!file.name.endsWith(".csv")) {
      showToast(
        "Please select a CSV file.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("Please select a CSV file.");
      document.getElementById("fileupload").value = "";

      return;
    }

    // Check file size
    if (file.size > maxSize) {
      showToast(
        "File size exceeds the limit of 9MB.",
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      // alert("File size exceeds the limit of 9MB.");
      document.getElementById("fileupload").value = "";

      return;
    }
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("batch_id", batchId);

    const res = await uploadCSVFileForBatch(formData);

    document.getElementById("fileupload").value = "";
    if (!!res && res?.success != false) {
      setIsImproting(true);
      showToast(
        "File Downloaded Successfully",
        "success",
        3000 // Custom timeout duration (5 seconds)
      );
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      setIsImproting(true);

      showToast(
        res.message,
        "danger",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }

    if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    }
  }

  async function handleSampleFile() {
    const res = await getSampleFileForBatch();

    if (!!res && res?.success != false) {
      showToast(
        "File Downloaded Successfully",
        "success",
        3000 // Custom timeout duration (5 seconds)
      );
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "error",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }

    if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    }
  }

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            <div className="dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4">
                    <div className="card-headers pt-2 pb-2 px-0 flex justify-between items-center p-2">
                      <div className="flex items-center flex-wrap p-2">
                        <h3 className="card-titles mb-0 me-3">Reallocate</h3>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3 p-2">
                      <div className="col-span-12 flex flex-row-reverse justify-end">
                        <Button
                          text={"Sample file"}
                          // disable={isImporting}
                          onClick={handleSampleFile}
                          className={"mx-3 bg-green-500"}
                        />
                        <input
                          type="file"
                          id="fileupload"
                          //   style={{ display: "none" }}
                          accept=".csv"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="col-span-12 flex flex-row-reverse justify-end">
                        <Button
                          text={"Import"}
                          disable={isImporting}
                          onClick={handleSubmit}
                          className={"mx-3"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
