import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_SERVER_URL
export const zoomWebApiUrl = process.env.REACT_APP_ZOOM_WEB_SERVER_URL
export const zoomWebApiClientKey = process.env.REACT_APP_ZOOM_WEB_API_CLIENT_KEY


const Axios = axios.create({
    baseURL: apiBaseUrl
});

//set token to user request.
/**
 * @param token set token to axiox
 */
export const setToken = token => {
    if (token) {
         Axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
        //Axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;



    } else {
        delete Axios.defaults.headers.common['Authorization'];

    }
}

export default Axios;

