import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import ReactHtmlParser from "react-html-parser";
import { getDateAndTimeFormate, getDateFormate } from "../../../helper/utils";
import moment from "moment/moment";
import { getPermission } from "../../../http/Utility";

const calANswer = (value) => {
  let count = 0;
  value.map((item) => {
    if (item?.isCorrect) {
      count++;
    }
  });

  return count;
};

export default function TableComponent({
  data,
  columns,
  mappingTitle = "",
  currentPage = 0,
  perPageLimit = 10,
  bb_impact_program_id=0
}) {
  const getCurrentIndex = (index) => {
    return (currentPage - 1) * perPageLimit + index;
  };

  return (
    <table className="table min-w-full divide-y divide-gray-200">
      <thead className="border-b dark:border-neutral-500">
        <th
          key={-1}
          className="bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r"
        >
          S.No
        </th>
        {columns.map((item, index) => (
          <th
            key={index}
            className="bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r !text-left"
          >
            {item?.label}
          </th>
        ))}
      </thead>
      <tbody className="divide-y divide-gray-200">
        {data.map((item, index) => {
          return (
            <tr key={index} className="border-b dark:border-neutral-500">
              <td
                key={-1}
                className="px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500"
              >
                {/* {index + 1} {currentPage} */}
                {getCurrentIndex(index + 1)}
              </td>
              {columns.map((cell, cellIndex) => {
                if (cell.accessor == "id") {
                  return (
                    <td
                      key={cellIndex}
                      className="px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500"
                    >
                      {item[cell?.accessor]}
                    </td>
                  );
                } else if (
                  cell.accessor !== "used_for" &&
                  cell.accessor !== "action" &&
                  cell.accessor !== "id"
                ) {
                  return (
                    <td
                      key={cellIndex}
                      className={`px-3 py-2 bg-white capitalize ${cell?.page=='discussion-forum'?'w-[500]px':'w-[300px]'} border-r dark:border-neutral-500`}
                    >
                      {cell.label == "OPEN FOR ALL" && (
                        <>{item[cell?.accessor] ? "Enabled" : "Disabled"}</>
                      )}

                      {cell.label == "PUBLISHED" && (
                        <>{item[cell?.accessor] ? "Enabled" : "Disabled"}</>
                      )}
                      {cell?.isImage && (
                        <img src={cell?.accessor} width="50" height="50" />
                      )}
                      {/* {cell.label=='END DATE' && <>{moment(new Date(item[cell?.accessor]).toISOString().split("T")[0]).format('DD-MM-YYYY')}</>} */}
                      {/* {cell.label=='START DATE' && <>{moment(new Date(item[cell?.accessor]).toISOString().split("T")[0]).format('DD-MM-YYYY')}</>} */}

                      {cell.isHTML ? (
                        <div className="max-w-[425px] w-[100%] max-h-[20px] overflow-hidden truncate ">
                          {/* {'item[cell.accessor] '|| "N/A"} */}
                          {ReactHtmlParser(item[cell?.accessor])}
                        </div>
                      ) : cell.isQuestion ? (
                        cell.label == "QUESTION'S TITLE" ? (
                          <div className="max-w-[425px] w-[100%] overflow-hidden truncate ">
                            {item?.type == "flash" ? (
                              <>
                                {item?.activitySet[0]?.question_text || "N/A"}
                              </>
                            ) : (
                              <> {item?.activitySet[0]?.question || "N/A"}</>
                            )}
                          </div>
                        ) : cell.label ==
                          "TOTAL QUESTION'S OPTION NO. / TOTAL QUESTION NO." ? (
                          <>
                            {item?.type == "flash" ? (
                              <>
                                {item?.activitySet[0]?.options?.length || "N/A"}
                              </>
                            ) : (
                              <> {item?.activitySet?.length || "N/A"}</>
                            )}
                          </>
                        ) : cell.label == "TOTAL QUESTION'S ANSWER NO." ? (
                          <>
                            {item?.type == "flash" ? (
                              <>
                                {calANswer(item?.activitySet[0]?.options) ||
                                  "N/A"}
                              </>
                            ) : (
                              <> {item?.activitySet.length || "N/A"}</>
                            )}
                          </>
                        ) : (
                          cell.label == "MARKS" && (
                            <>
                              {item?.type == "flash" ? (
                                <>{item?.activitySet[0]?.marks}</>
                              ) : (
                                <> {item?.activitySet[0]?.marks}</>
                              )}
                            </>
                          )
                        )
                      ) : !!cell.dateFormate ? (
                        <div className="max-w-[425px] w-[100%] max-h-[20px] overflow-hidden truncate ">
                          {/* {'item[cell.accessor] '|| "N/A"} */}
                          {`${getDateFormate(item[cell?.accessor])}`}
                        </div>
                      ) : !!cell.dateTimeFormate ? (
                        <div className="max-w-[425px] w-[100%] max-h-[20px] overflow-hidden truncate ">
                          {/* {'item[cell.accessor] '|| "N/A"} */}
                          {`${getDateAndTimeFormate(item[cell?.accessor])}`}
                        </div>
                      ) : (
                        <div className={`${cell?.page=='discussion-forum'?'w-[500]px':'w-[300px]'} w-[100%] overflow-hidden truncate `}>
                          {item[cell.accessor] == 0
                            ? item[cell?.accessor]
                            : item[cell?.accessor] || "N/A"}
                        </div>
                      )}
                    </td>
                  );
                } else {
                  return (
                    <td className="px-2 py-1 bg-white">
                      {renderAction(
                        item[cell?.accessor],
                        index,
                        item.id,
                        item,
                        mappingTitle,
                        bb_impact_program_id
                      )}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
const renderAction = (data, index, id, item, mappingTitle,bb_impact_program_id) => {
  const checkDateGap = (date) => {
    // console.log(new Date(date) - new Date());

    let diffrence = new Date(date) - new Date();

    if (diffrence > 0) {
      return true;
    } else {
      return false;
    }
  };
  const permission = getPermission();
  // console.log('const permission = getPermission() = ',permission)
  return (
    <div className="action_div_Text justify-end flex mw-[15.5rem]">
    <div className="flex flex-col gap-[5px]">

  
    {data.hasOwnProperty("status") && (
        <button
          disabled={item.status !== "pending"}
          onClick={() => data.status(id, index)}
          className="blue-text"
          title="Publish"
        >
          {/* <i
            className={
              item.status !== "pending" ? "fa fa-unlock" : "fa fa-lock"
            }
          ></i> */}
          <i class="fa fa-caret-square-o-up" aria-hidden="true"></i>
        </button>
      )}
      
      {data.hasOwnProperty("statusLock") && (
        <button
          
          onClick={() => data.statusLock(item)}
          className="blue-text"
          title={item.status == "locked" ?'Unlock':'Lock'}
        >
          <i
            className={
              item.status == "locked" ? "fa fa-unlock" : "fa fa-lock"
            }
          ></i>
          {/* <i class="fa fa-caret-square-o-up" aria-hidden="true"></i> */}
        </button>
      )}
      
      {data.hasOwnProperty("exportAcvtPoll") && (
        <button
          disabled={!item?.showReport}
          onClick={() => {
            data.exportAcvtPoll(id, index, item);
          }}
          className="blue-text"
          title={"Activity Poll"}
        >
          Acvt Poll<i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}
      {data.hasOwnProperty("handleBanner") && (
        <button
       
          onClick={() => {
            data.handleBanner(id, index, item);
          }}
          className="blue-text"
          title={"Banner"}
        >
          Banner Managment<i style={{ marginLeft: 5 }} class="fa fa-list"></i>
        </button>
      )}
      {data.hasOwnProperty("handleSesion") && (
        <button
       
          onClick={() => {
            data.handleSesion(id, index, item);
          }}
          className="blue-text"
          title={"Banner"}
        >
          Session Managment<i style={{ marginLeft: 5 }} class="fa fa-list"></i>
        </button>
      )}
      {data.hasOwnProperty("syncPoll") && item.pollSynced == false && (
        <button
          disabled={
            !item?.showReport ||
            item?.pollSyncError == "PENDING" ||
            item?.pollSyncError == "FAILED"
          }
          onClick={() => {
            data.syncPoll(id, index, item);
          }}
          className="blue-text"
          title={"Sync Poll"}
        >
          Poll<i style={{ marginLeft: 5 }} class="fa fa-refresh"></i>
        </button>
      )}
      {data.hasOwnProperty("exportPoll") && item.pollSynced == true && (
        <button
          onClick={() => {
            data.exportPoll(id, index, item);
          }}
          className="blue-text"
          title={"Sync Poll"}
        >
          Poll<i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}

      {data.hasOwnProperty("exportAssessment") && (
        <button
          disabled={!item?.showReport}
          onClick={() => {
            data.exportAssessment(id, index, item);
          }}
          className="blue-text"
          title={"Download Assessment"}
        >
          Asmt<i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}
      {data.hasOwnProperty("exportReport") && (
        <button
          onClick={() => {
            data.exportReport(id, index, item);
          }}
          className="blue-text"
          title={"Download Report"}
        >
          Download report
          <i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}

      {data.hasOwnProperty("exportAttendance") && (
        <button
          disabled={!item?.showReport}
          onClick={() => {
            data.exportAttendance(id, index, item);
          }}
          className="blue-text"
          title={"Download Attendance"}
        >
          Att<i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}
      {data.hasOwnProperty("exportFeedbackReport") && (
        <button
          disabled={!item?.showReport}
          onClick={() => {
            data.exportFeedbackReport(id, index, item);
          }}
          className="blue-text"
          title={"Download Feedback campaign report"}
        >
          Feedback
          <i style={{ marginLeft: 5 }} class="fa fa-download"></i>
        </button>
      )}
     
      
      {data.hasOwnProperty("reAllocate") && (
        <>
          {/* {console.log(item)} */}
          {checkDateGap(item?.start_date) && (
            <button
              onClick={() => {
                data.reAllocate(id, index, item);
              }}
              className="blue-text"
              title={"Download Attendance"}
            >
              ReAllocate
              {/* <i style={{ marginLeft: 5 }} class="fa fa-download"></i> */}
            </button>
          )}
        </>
      )}
      </div>
      <div>
      {data.hasOwnProperty("handleTopicManagment") && bb_impact_program_id==item?.program_id&& (
        <button
          onClick={() => data.handleTopicManagment(id, index, item)}
          className="blue-text"
          title={mappingTitle || "Topic managment"}
        >
          <i className="fa fa-list"></i>
        </button>
      )}
      {data.hasOwnProperty("edit2") && (
        <button
          disabled={item.status == "pending" || item.status == "rejected"}
          onClick={() => data.edit2(item)}
          className="blue-text"
          title="edit"
        >
          {/* <i
            className={
              item.status !== "pending" ? "fa fa-unlock" : "fa fa-lock"
            }
          ></i> */}
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
      )}

     
      {data.hasOwnProperty("edit") && (
        <button
          onClick={() => data.edit(id ? id : item._id, index, item)}
          className="green-text"
          title="Edit"
        >
          <i className="fa fa-pencil"></i>
        </button>
      )}
      {data.hasOwnProperty("mapping") && (
        <button
          onClick={() => data.mapping(id, index, item)}
          className="blue-text"
          title={mappingTitle || "Mapping"}
        >
          <i className="fa fa-list"></i>
        </button>
      )}
      {data.hasOwnProperty("statusDisable") && (
        <button
          onClick={() => data.statusDisable(id, index, item)}
          className="blue-text"
          title={mappingTitle || "Disable"}
        >
        Disable 
          <i style={{ marginLeft: 5 }} className="fa fa-window-close-o"></i>
        </button>
      )}

      {data.hasOwnProperty("delete") && permission == "supperAdmin" && (
        <button
          href=""
          onClick={() => data.delete(id ? id : item._id, index)}
          className="red-text"
          title="Delete"
        >
          <i className="fa fa-trash"></i>
        </button>
      )}
      {data.hasOwnProperty("launch") && (
        <button
          href=""
          onClick={() => data.launch(id ? id : item._id, index)}
          className="red-text"
          title="Launch"
        >
          <i class="fa fa-rocket" aria-hidden="true"></i>
        </button>
      )}
      {data.hasOwnProperty("viewPoll") && item?.type == "poll" && (
        <button
          href=""
          onClick={() => data.viewPoll(id ? id : item._id, index)}
          className="red-text"
          title="View Poll"
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      )}
      
      </div>

    </div>
  );
};
