import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { Formik } from 'formik';
import InputBox from '../../components/core/InputBox/Index';
import DropdownBox from '../../components/core/DropdownBox/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../helper/custom hooks/useToast';
import Button from '../../components/core/Button/Index';
import { colleageDataApi, createCertificate } from '../../http/Certificate';
import Sidebar from '../../Layout/Sidebar';
import Breadcrumb from '../../Layout/Breadcrumb';
import Header from '../../Layout/Header';
const certificateSchema = Yup.object().shape({
        
 
  certificateCompletionLogo: Yup.string().required("Required").matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!'
),
          certificateSignature: Yup.string().required("Required").matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        ),
          colleageId: Yup.string().required("Required"),


        });

export default function Certificate(props) {
          const location = useLocation();
  const formType = props?.formType || location?.state?.formType;
  const certificateData = JSON?.parse(location?.state?.certificateData||'{}');



  const { showToast } = useToast();
  const navigate = useNavigate();
  const [isAPICall,setIsAPICall]=useState(false)
  const [colleageData,setColleageData]=useState([])




  const getCollegeData = async () => {
          const res = await colleageDataApi();
      
          if (res.success) {
                    console.log(res)
            setColleageData(res?.data);
          } else if (res.status === 401) {
            showToast(
              res.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
            sessionStorage.clear();
            navigate("/");
         
          } else {
            showToast(
              res.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
          }
        };

        useEffect(()=>{
          console.log(certificateData)
          if( formType=='edit' &&!(certificateData.hasOwnProperty('certificateSignature'))){
            navigate('/certificate')
          }


          getCollegeData()


        },[])
        const handleSubmit1= async (e,values) => {
    
          if (isAPICall) {
            return true;
          } else {
            console.log(values)
            const payload = {
              "collegeId": values.colleageId,
              "collegeName": values.colleageName,
              "certificateParticipationLogo": values.certificateParticipationLogo,
              "certificateCompletionLogo": values.certificateCompletionLogo,
              "certificateSignature": values.certificateSignature,
              
          }
          
            setIsAPICall(true)
            const res = await createCertificate(payload)
          console.log(res)
            if (res?.success) {
              showToast(
                res.message,
                "success",
                1000, // Custom timeout duration (5 seconds)
                () => {}
              );
     
              navigate("/certificate");
            } else if (res.status === 401) {
              setIsAPICall(false)
              showToast(
                res.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {}
              );
              sessionStorage.clear();
              navigate("/");
            } else {
              setIsAPICall(false)
              showToast(
                res.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {}
              );
            }
          }
        };

  return (
<>
<div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12 ">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pb-2 flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "update"
                            ? `Edit certificate logo`
                            : `Add certificate logo`}
                        </h3>
                      </div>
                    </div>
        
                      <Formik
                        validationSchema={certificateSchema}
                        initialValues={{
                         colleageName:certificateData?.collegeName ||'',
                          colleageId:certificateData?.collegeId ||"",

                          certificateSignature:certificateData?.certificateSignature ||'',
                          certificateCompletionLogo:certificateData?.certificateCompletionLogo ||'',

                        }}
                        enableReinitialize
                        onSubmit={(values, e) =>{console.log(values,e); handleSubmit1(e, values)}}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* and other goodies */
                        }) => (
                          <>
                          <form onSubmit={handleSubmit}>
                          <div className="card-body pt-0 form-author">
                                <div className="grid grid-cols-12 gap-4">
                                  
                                <div className="col-span-12 lg:col-span-12">
                                <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="College signature  *"
                                      placeholder="Enter Certificate signature  URL"
                                      name="certificateSignature"
                                      value={values.certificateSignature}
                                      onError={
                                        errors.certificateSignature &&
                                        touched.certificateSignature &&
                                        errors.certificateSignature
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {/* <div className="col-span-12 lg:col-span-12">
                                <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Certificate participation logo  *"
                                      placeholder="Enter certificate participation logo  URL"
                                      name="certificateParticipationLogo"
                                      value={values.certificateParticipationLogo}
                                      onError={
                                        errors.certificateParticipationLogo &&
                                        touched.certificateParticipationLogo &&
                                        errors.certificateParticipationLogo
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  <div className="col-span-12 lg:col-span-12">
                                  <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="College logo *"
                                      placeholder="Enter certificate completion logo URL"
                                      name="certificateCompletionLogo"
                                      value={values.certificateCompletionLogo}
                                      onError={
                                        errors.certificateCompletionLogo &&
                                        touched.certificateCompletionLogo &&
                                        errors.certificateCompletionLogo
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  
                                  <div className="col-span-12 lg:col-span-4">
                                    <DropdownBox
                                      label="college *"
                                      onChange={(e)=>{setFieldValue('colleageId',e?.target?.value);setFieldValue('colleageName',e?.target?.selectedOptions[0]?.text)}}
                                      value={values.colleageId}
                                      name="colleageId"
                                      onError={
                                        errors.colleageId &&
                                        touched.colleageId &&
                                        errors.colleageId
                                      }
                                      onBlur={handleBlur}
                                    >
                                    <option value="" name>Select</option>
                                    {colleageData?.map((item,index)=>  <option selected={item?.id==values?.colleageId} value={item?.id}>{item?.collegeName }</option>)}
                                      {/* <option value="" name>Select</option>
                                      <option value="Active">Active</option>
                                      <option value="Inactive">InActive</option> */}
                                    </DropdownBox>
                                  </div>
                                  <div className="col-span-12">
                                    <div className="justify-end flex">
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          navigate("/certificate");
                                        }}
                                        text='Cancel'
                                        className="btn_table green-bg py-2"
                                      />
                                      <Button type="submit" loader={isAPICall} disable={isAPICall} className="btn_table blue-bg ms-1 py-2" text={formType == "update"
                                          ? `Update`
                                          : `Save`} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                             


                          
                            </form>
                          </>
                        )}
                      </Formik>
               
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
</>
  )
}
