import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Layout/Sidebar'
import Header from '../../../Layout/Header'
import Breadcrumb from '../../../Layout/Breadcrumb'
import InputBox from '../../../components/core/InputBox/Index'
import DropdownBox from '../../../components/core/DropdownBox/Index'
import { batchesList, downloadBBReport, getBBReport } from '../../../http/batch'
import { useToast } from '../../../helper/custom hooks/useToast'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/core/Button/Index'
import moment from 'moment'

export default function BBReport() {
          const [phone, setPhone] = useState('')
          const [bacthId, setBatchId] = useState('')
          const [batchData, setBatchData] = useState([])
          const { showToast } = useToast();
          const [apiLoader, setApiLoader] = useState(false);
          const [tableData, setTableData] = useState(null);
          

          const navigate = useNavigate();
          const getBatchesList = async () => {
                    setApiLoader(true);
                    const payload = {
                      type: "dropdown",
                    };
                    const res = await batchesList(payload);
                    setApiLoader(false);
                
                    if (res.success) {
                        
                     setBatchData(res.data.rows)
                    } else if (res.status === 401) {
                           
                          
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {}
                      );
                      sessionStorage.clear();
                      navigate("/");
                    } else {
              
                    
                      showToast(
                        res.message,
                        "danger",
                        3000, // Custom timeout duration (5 seconds)
                        () => {}
                      );
                    }
                  };
                  useEffect(()=>{
                              getBatchesList()
                  },[])

                  const handleSubmit=async()=>{
                    if(phone !=''  && bacthId !='' && phone.length==10){
                              

                              setApiLoader(true);
                              const payload = {
                                type: "dropdown",
                              };
                              const res = await getBBReport(bacthId,phone);
                              setApiLoader(false);
                          
                              if (res.success) {
                               setTableData(res.data)
                              } else if (res.status === 401) {
                                        setTableData({...tableData,session_detalis:[]})
                                showToast(
                                  res.message,
                                  "danger",
                                  3000, // Custom timeout duration (5 seconds)
                                  () => {}
                                );
                                sessionStorage.clear();
                                navigate("/");
                              } else {
                                        setTableData({...tableData,session_detalis:[]})
                                showToast(
                                  res.message,
                                  "danger",
                                  3000, // Custom timeout duration (5 seconds)
                                  () => {}
                                );
                              }
                    }else{
                              showToast(
                                        'please add Proper number or select batch',
                                        "danger",
                                        3000, // Custom timeout duration (5 seconds)
                                        () => {}
                                      );

                    }

                  }
                  const handleDownload=async()=>{
                    if(phone !=''  && bacthId !='' && phone.length==10){
                              

                              setApiLoader(true);
                              
                              const res = await downloadBBReport(bacthId,phone);
                              if (!!res && res?.success != false) {
                                showToast(
                                  "File Downloaded Successfully",
                                  "success",
                                  3000 // Custom timeout duration (5 seconds)
                                );
                              } else {
                                showToast(
                                  res.message,
                                  "error",
                                  1000, // Custom timeout duration (5 seconds)
                                  () => {}
                                );
                              }
                    }else{
                              showToast(
                                        'please add Proper number or select batch',
                                        "danger",
                                        3000, // Custom timeout duration (5 seconds)
                                        () => {}
                                      );

                    }

                  }



  return (
    <>
          <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header  />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            <div className="dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4">
                    <div className="card-headers pt-2 pb-2 px-0 flex justify-between items-center p-2">
                      <div className="flex items-center flex-wrap p-2">
                        <h3 className="card-titles mb-0 me-3">Report</h3>
                        </div>
                       
                        </div>
                        <div className='grid grid-cols-12 gap-3 p-2'>

                        <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      type="number"
                                      id=""
                                      placeholder="Enter Phone no."
                                      label="Phone *"
                                      name="phone"
                                      className="border border-gray-300 px-2"
                                      onChange={(e)=>setPhone(e?.target?.value)}
                                      value={phone}
                                      
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-4">
                                    
                                    <DropdownBox
                                      name="BatchId"
                                     
                                      label="Batch *"
                                      
                                      value={bacthId}
                                      onChange={(e) => {
                                        setBatchId(e?.target?.value)
                                      }}
                                    >
                                      <option value="select">Select</option>
                                      {batchData.map((item, index) => {
                                        return (
                                          <option
                                            value={item?.id}
                                           
                                          >
                                            {item?.title}
                                          </option>
                                        );
                                      })}
                                    </DropdownBox>
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 flex gap-3">
                                  
                                    <Button text='View'  onClick={handleSubmit}/>
                                  
                                    <Button text='Downlaod'  onClick={handleDownload}/>
                                  </div>
                                 
                        </div>
                        <div className='grid grid-cols-12'>
                        <div className='col-span-12'>
                      {  console.log(tableData)}
                        {
                              tableData ==null  ? <></> :      tableData?.session_detalis?.length ?
                    <>   <table className='table min-w-full divide-y divide-gray-200'>
                        <thead className="border-b dark:border-neutral-500">

  <tr>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">S.no</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">Session Name</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">Session Type</th>

    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">Date</th>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">Duration</th>
    <th className='bg-gray-50 px-2 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r' colSpan="2">Attendance Score</th>
    <th className='bg-gray-50 px-2 py-2 !text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r' colSpan="3">Assessment Score</th>
  </tr>
  <tr>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Watched Duration</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Percentage</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total Score</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Obtained Score</th>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Percetange</th>
  </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200'>

  {
          tableData?.session_detalis.map((item,index)=>{
                  return  <>
  <tr className='border-b dark:border-neutral-500' key={index+1}>

    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{index+1}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.name}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.session_type}</td>

    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{moment(new Date(item?.date).toISOString().split("T")[0]).format('DD-MM-YYYY')}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.duration}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.attendance_score?.watched_duration}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.attendance_score?.percentage}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.assessment_score?.total_score}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.assessment_score?.obtained_score}</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{item?.assessment_score?.percetange}</td>
  </tr>
  
                    </>
          })
          
         
  }
  
                        </tbody>
                        <thead className="border-b dark:border-neutral-500">

                        <tr className='border-b dark:border-neutral-500' >

<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>

<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
</tr>
                        <tr>
                       



  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2"></th>
  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2"></th>
  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2"></th>

  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2"></th>
  <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' rowspan="2">Total session duration</th>
  <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total watched Duration</th>
  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Attendance percentage</th>
  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total assessment  Score</th>
  <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total Attempted Score</th>
  <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Assessment Percetange</th>
</tr>
                      </thead>
                      <tbody>

  <tr className='border-b dark:border-neutral-500' >

<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>

<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'><strong>Last 24hr data*</strong></td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.total_session_duration}</td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.total_watched_duration}</td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.attendance_percentage}</td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.total_assessment_score}</td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.total_attempted_score}</td>
<td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>{tableData?.assessment_percentage}</td>
</tr>
                      </tbody>
</table>
   {/* <table className='table min-w-full divide-y divide-gray-200'>
                        <thead className="border-b dark:border-neutral-500">

                        <tr>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' ></th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r'></th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' ></th>

    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r' ></th>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r'>Total Session Duration</th>
    <th className='bg-gray-50 px-2 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r' >Attendance Score</th>
    <th className='bg-gray-50 px-2 py-2 !text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-r' >Assessment Score</th>
  </tr>
  <tr>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total Watched Duration</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Total Percentage</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Assessment total Score</th>
    <th className='bg-gray-50 px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'> Assessment total obtained Score</th>
    <th className='bg-gray-50 px-2 py-2 !text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-t'>Assessment percetange</th>
  </tr>
    
    </thead>
    <tbody className='divide-y divide-gray-200'>


  <tr className='border-b dark:border-neutral-500'>

    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500' colSpan={1}></td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500' colSpan={1}></td>

    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500' colSpan={1}></td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'></td>

    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500' colSpan={1}>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
    <td className='px-3 py-2 bg-white capitalize w-[10px] border-r dark:border-neutral-500'>0</td>
  </tr>
  </tbody>
  
                
    </table> */}

</>
:
<p className='text-center text-[1rem]'>No Data Found</p>

                        }



                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
            </div>
            </div>
            </div>
    </>
  )
}
