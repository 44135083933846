import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";


export const updateForm = async (data = {},id) => {
          let result = { success: false };
          try {
            let res = await Axios.put(`/admin-api/v1/forms/${id}`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
      

        export const updateDiscussForm = async (id,data = {},) => {
          let result = { success: false };
          try {
            let res = await Axios.put(`/admin-api/v1/discussion-forums/${id}`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
export const saveForm = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/add`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };
        export const saveCampaign = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms/campaigns/add`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
          }
          return result;
        };

export const formList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/forms`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
        export const discussFormList = async (data = {}) => {
          let result = { success: false };
          try {
            let res = await Axios.post(`/admin-api/v1/discussion-forums`, data);
            result.success = true;
            result.message = res;
            return res.data;
          } catch (error) {
            result.message = error?.response?.data?.message;
        result.status = error?.response?.status;
           
        
          }
          return result;
        };
        
       



    
        
