import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from "./App";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-image-crop/dist/ReactCrop.css'
import { ToastProvider } from './helper/custom hooks/useToast';


// const root = ReactDOM.createRoot(document.getElementById("root"));
// ReactDOM.render(
// <React.StrictMode>

//     <Provider store={store}>
// <ToastProvider>
//         <App />
//         </ToastProvider>

//     </Provider>
//   </React.StrictMode>,document.getElementById("root")
// );
ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
 <ToastProvider>
      <App />
   </ToastProvider>
   </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals();
