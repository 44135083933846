import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";
import ImageUploadIcon from "../../../images/image-upload-shape.png";

import {
  BBImpactById,
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import PdfUploadComponent from "../../../components/core/FileUpload";
const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}
const chapterSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  url: Yup.string().required("Required"),
  // description: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(5000, "Too Long!")
  // .required("Required"),
  // reading_content: Yup.string().required("Required").notOneOf([`<p></p>\n`],'Required'),
  // duration: Yup.number()
  //   .typeError("Duration should be in number")
  //   .moreThan(0, "Duration should be grater than 0")
  //   .required("Required"),
  duration_in_min: Yup.number().typeError("Duration should be in number"),
  // .moreThan(0, "Duration should be grater than 0"),
  duration_in_sec: Yup.number().typeError("Duration should be in number"),
  // .moreThan(0, "Duration should be grater than 0"),
  preview_duration: Yup.number()
    .typeError("Duration should be in number")
    .moreThan(0, "Duration should be grater than 0")
    .required("Required"),
  sequence: Yup.number().typeError("sequence should be in number"),
  // share_long_url: Yup.string().required("Required"),
  // share_short_url: Yup.string().required("Required"),
  image: Yup.mixed().required("Required"),
  // comp_img_url: Yup.string().required("Required"),
  tags: Yup.array().min(1, "add one tag at least").required("Required"),
  // search_keyword: Yup.string().required("Required"),
  // search_question: Yup.string().required("Required"),
  // search_title: Yup.string().required("Required"),
  bb_author_id: Yup.string().required("Required"),
  framework_index: Yup.number()
    .typeError("framework index should be in number")
    .required("Required"),
});

// ClassicEditor.create( document.querySelector( '#editor' ), {
//   toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
//   heading: {
//       options: [
//           { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
//           { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
//           { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
//       ]
//   }
// } )
// .catch( error => {
//
// } );

function TopicForm(props) {
  const tagInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const subjectId = location?.state?.subjectId || "";
  const chapterId = location?.state?.chapterId || "";
  const unitId = location?.state?.unitId || "";
  let tempTopicId = location?.state?.topicId || "";
  const [topicId, setTopicId] = useState(location?.state?.topicId || "");

  const formType = props?.formType;
  const [author, setAuthor] = useState([]);
  const [topic, setTopic] = useState("");
  const [videoID, setVideoID] = useState("");
  // const [isSubmit,setIsSubmit]=useState(false)
  const [editorData, setEditor] = useState(formType == "add" ? "" : null);
  const [isImport, setIsImport] = useState(false);
  const [isAPICall, setIsAPICall] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isBBBatch, setIsBBBatch] = useState(false);
  const [isBBBatchVisible, setIsBBBatchVisible] = useState(false);

  const [descriptionData, setDescription] = useState(
    formType == "add" ? "" : null
  );
  const editorRef = useRef();

  const [usedForData, setUsedForData] = useState([
    {
      text: "Precourse",
      label: "precourse",
      isChecked: false,
      type: "checkbox",
    },
    {
      text: "Maincourse",
      label: "maincourse",
      isChecked: false,
      type: "checkbox",
    },
  ]);

  const handleInput = (e, index) => {
    usedForData[index].value = e.target.value;
  };
  const handleCheckBox = (e, index) => {
    usedForData[index].isChecked = e.target.checked;
    setUsedForData([...usedForData]);
  };
  const getTopicById = async () => {
    const res = await topicByID(topicId);

    if (res.success) {
      setTopicData(res.data[0]);
      usedForData[0].isChecked =
        res.data[0]?.used_for.precourse === "false" ? false : true;
      usedForData[1].isChecked =
        res.data[0]?.used_for.maincourse === "false" ? false : true;
      setUsedForData([...usedForData]);
      if (res.data[0]?.assessment?.length !== 0) {
        setQuestion([...res.data[0]?.assessment]);
      }
      setEditor(res.data[0].reading_content);
      setDescription(res.data[0].description);
      setSelectedFile(res.data[0]?.framework_file);
      setSelectedFile2(res.data[0]?.massive_action_plan);
      setIsBBBatch(res.data[0].is_bbimpact);

      setTopic("");
      setLoader(false);
    } else {
      if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      }
    }
  };

  const getBBImpactSubject = async () => {
    let payload = {
      subject_id: subjectId,
    };
    const res = await BBImpactById(payload);

    if (res.success) {
      setIsBBBatchVisible(res?.data?.is_bbimpact_topic);
    } else {
      if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getAuthorList();
    getBBImpactSubject();
    // if (topicId) {
    //   getTopicById();
    // } else {
    //   setTopicData({});
    // }
  }, []);
  const [inputValue, setValue] = useState("");

  useEffect(() => {
    if (topicId != "") {
      getTopicById();
    }
  }, [topicId]);
  // let isAPICall = false;
  const handleSubmit = async (e, values) => {
    // setIsSubmit(true)
    setIsAPICall(true);
    setTopicData({ ...values, tags: values?.tags?.join(",") });

    if (isAPICall) {
      // setIsSubmit(false)

      return true;
    } else {
      const isCheckAssesment = finalAssignmentData();

      if (isCheckAssesment) {
        const payload = {
          ...values,
        };
        if (!!subjectId) {
          payload.subject_id = subjectId;
        }
        if (!!topicId) {
          payload.topicId = topicId;
        }
        if (!!chapterId) {
          payload.chapter_id = chapterId;
        }
        if (!!unitId) {
          payload.unit_id = unitId;
        }

        delete payload["tags"];
        const formData = convertJsonToFormDataNested(payload);
        if (selectedFile) {
          formData.append("framework_file", selectedFile);
        }
        if (selectedFile2) {
          formData.append("massive_action_plan", selectedFile2);
        }
        usedForData.map((item) => {
          if (item.hasOwnProperty("value")) {
            formData.append(item.label, item.value);
          } else {
            formData.append(item.label, item.isChecked);
          }
          return null;
        });

        formData.append("assessment", JSON.stringify(question));
        formData.append("tags", values.tags);
        formData.set("reading_content", editorData);
        formData.set("description", descriptionData);
        formData.append("is_bbimpact", isBBBatch);

        let res;

        if (!!topicId) {
          res = await addTopicById(formData, topicId);
        } else {
          res = await addTopic(formData);
        }
        // setIsSubmit(false)

        if (res?.success) {
          setIsAPICall(false);

          navigate("/topic-list", {
            state: {
              subjectId: subjectId,
              chapterId: chapterId,
              unitId: unitId,
            },
          });
          showToast(
            res?.message,
            "success",
            1000, // Custom timeout duration (5 seconds)
            () => {}
          );
        } else {
          setIsAPICall(false);
          if (res?.status === 401) {
            showToast(
              res?.message,
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
            sessionStorage.clear();
            navigate("/");
          }
        }
      }
    }
  };
  const fetchUsers = () => {
    // return  myApi.get('/users?page=1').then(result => {
    //   const res =  result.data.data;
    //   return res;
    // });
  };
  const [topicData, setTopicData] = useState(null);
  const [question, setQuestion] = useState([
    {
      question_text: "",
      question_type: "single_select",
      answer: [],
      options: [],
      count: 0,
      tempOption: [
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
        {
          option: "",
          isChecked: false,
        },
      ],
    },
  ]);
  const addQuestion = () => {
    setQuestion([
      ...question,

      {
        question_text: "",
        question_type: "single_select",
        answer: [],
        options: [],
        count: 0,
        tempOption: [
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
          {
            option: "",
            isChecked: false,
          },
        ],
      },
    ]);
  };
  const addTags = (setFieldValue, data) => {
    if (tagInputRef.current.value.length > 0) {
      data.push(tagInputRef.current.value);
      setFieldValue("tags", data);
      tagInputRef.current.value = "";
    }
  };
  const handleRadio = (e, index, innerIndex) => {
    let tempdata = question;

    //   tempdata[index].tempOption.map((item,index)=>item.isChecked=false)
    // tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;

    // setQuestion([...tempdata]);
    // let tempdata = question;
    // let tempdata = question;

    tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;
    if (tempdata[index].question_type == "single_select") {
      tempdata[index].tempOption.map((item, index) => (item.isChecked = false));
      tempdata[index].tempOption[innerIndex].isChecked = e.target.checked;
    }

    setQuestion([...tempdata]);
  };
  const deleteQuestion = (index) => {
    let tempQuestion = question;
    tempQuestion.splice(index, 1);
    setQuestion([...tempQuestion]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].tempOption[innerIndex].option = e.target.value;
    setQuestion([...tempdata]);
  };
  const handleQuestionTitle = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].question_text = e.target.value;
    setQuestion([...tempdata]);
  };

  const finalAssignmentData = () => {
    question.map((item) => {
      item.count = 0;
      item.answer = [];
      item.options = [];
    });
    let tempdata = question;
    question.map((item, index) => {
      item.tempOption.map((innerItem, innerIndex) => {
        if (innerItem.isChecked) {
          item.count += 1;
          if (!item.answer.includes(innerItem.option)) {
            item.answer.push(innerItem.option);
          }
        }
        if (!item.options.includes(innerItem.option)) {
          item.options.push(innerItem.option);
        }

        // item.options.push(innerItem.option)
      });

      // if (item.count == 1) {
      //   item.question_type = "single_select";
      // }
      // if (item.count > 1) {
      //   item.question_type = "mcq";
      // }
      item.options = item.options.filter((v) => v != "");
      item.answer = item.answer.filter((v) => v != "");
    });
    tempdata[0].answer = [...new Set(tempdata[0].answer)];
    tempdata[0].options = [...new Set(tempdata[0].options)];
    // if(tempdata.length===1 && tempdata[0].options.length==0 && tempdata[0].answer.length==0){
    //   tempdata=[];
    // setQuestion([...tempdata]);

    //   return true

    // }else{

    for (let i = 0; i <= tempdata.length - 1; i++) {
      if (
        tempdata.length == 1 &&
        tempdata[i].options.length == 0 &&
        tempdata[i].answer.length == 0 &&
        tempdata[0].question_text == ""
      ) {
        tempdata = tempdata.splice(i, 1);
        setQuestion([...tempdata]);
        break;
        return true;
      } else {
        if (tempdata[i].options.length < 2) {
          showToast(
            `add at least 2 options in question number ${i + 1}`,
            "danger",
            3000, // Custom timeout duration (5 seconds)
            () => {}
          );
          setQuestion([...tempdata]);
          // setIsSubmit(false)
          return false;
          break;
        } else if (tempdata[i].answer.length === 0) {
          showToast(
            `add atleast one answer  in question number ${i + 1}`,
            "danger",
            3000, // Custom timeout duration (5 seconds)
            () => {}
          );
          setQuestion([...tempdata]);
          // setIsSubmit(false)
          return false;
          break;
        } else if (tempdata[i].question_text == "") {
          showToast(
            `add question title in  question number ${i + 1}`,
            "danger",
            3000, // Custom timeout duration (5 seconds)
            () => {}
          );
          setQuestion([...tempdata]);
          // setIsSubmit(false)
          return false;
          break;
        }
      }
    }

    //  setIsSubmit(false)
    return true;
  };
  const handleSaveToDraft = async (values) => {
    finalAssignmentData();

    const payload = {
      ...values,
    };
    for (let key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }

    if (!!subjectId) {
      payload.subject_id = subjectId;
    }
    if (!!chapterId) {
      payload.chapter_id = chapterId;
    }
    if (!!unitId) {
      payload.unit_id = unitId;
    }

    delete payload["tags"];
    if (!!topicId) {
      payload.topicId = topicId;
    }
    const formData = convertJsonToFormDataNested(payload);
    usedForData.map((item) => {
      if (item.hasOwnProperty("value")) {
        formData.append(item.label, item.value);
      } else {
        formData.append(item.label, item.isChecked);
      }
      return null;
    });

    formData.append("assessment", JSON.stringify(question));
    formData.set("reading_content", editorData);
    formData.set("description", descriptionData);
    formData.append("tags", values.tags);
    formData.append("is_bbimpact", isBBBatch);

    for (let key of formData.keys()) {
      // Check if key is empty

      if (!formData.get(key)) {
        // Remove entry with empty key
        formData.delete(key);
      }
    }

    let res;
    if (!!topicId) {
      res = await addTopicById(formData, topicId);
    } else {
      res = await addTopic(formData);
    }

    if (res.success) {
      showToast(
        res.message,
        "success",
        1000, // Custom timeout duration (5 seconds)
        () => {}
      );
      navigate("/topic-list", {
        state: { subjectId: subjectId, chapterId: chapterId, unitId: unitId },
      });
    } else {
      if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      }
    }
  };

  const getAuthorList = async () => {
    const payload = {
      page: 0,
      type: "dropdown",
    };

    const res = await authorList(payload);

    if (res.success) {
      let dataArr = res?.data?.rows;
      setAuthor(dataArr);
    } else {
      if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };

  useEffect(() => {
    if (editorData != null) {
    }
  }, [editorData]);

  const importVideo = async () => {
    setLoader(true);
    const res = await importVideoById(videoID, chapterId);

    if (res.success) {
      const data = res.data;
      setTopicId(data.id);
    } else {
      if (res.status === 401) {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
    setLoader(false);
  };
  const handleInputChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    if (isImport || formType != "add") {
      setEditor(null);
      setDescription(null);
    } else {
      setEditor("");
      setDescription("");
    }
  }, [isImport]);

  const handleChangeQuestionType = (e, index) => {
    let tempdata = question;

    tempdata[index].question_type = e.target.value;

    tempdata[index].tempOption.map((item) => (item.isChecked = false));
    tempdata[index].tempOption.map((item) => (item.isChecked = false));

    setQuestion([...tempdata]);
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "edit" ? `Edit Topic` : `Add Topic`}
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2">
                      {/* <Button text='Add new topic' onClick={()=>('new')}/> */}
                      {formType == "add" && (
                        <CheckBox
                          label="Import Data"
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setIsImport(false);
                              window.location.reload();

                              setTopic("");
                            } else {
                              setTopic("import");

                              setIsImport(true);
                            }
                          }}
                        />
                      )}
                    </div>
                    {topic == "import" && (
                      <div className="flex gap-2 mb-2 items-end">
                        <InputBox
                          onChange={(e) => setVideoID(e?.target?.value)}
                          type="number"
                          className="border border-gray-300 px-2"
                          id=""
                          label="Video ID"
                          placeholder="Enter Topic's video id"
                        />
                        <Button
                          text="Import"
                          disable={!videoID}
                          onClick={importVideo}
                        />
                      </div>
                    )}
                    <div className={topic == "import" ? "hidden" : ""}>
                      <Formik
                        validationSchema={chapterSchema}
                        initialValues={{
                          title: topicData?.title || "",
                          url: topicData?.url || "",
                          description: topicData?.description || "",
                          reading_content: topicData?.reading_content || "",
                          duration_in_min: topicData?.duration_in_min || 0,
                          duration_in_sec: topicData?.duration_in_sec || 0,
                          preview_duration: topicData?.preview_duration || "",
                          // share_long_url: topicData?.share_long_url || "",
                          // share_short_url: topicData?.share_short_url || "",
                          image: topicData?.image || "",
                          // comp_img_url: topicData?.comp_img_url || "",
                          tags:
                            topicData?.tags !== ""
                              ? topicData?.tags?.split(",") || []
                              : [],
                          // search_keyword: topicData?.search_keyword || "",
                          // search_question: topicData?.search_question || "",
                          // search_title: topicData?.search_title || "",
                          bb_author_id: topicData?.bb_author_id || "",
                          status: topicData?.status || "pending",
                          sequence: topicData?.sequence || 0,
                          framework_index: topicData?.framework_index || 0,
                        }}
                        enableReinitialize
                        onSubmit={(values, e) => handleSubmit(e, values)}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          /* and other goodies */
                        }) => (
                          <>
                            <form onSubmit={handleSubmit}>
                              <div className="card-body pt-0 form-author">
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 lg:col-span-6">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Title *"
                                      placeholder="Enter Title"
                                      name="title"
                                      value={values.title}
                                      onError={
                                        errors.title &&
                                        touched.title &&
                                        errors.title
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6">
                                    {/* <AsyncSelect
        cacheOptions
        defaultOptions
        value={values.bb_author_id}
        getOptionLabel={e => e.first_name + ' ' + e.last_name}
        getOptionValue={e => e.id}
        loadOptions={fetchUsers}
        onInputChange={handleInputChange}
        onChange={()=>setFieldValue('bb_author_id',values.bb_author_id)}
      />   */}
                                    {author.length > 0 && (
                                      <>
                                        <Select
                                          className="basic-single px-2 pt-[1.5rem]  border-gray-300 rounded-md w-full"
                                          classNamePrefix="select"
                                          isSearchable={true}
                                          // defaultValue={author.filter(option=>option.id==values.bb_author_id)}
                                          name="color"
                                          options={author}
                                          value={author.filter(
                                            (option) =>
                                              option.id == values.bb_author_id
                                          )}
                                          getOptionLabel={(e) =>
                                            e.title + " " + e.name
                                          }
                                          getOptionValue={(e) => e.id}
                                          onChange={(value) =>
                                            setFieldValue(
                                              "bb_author_id",
                                              value.id
                                            )
                                          }
                                          placeholder="Select Author"
                                          // classNames='px-2 py-2 border border-gray-300 rounded-md w-full'
                                        />
                                        {
                                          <p className="onerror">
                                            {errors.bb_author_id &&
                                              touched.bb_author_id &&
                                              errors.bb_author_id}
                                          </p>
                                        }
                                      </>
                                    )}

                                    {/* <DropdownBox
                                      name="bb_author_id"
                                      label="Author *"
                                      onError={
                                        errors.bb_author_id &&
                                        touched.bb_author_id &&
                                        errors.bb_author_id
                                      }
                                      value={values.bb_author_id}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "bb_author_id",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="select">Select</option>
                                      {author.map((item, index) => {
                                        return (
                                          <option
                                            value={item.id}
                                            selected={
                                              item.id == values.bb_author_id
                                            }
                                          >
                                            {item.title + " " + item.name}
                                          </option>
                                        );
                                      })}
                                    </DropdownBox> */}
                                    {/* <AsyncDropdown label='Author'getOptionLabel={(option)=>option.title+' '+option.name} name='bb_author_id' url='admin-api/v1/authors' 
                              value={values?.bb_author_id?.title} 
                              placeholder='Select Author' onChange={setFieldValue}
                               onError={errors.bb_author_id && touched.bb_author_id && errors.bb_author_id}
                                onBlur={handleBlur}/> */}
                                  </div>

                                  <div className="col-span-12 lg:col-span-122">
                                    <label className="rounded-md mb-2">
                                      Reading Content
                                    </label>

                                    {editorData != null && (
                                      <>
                                        <CKEditor
                                          ref={editorRef}
                                          onInstanceReady={() => {
                                            const iframe =
                                              document.getElementsByClassName(
                                                "cke_wysiwyg_frame"
                                              );

                                            const iframeDoc =
                                              iframe[0].contentDocument ||
                                              iframe[0].contentWindow.document;
                                            const elementsInsideIframe =
                                              iframeDoc.querySelectorAll(
                                                "span[lang]"
                                              );
                                            elementsInsideIframe.forEach(
                                              (element) => {
                                                element.style.fontStyle =
                                                  "normal";
                                                // Add more style properties as needed
                                              }
                                            );
                                          }}
                                          config={{
                                            allowedContent: true, // Allow all content (use with caution)
                                            extraAllowedContent:
                                              "span{(color)[]};", // Allow span elements with the color attribute
                                          }}
                                          onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setEditor(data);
                                          }}
                                          initData={editorData}
                                        />
                                      </>
                                    )}

                                    {/* <CKEditor
        editor={ClassicEditor}
        data="<p>Hello from CKEditor!</p>"
        onReady={handleEditorReady} 
        // config={{
        //   // Add your custom configurations here
        //   plugins: [
        //     Essentials,
        //     Font,
        //     Heading,
        //     Link,
        //     List,
        //     Paragraph,
        //     Alignment,
        //     Image,
        //     Table,
        //   ],
          // toolbar: [
          //   'heading',
          //   '|',
          //   'bold',
          //   'italic',
          //   'link',
          //   'bulletedList',
          //   'numberedList',
          //   'alignment',
          //   'imageInsert',
          //   'insertTable',
          // ],
          // image: {
          //   toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
          // },
          // table: {
          //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
          // },
        // }}
      // />
                                    {/* <InputBox
                                      onChange={setFieldValue}
                                      type="editor"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="reading content"
                                      placeholder="Enter reading content"
                                      name="reading_content"
                                      value={values.reading_content}
                                      onError={
                                        errors.reading_content &&
                                        touched.reading_content &&
                                        errors.reading_content
                                      }
                                      onBlur={handleBlur}
                                    /> */}
                                  </div>
                                  <div className="col-span-12 lg:col-span-6">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Sequence"
                                      placeholder="Enter sequence"
                                      name="sequence"
                                      value={values.sequence}
                                      onError={
                                        errors.sequence &&
                                        touched.sequence &&
                                        errors.sequence
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Video Id *"
                                      placeholder="Enter topic's video ID"
                                      name="url"
                                      value={values.url}
                                      onError={
                                        errors.url && touched.url && errors.url
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Duration"
                                      name="duration"
                                      value={values.duration}
                                      onError={
                                        errors.duration &&
                                        touched.duration &&
                                        errors.duration
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      placeholder="Enter Duration"
                                      disabled={true}
                                      label="Duration ( in Minutes ) *"
                                      name="duration_in_min"
                                      value={values.duration_in_min}
                                      onError={
                                        errors.duration_in_min &&
                                        touched.duration_in_min &&
                                        errors.duration_in_min
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      disabled={true}
                                      placeholder="Enter Duration (in Seconds)"
                                      label="Duration ( in Seconds ) *"
                                      name="duration_in_sec"
                                      value={values.duration_in_sec}
                                      onError={
                                        errors.duration_in_sec &&
                                        touched.duration_in_sec &&
                                        errors.duration_in_sec
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      placeholder="Enter Preview Duration (in Seconds)"
                                      label="Preview Duration ( in Seconds ) *"
                                      name="preview_duration"
                                      value={values.preview_duration}
                                      onError={
                                        errors.preview_duration &&
                                        touched.preview_duration &&
                                        errors.preview_duration
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      placeholder="Enter Framework Index"
                                      label="Framework Index"
                                      name="framework_index"
                                      value={values.framework_index}
                                      onError={
                                        errors.framework_index &&
                                        touched.framework_index &&
                                        errors.framework_index
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Share long url "
                                      name="share_long_url"
                                      value={values.share_long_url}
                                      onError={
                                        errors.share_long_url &&
                                        touched.share_long_url &&
                                        errors.share_long_url
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Share short url "
                                      name="share_short_url"
                                      value={values.share_short_url}
                                      onError={
                                        errors.share_short_url &&
                                        touched.share_short_url &&
                                        errors.share_short_url
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  <div className="col-span-12 lg:col-span-12">
                                    <div className="form-group">
                                      <ImageUploader
                                        isCrop={true}
                                        name="image"
                                        label="Image *"
                                        onChange={setFieldValue}
                                        imageUrl={values.image}
                                        imageShapeView={ImageUploadIcon}
                                        onError={
                                          errors.image &&
                                          touched.image &&
                                          errors.image
                                        }
                                        onBlur={handleBlur}
                                      />
                                      {errors.image && touched.image && (
                                        <p className="onerror">
                                          {errors.image}
                                        </p>
                                      )}

                                      <p className="mt-1">
                                        <b>NOTE</b> : Only png, jpg, gif
                                        extension allowed for images.
                                      </p>
                                    </div>
                                  </div>
                                  {/* PDF upload */}
                                  {isBBBatchVisible && (
                                    <div className="col-span-2 lg:col-span-2 max-w-fit">
                                      <CheckBox
                                        value={isBBBatch}
                                        onChange={(e) =>
                                          setIsBBBatch(e.target.checked)
                                        }
                                        label="BB Impact"
                                      />
                                    </div>
                                  )}
                                  {isBBBatch && (
                                    <div className="col-span-12 lg:col-span-12">
                                      <div className="form-group">
                                        <PdfUploadComponent
                                          selectedFile={selectedFile}
                                          setSelectedFile={setSelectedFile}
                                          label="Framework file"
                                        />
                                        <p className="mt-1">
                                          <b>NOTE</b> : Only pdf extension
                                          allowed.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-span-12 lg:col-span-12">
                                    <div className="form-group">
                                      <PdfUploadComponent
                                        selectedFile={selectedFile2}
                                        setSelectedFile={setSelectedFile2}
                                        label="Massive action plan"
                                      />
                                      <p className="mt-1">
                                        <b>NOTE</b> : Only pdf extension
                                        allowed.
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Compress image url"
                                      name="comp_img_url"
                                      value={values.comp_img_url}
                                      onError={
                                        errors.comp_img_url &&
                                        touched.comp_img_url &&
                                        errors.comp_img_url
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  {/* <div className="col-span-12 lg:col-span-4">
                                  <InputBox
                                    onChange={handleChange}
                                    type="text"
                                    className="border border-gray-300 px-2"
                                    id=""
                                    label="Tags"
                                    name="tags"
                                    value={values.tags}
                                    onError={
                                      errors.tags && touched.tags && errors.tags
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div> */}
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Search keywords"
                                      name="search_keyword"
                                      value={values.search_keyword}
                                      onError={
                                        errors.search_keyword &&
                                        touched.search_keyword &&
                                        errors.search_keyword
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Search Question"
                                      name="search_question"
                                      value={values.search_question}
                                      onError={
                                        errors.search_question &&
                                        touched.search_question &&
                                        errors.search_question
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  {/* <div className="col-span-12 lg:col-span-4">
                                    <InputBox
                                      onChange={handleChange}
                                      type="text"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="Search Title"
                                      name="search_title"
                                      value={values.search_title}
                                      onError={
                                        errors.search_title &&
                                        touched.search_title &&
                                        errors.search_title
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div> */}
                                  <div className="col-span-12 lg:col-span-12">
                                    {/* <InputBox
                                      onChange={handleChange}
                                      type="textarea"
                                      className="border border-gray-300 px-2"
                                      id=""
                                      label="description"
                                      name="description"
                                      value={values.description}
                                      onError={
                                        errors.description &&
                                        touched.description &&
                                        errors.description
                                      }
                                      onBlur={handleBlur}
                                    /> */}
                                    <label className="rounded-md mb-2">
                                      Description
                                    </label>
                                    {/* <textarea name="editor2" id='editor2'></textarea> */}
                                    {descriptionData != null && (
                                      <CKEditor
                                        ref={editorRef}
                                        config={{
                                          allowedContent: true, // Allow all content (use with caution)
                                          extraAllowedContent:
                                            "span{(color)[]};", // Allow span elements with the color attribute
                                        }}
                                        onChange={(event, editor) => {
                                          const data = event.editor.getData();
                                          setDescription(data);
                                        }}
                                        initData={descriptionData}

                                        // onInstanceReady={ () => {
                                        //     alert( 'Editor is ready!' );
                                        // } }
                                      />
                                    )}
                                  </div>

                                  <div className="col-span-12 lg:col-span-4">
                                    <DropdownBox
                                      label="Status"
                                      name="status"
                                      onChange={handleChange}
                                      value={values.status}
                                      onError={
                                        errors.status &&
                                        touched.status &&
                                        errors.status
                                      }
                                      onBlur={handleBlur}
                                    >
                                      <option value="select">Select</option>
                                      <option
                                        value="enabled"
                                        selected={values.status === "enabled"}
                                      >
                                        Enable
                                      </option>
                                      <option
                                        value="disabled"
                                        selected={values.status === "disabled"}
                                      >
                                        Disable
                                      </option>
                                    </DropdownBox>
                                  </div>

                                  <div className="col-span-12 lg:col-span-10">
                                    <p className="mt-1">
                                      <b>NOTE</b> : Please tick option for make
                                      them answer.
                                    </p>
                                    {question.map((item, index) => {
                                      return (
                                        <>
                                          <div className="relative mt-2">
                                            <div className="assessment_qus_box px-4 py-3">
                                              <div className="question">
                                                <label
                                                  className="mb-3"
                                                  for="exampleInputEmail1"
                                                >
                                                  Question
                                                </label>
                                                <input
                                                  type="text"
                                                  autocomplete="off"
                                                  className="border border-gray-300 px-2 rounded-md  w-full py-2"
                                                  placeholder="Enter Question here"
                                                  name="question_text"
                                                  value={item.question_text}
                                                  onChange={(e) =>
                                                    handleQuestionTitle(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>

                                              <DropdownBox
                                                label="Question Type"
                                                required={true}
                                                className="w-[10rem]"
                                                onChange={(e) =>
                                                  handleChangeQuestionType(
                                                    e,
                                                    index
                                                  )
                                                }
                                                value={item.question_type}
                                                name="Question type"
                                                //       onError={
                                                //         errors.type &&
                                                //         touched.type &&
                                                //           errors.type
                                                //       }
                                                //       onBlur={handleBlur}
                                              >
                                                <option
                                                  value="select"
                                                  selected={
                                                    item.question_type ==
                                                    "select"
                                                  }
                                                >
                                                  Select
                                                </option>
                                                <option
                                                  value="single_select"
                                                  selected={
                                                    item.question_type ==
                                                    "single_select"
                                                  }
                                                >
                                                  Single
                                                </option>
                                                <option
                                                  value="mcq"
                                                  selected={
                                                    item.question_type == "mcq"
                                                  }
                                                >
                                                  Multiple
                                                </option>
                                              </DropdownBox>
                                              <div className="ans-box w-9/12">
                                                {item.tempOption.map(
                                                  (innerItem, innerIndex) => (
                                                    <>
                                                      <div className="flex pt-3 items-center">
                                                        <input
                                                          disabled={
                                                            innerItem.option
                                                              .length == 0
                                                          }
                                                          type={
                                                            item.question_type ==
                                                            "single_select"
                                                              ? "radio"
                                                              : "checkbox"
                                                          }
                                                          name={`option${index}`}
                                                          autocomplete="off"
                                                          checked={
                                                            innerItem.isChecked
                                                          }
                                                          onChange={(e) =>
                                                            handleRadio(
                                                              e,
                                                              index,
                                                              innerIndex
                                                            )
                                                          }
                                                        />
                                                        <input
                                                          type="text"
                                                          autocomplete="off"
                                                          value={
                                                            innerItem.option
                                                          }
                                                          className="border border-gray-300 px-2 ms-2 w-full py-2 rounded-md"
                                                          placeholder="Enter option here"
                                                          name={`option`}
                                                          onChange={(e) =>
                                                            handleQuestion(
                                                              e,
                                                              index,
                                                              innerIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                            <div className="add-qustion">
                                              {index == question.length - 1 ? (
                                                <button
                                                  type="button"
                                                  className="green-bg me-2"
                                                  onClick={addQuestion}
                                                >
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              ) : null}
                                              {index != 0 && (
                                                <button
                                                  type="button"
                                                  className="blue-bg"
                                                  onClick={() =>
                                                    deleteQuestion(index)
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-minus"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="col-span-12 lg:col-span-7">
                                    <p>Used For</p>
                                    <div className="border border-gray-200 p-2 rounded-md">
                                      {usedForData.map((item, index) => {
                                        return (
                                          <>
                                            {item.type === "checkbox" ? (
                                              <div>
                                                <CheckBox
                                                  value={item.isChecked}
                                                  onChange={(e) =>
                                                    handleCheckBox(e, index)
                                                  }
                                                  label={item.text}
                                                />
                                              </div>
                                            ) : (
                                              <div>
                                                <InputBox
                                                  type={item.type}
                                                  onChange={(e) =>
                                                    handleInput(e, index)
                                                  }
                                                  placeholder="Enter number"
                                                  label={item.text}
                                                />
                                              </div>
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div className="col-span-12 md:col-span-7 lg:col-span-7 mt-3">
                                    <div className="form-group relative">
                                      {/* <InputBox
                                      type="text"
                                      ref={tagInputRef}
                                      id=""
                                      label="Tags"
                                      placeholder="Enter Tags"
                                      name="tags"
                                      className="border border-gray-300 px-2"
                                      value={values.tags}
                                      onError={
                                        errors.tags &&
                                        touched.tags &&
                                        errors.tags
                                      }
                                    /> */}
                                      <input
                                        ref={tagInputRef}
                                        autocomplete="off"
                                        placeholder="Enter Tags"
                                        name="tags"
                                        className="border border-gray-300 px-2 rounded-md w-full py-2"
                                        onError={
                                          errors.tags &&
                                          touched.tags &&
                                          errors.tags
                                        }
                                      />
                                      <div className="add-qustion">
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={() =>
                                            addTags(setFieldValue, values.tags)
                                          }
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                    <Chip
                                      data={values.tags}
                                      onChange={setFieldValue}
                                    />
                                    <p className="onerror">
                                      {errors.tags &&
                                        touched.tags &&
                                        errors.tags}
                                    </p>
                                  </div>
                                  <div className="col-span-12">
                                    <div className="justify-end flex">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          navigate(-1);
                                        }}
                                        className="btn_table green-bg py-2"
                                      >
                                        Cancel
                                      </button>
                                      <Button
                                        loader={isAPICall}
                                        type="submit"
                                        className="btn_table blue-bg ms-1 py-2"
                                        text={
                                          formType == "update"
                                            ? `Update`
                                            : `Save`
                                        }
                                      />
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          handleSaveToDraft(values)
                                        }
                                        className="btn_table green-bg ms-1 py-2"
                                        text="Save as draft"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* {JSON.stringify(values)}
                              {JSON.stringify(errors)} */}
                            </form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {isLoader && <Loader />}
    </>
  );
}

export default TopicForm;
