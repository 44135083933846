import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";
export const bannerDropDownData = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/sessions/banner-and-cta`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const bannerDeatils = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/sessions/course-live-session/${id}/banner`, {});
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
export const BannerUpdate = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/sessions/course-live-session/save`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
    result.status = error?.response?.status;
  }
  return result;
};
