import Axios, { setToken } from "../Axios";
import { getToken } from "../Utility";


export const addAssesmentById = async (data = {},id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/activities/${id}`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
 
  }
  return result;
};
export const addAssesment = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/activities/add`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  
  }
  return result;
};

export const assesmentList = async (data = {}) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/activities`, data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
export const assesmentByID = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.get(`/admin-api/v1/activities/${id}`,);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
export const pollById = async (data) => {
  let result = { success: false };
  try {
    let res = await Axios.post(`/admin-api/v1/activities/result`,data);
    result.success = true;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};


export const publishTopicById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.put(`/admin-api/v1/topics/publish/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};
export const assesmentDeleteById = async (id) => {
  let result = { success: false };
  try {
    let res = await Axios.delete(`/admin-api/v1/activities/${id}`);
    result.success = res.status;
    result.message = res;
    return res.data;
  } catch (error) {
    result.message = error?.response?.data?.message;
result.status = error?.response?.status;
  }
  return result;
};

