import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";

import {
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { FAQDetails, assesmentDetails, saveAssesment, saveFAQ } from "../../../http/session";

function FAQ(props) {
  
  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const topicID = location?.state?.topicID || "";
  const type = location?.state?.type || "";
  const [isLoader, setLoader] = useState(false);


  const formType = props?.formType;

  const [title, setTitle] = useState('');
  const [description, setDescroption] = useState('');
  const [question,setQuestion]=useState([{title:'',description:''}])

  const addQuestion=()=>{
    let tempQuestion=question;
    tempQuestion.push({title:'',description:''})
  
    setQuestion([...tempQuestion])
   }
   const removeQuestion=(index)=>{
    let tempQuestion=question;
    tempQuestion.splice(index, 1);
 

    setQuestion([...tempQuestion]);
   }
  const handleChange=(e,index,value)=>{
    let tempQuestion=question;
 
    if(typeof e=='object'){

      tempQuestion[index][value]=e?.target?.value
    }
   

    setQuestion([...question])
  }
  

  const checkFAQ=()=>{
    let tempQuestion=question
 
for(let i=0;i<tempQuestion.length;i++){

  if(!tempQuestion[i]?.title){
    showToast(
      `Title can\'t not be blank in question no. ${i+1}`,
      "danger",
      3000, // Custom timeout duration (5 seconds)
      () => {}
    );
    return false
    break;
  
  }else if(!tempQuestion[i]?.description){
    showToast(
      `Description can\'t not be blank in question no. ${i+1}`,
      "danger",
      3000, // Custom timeout duration (5 seconds)
      () => {}
    );
  
    return false
    break;

  
  }
}
   
    
    return true;

  }


  const getAsessmentData = async () => {
    let payload = {
      type: type,
      reference_id: topicID,
    };
    let res = await FAQDetails(payload);

    if (res?.success) {
      if (res.data.length != 0) {
    console.log('from get api')
        
        setQuestion(res?.data[0]?.faq)
        // alert('fs')

       
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };
  useEffect(() => {
    getAsessmentData();
  }, []);


  const handleSubmit = async (e, values) => {
  // console.log(isCheck)

  const isCheck=checkFAQ()
  
  if(isCheck){

    let payload={
      "type" : "topic",
      "reference_id" : topicID,
      "faq": question
    }

      let res;

      res = await saveFAQ(payload);

      // setIsSubmit(false)

      if (res?.success) {
        navigate("/topic-list");
        showToast(
          res?.message,
          "success",
          1000, // Custom timeout duration (5 seconds)
          () => {}
        );
      } else {
        if (res?.status === 401) {
          showToast(
            res?.message,
            "danger",
            3000, // Custom timeout duration (5 seconds)
            () => {}
          );
          sessionStorage.clear();
          navigate("/");
        }
      }
  }
    }


  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                         FAQ
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-10">

                        {
                          question?.map((item,index)=>
                          <>
                          <div className="relative mt-2">
                                    <div className="assessment_qus_box px-4 py-3">

                        <div className="col-span-12 lg:col-span-10">
                            <InputBox
                              label="Title"
                              type="text"
                              value={item?.title}
                              onChange={(e)=>handleChange(e,index,'title')}
                              name='title'
                            />
                          </div>
                          <div className="col-span-12 lg:col-span-10">
                            <InputBox
                              label="Description"
                              type="text"
                              value={item?.description}
                              onChange={(e)=>handleChange(e,index,'description')}
                              name='description'
                            />
                          </div>
                          <div className="add-qustion mb-2">
                                      {index == question.length - 1 ? (
                                        <button
                                          type="button"
                                          className="green-bg me-2"
                                          onClick={addQuestion}
                                        >
                                          <i
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : null}
                                      {index != 0 && (
                                        <button
                                          type="button"
                                          className="blue-bg"
                                          onClick={() => removeQuestion(index)}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                    </div>
                                    </div>
                          </>
                          )
                        }
                        </div>
                        
                         
                        </div>
                        <div className="grid grid-cols-12 gap-4 mt-4">
                          

                          <div className="col-span-12">
                            <div className="justify-end flex">
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn_table green-bg py-2"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSubmit}
                                className="btn_table blue-bg ms-1 py-2"
                              >
                                {formType == "update" ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Container-fluid end--> */}
            </div>
          </div>
        </div>
        {/* <!-- Page Body end--> */}
      </div>
      {isLoader && <Loader />}
    </>
  );
}

export default FAQ;
