import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export function useToast() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }) {
  const [toast, setToast] = useState(null);


   const showToast = (message, type = 'info', duration = 3000, onClose = null) => {
    setToast({ message, type });
    if (duration > 0) {
      setTimeout(() => {
        hideToast();
        if (onClose) {
          onClose();
        }
      }, duration);
    }
  };

  const hideToast = () => {
    setToast(null);
  };


  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>


      {children}
       {toast && <Toast message={toast.message} type={toast.type} />}
    </ToastContext.Provider>
  );
}

const Toast = React.memo(({ message, type='success' }) => {
  return (
    <div
      className={`bottom-10 right-10 p-4 rounded text-white z-[9999] fixed h-fit top-0 ${
            type === 'success'
              ? 'bg-green-500'
              : type === 'danger'
              ? 'bg-red-500'
              : 'bg-blue-500'
          }`}
    >
      {message || 'Something went wrong'}
    </div>
  );
});

export default Toast;


