import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import Breadcrumb from "../../../Layout/Breadcrumb";
import InputBox from "../../../components/core/InputBox/Index";
import { Formik } from "formik";
import * as Yup from "yup";
import ImageUploader from "../../../components/core/ImageUploader";
import { useToast } from "../../../helper/custom hooks/useToast";
import { useNavigate, useLocation } from "react-router";
import CheckBox from "../../../components/core/checkbox/Index";
import Chip from "../../../components/core/Chip/Index";
import { CKEditor } from "ckeditor4-react";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  addTopic,
  addTopicById,
  importVideoById,
  publishTopicById,
  topicByID,
} from "../../../http/topic";
import { convertJsonToFormDataNested } from "../../../helper/utils";
import DropdownBox from "../../../components/core/DropdownBox/Index";
import { authorList } from "../../../http/author";
import Button from "../../../components/core/Button/Index";
import Loader from "../../../components/core/Loader/Index";
import { set } from "immutable";
// import Select from "react-select/dist/declarations/src/Select";
import { assesmentDetails, saveAssesment } from "../../../http/session";
import {
  campaignDetails,
  formDetails,
  formList,
  professionList,
  saveCampaign,
  saveForm,
  updateCampaign,
  updateForm,
} from "../../../http/feedback";
import moment from "moment";
import MultiSelect from "../../../components/core/MultiDrop";

function CampaignAdd(props) {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [openToAll, setOpenToAll] = useState(false);
  const [is_bb_impact, setIs_bb_impact] = useState(false);
  const [professions, setProfessions] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [source, setSource] = useState("");
  const [formId, setFormId] = useState("");
  const [formData, setFormData] = useState([]);
  const [professionData, setProfessionData] = useState([]);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const location = useLocation();
  const CampaignId = location?.state?.CampaignId || "";
  const type = location?.state?.type || "";

  const formType = props?.formType;

  const [isLoader, setLoader] = useState(false);

  const getProfession = async () => {
    const payload = {
      type: "dropdown",
    };

    const res = await professionList(payload);

    if (res.success) {
      setProfessionData(res.data);
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  useEffect(() => {
    if (professionData.length != 0) {
      if (formType == "edit") {
        getCampaignData();
      }
    }
  }, [professionData]);
  const makeDefualtProfession = (selectedProfession) => {
    let tempdata = [];

    professionData.forEach((item) => {
      // console.log(item);
      if (selectedProfession.includes(item._id)) {
        // console.log(item._id);
        tempdata.push(item);
      }
    });

    setProfessions(tempdata);
  };
  useEffect(() => {
    if (professions.length != 0) {
      setIs_bb_impact(true);
    }
  }, [professions]);
  const getCampaignData = async () => {
    let res = await campaignDetails(CampaignId);

    if (res?.success) {
      if (res.data.length != 0) {
        // alert('fs')
        setName(res.data[0]?.name);
        setCode(res.data[0]?.code);
        setSource(res.data[0]?.source);
        setFormId(res.data[0]?.form_id);
        setStartDate(
          new Date(res.data[0]?.start_date).toISOString().split("T")[0]
        );
        setEndDate(new Date(res.data[0]?.end_date).toISOString().split("T")[0]);

        setIsPublished(res.data[0]?.published);
        setOpenToAll(res.data[0]?.open_for_all);
        // setIs_bb_impact(res.data[0]?.is_bb_impact);
        makeDefualtProfession(res.data[0]?.profession);
        // setProfessions(res.data[0]?.profession);

        // setQuestion([...res.data[0]?.assessment]);
      }
    } else {
      if (res?.status === 401) {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        sessionStorage.clear();
        navigate("/");
      } else {
        showToast(
          res?.message,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    }
  };
  const getFormList = async () => {
    const payload = {
      type: "dropdown",
    };

    const res = await formList(payload);

    if (res.success) {
      setFormData(res.data.rows);
      // if (formType == "edit") {
      //   getCampaignData();
      // }
    } else if (res.status === 401) {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
      sessionStorage.clear();
      navigate("/");
    } else {
      showToast(
        res.message,
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
  };
  useEffect(() => {
    getProfession();
    getFormList();
    // if (formType == "edit") {
    //   getCampaignData();
    // }
  }, []);

  const isArrayofObjects = (arr) => {
    if (!Array.isArray(arr)) {
      return false; // Not an array
    }

    // Check if every element in the array is an object
    return arr.every((item) => typeof item === "object" && item !== null);
  };
  const handleSubmit = async (e, values) => {
    let tempProfession = [];

    professions.forEach((item) => tempProfession.push(item._id));
    if (name && code && startDate && endDate && source && formId) {
      if (startDate <= endDate) {
        const isCheckAssesment = true;
        if (isCheckAssesment) {
          let payload = {
            name: name,
            code: code,
            form_id: formId,
            source: source,
            start_date: startDate,
            end_date: endDate,
            published: isPublished,
            open_for_all: openToAll,
            is_bb_impact: is_bb_impact,
            profession: isArrayofObjects(professions)
              ? tempProfession
              : professions,
          };

          if (is_bb_impact && tempProfession.length == 0) {
            showToast(
              "Please select profession",
              "danger",
              3000, // Custom timeout duration (5 seconds)
              () => {}
            );
            return true;
          }

          let res;

          res =
            formType == "add"
              ? await saveCampaign(payload)
              : await updateCampaign(payload, CampaignId);

          // setIsSubmit(false)

          if (res?.success) {
            navigate("/campaign-list");
            showToast(
              res?.message,
              "success",
              1000, // Custom timeout duration (5 seconds)
              () => {}
            );
          } else {
            if (res?.status === 401) {
              showToast(
                res?.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {}
              );
              sessionStorage.clear();
              navigate("/");
            } else {
              showToast(
                res?.message,
                "danger",
                3000, // Custom timeout duration (5 seconds)
                () => {}
              );
            }
          }
        }
      } else {
        showToast(
          "Start date Should be less then end date.",
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
      }
    } else {
      showToast(
        "Please all fill mandatory.",
        "danger",
        3000, // Custom timeout duration (5 seconds)
        () => {}
      );
    }
    // const isCheckAssesment = finalAssignmentData();
  };
  const handleChangeQuestionType = (e, index) => {
    let tempdata = question;

    tempdata[index].question_type = e.target.value;

    setQuestion([...tempdata]);
  };

  const [question, setQuestion] = useState([
    {
      question: "",
      question_type: "single",
      options: ["", ""],

      placeholder: "",
      question_priority: "optional",
      published: false,
      order: 0,
    },
  ]);
  const addOption = (index) => {
    let tempQuestion = question;
    tempQuestion[index].options.push("");
    setQuestion([...tempQuestion]);
  };

  const deleteOption = (index, innerIndex) => {
    let tempQuestion = question;
    tempQuestion[index].options.splice(innerIndex, 1);
    setQuestion([...tempQuestion]);
  };

  const handleQuestion = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].options[innerIndex] = e.target.value;
    setQuestion([...tempdata]);
  };
  const handleQuestionTitle = (e, mainIndex, innerIndex) => {
    let tempdata = question;

    tempdata[mainIndex].question = e.target.value;
    setQuestion([...tempdata]);
  };

  const finalAssignmentData = () => {
    let tempdata = question;
    question?.map((item, index) => {
      item.options = item.options.filter((v) => v != "");
    });

    for (let i = 0; i <= tempdata.length - 1; i++) {
      if (tempdata[i].options.length < 2) {
        showToast(
          `add at least 2  options in question number ${i + 1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        setQuestion([...tempdata]);
        // setIsSubmit(false)
        return false;
        break;
      } else if (tempdata[i].question == "") {
        showToast(
          `add question title in  question number ${i + 1}`,
          "danger",
          3000, // Custom timeout duration (5 seconds)
          () => {}
        );
        setQuestion([...tempdata]);
        // setIsSubmit(false)
        return false;
        break;
      }
    }

    //
    //  setIsSubmit(false)
    return true;
  };
  const addQuestion = () => {
    setQuestion([
      ...question,

      {
        question: "",
        question_type: "single",
        options: ["", ""],
      },
    ]);
  };
  const deleteQuestion = (index) => {
    let tempQuestion = question;
    tempQuestion.splice(index, 1);
    setQuestion([...tempQuestion]);
  };
  const handleQuestionOrder = (index, e) => {
    let tempdata = question;

    tempdata[index].order = e?.target?.value;
    setQuestion([...tempdata]);
  };
  const handleRequired = (index, e) => {
    let tempdata = question;

    tempdata[index].question_priority = e?.target?.checked
      ? "required"
      : "optional";

    setQuestion([...tempdata]);
  };
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleMultiSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const handleProfession = (e) => {
    // console.log(e);
    // console.log(professions);
    let tempdata = professions;
    // console.log(tempdata);
    e.forEach((item) => {
      if (!tempdata.includes(item._id)) {
        tempdata.push(item._id);
      }
    });
  };

  return (
    <>
      <div className="page-wrapper null compact-wrapper" id="pageWrapper">
        <Header />
        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper null">
          {/* <!-- Page Sidebar Start--> */}
          <Sidebar />
          {/* <!-- Page Sidebar end--> */}
          <div className="page-body">
            {/* /*breadcrumb*/}
            <Breadcrumb />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid dashboard-default-sec pt-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="card rounded-md mb-4 p-3">
                    <div className="card-headers pt-0 pb-2  flex justify-between items-center">
                      <div className="flex items-center">
                        <h3 className="card-titles mb-0 me-3">
                          {formType == "add"
                            ? ` Add Campaign`
                            : `Update Campaign`}
                        </h3>
                      </div>
                    </div>
                    <div className="flex gap-2 mb-2"></div>

                    <div>
                      <div className="card-body pt-0 form-author">
                        <div className="grid grid-cols-12 gap-4">
                          <div className="col-span-12  lg:col-span-6">
                            <InputBox
                              label="Name*"
                              value={name}
                              onChange={(e) => setName(e?.target?.value)}
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="col-span-12  lg:col-span-6">
                            <InputBox
                              label="Code*"
                              value={code}
                              onChange={(e) => setCode(e?.target?.value)}
                              placeholder="Enter Code"
                            />
                          </div>
                          <div className="flex gap-[1rem] col-span-12  lg:col-span-12">
                            <CheckBox
                              label="Published"
                              value={isPublished}
                              onChange={(e) => {
                                setIsPublished(e?.target?.checked);
                              }}
                            />
                            <CheckBox
                              label="Open to all"
                              value={openToAll}
                              onChange={(e) => {
                                setOpenToAll(e?.target?.checked);
                              }}
                            />
                            <CheckBox
                              label="Is bb impact"
                              value={is_bb_impact}
                              onChange={(e) => {
                                setIs_bb_impact(e?.target?.checked);
                                setProfessions([]);
                              }}
                            />
                          </div>
                          {is_bb_impact &&
                            (formType == "Edit" ? (
                              professions.length != 0 ? (
                                <div className="col-span-12  lg:col-span-6">
                                  <label className="rounded-md mb-2">
                                    Professions
                                  </label>

                                  <Select
                                    isMulti
                                    name="colors"
                                    defaultValue={
                                      professions.length != 0
                                        ? professions
                                        : [
                                            {
                                              _id: "659befae1cc803cf8fdffe77",
                                              name: "Freelancer",
                                            },
                                          ]
                                    }
                                    options={professionData}
                                    onChange={(e) => handleProfession(e)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option._id}
                                  />
                                </div>
                              ) : null
                            ) : (
                              <div className="col-span-12  lg:col-span-6">
                                <label className="rounded-md mb-2">
                                  Professions
                                </label>

                                <Select
                                  isMulti
                                  name="colors"
                                  defaultValue={professions}
                                  options={professionData}
                                  onChange={(e) => handleProfession(e)}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option._id}
                                />
                              </div>
                            ))}

                          <div className="col-span-12  lg:col-span-6">
                            <DropdownBox
                              label="Source*"
                              value={source}
                              onChange={(e) => setSource(e?.target?.value)}
                            >
                              <option value="">Select</option>
                              <option value="oneapp">Oneapp </option>
                              <option value="community">Community </option>
                              <option value="web">Web </option>
                            </DropdownBox>
                          </div>

                          <div className="col-span-12  lg:col-span-6">
                            <DropdownBox
                              label="Form*"
                              value={formId}
                              onChange={(e) => setFormId(e?.target?.value)}
                            >
                              <option value="">Select</option>

                              {formData &&
                                formData?.map((item, index) => (
                                  <option value={item?._id} key={index}>
                                    {item?.name}
                                  </option>
                                ))}
                            </DropdownBox>
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <div className="relative flex flex-col">
                              <label className="mb-2">Start Date *</label>
                              <input
                                type="date"
                                min={moment().format("YYYY-MM-DD")}
                                className="border border-gray-300 px-2 w-full py-2 rounded-md"
                                onChange={(e) => {
                                  setStartDate(e?.target?.value);
                                }}
                                value={startDate}
                              />
                            </div>
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <div className="relative flex flex-col">
                              <label className="mb-2">End Date *</label>
                              <input
                                type="date"
                                min={moment().format("YYYY-MM-DD")}
                                className="border border-gray-300 px-2 w-full py-2 rounded-md"
                                onChange={(e) => {
                                  setEndDate(e?.target?.value);
                                }}
                                value={endDate}
                              />
                            </div>
                          </div>

                          <div></div>
                        </div>

                        <div className="col-span-12">
                          <div className="justify-end flex">
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn_table green-bg py-2"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSubmit}
                              className="btn_table blue-bg ms-1 py-2"
                            >
                              {formType == "update" ? `Update` : `Save`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container-fluid end--> */}
          </div>
        </div>
      </div>
      {/* <!-- Page Body end--> */}
      {isLoader && <Loader />}
    </>
  );
}

export default CampaignAdd;
